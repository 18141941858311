import React, { Fragment, useEffect, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import "../../styles/gptSearch.scss";
import { CiVideoOff, CiVideoOn } from "react-icons/ci";
import { AiFillAudio, AiFillDislike, AiFillLike } from "react-icons/ai";
import axios from "axios";
import { LuBookmark, LuLoader } from "react-icons/lu";
import { RiVoiceprintFill } from "react-icons/ri";
import GptSidebar from "./gptSidebar";
import { TiArrowUp } from "react-icons/ti";
import {
  MdAdd,
  MdOutlineKeyboardArrowUp,
  MdQuestionMark,
} from "react-icons/md";
import { useTour } from "@reactour/tour";
import {
  CPSS_logo,
  Gto,
  OIR,
  captainAlphaModal,
  courses,
  cpssModal,
  currentAffair,
  defaultUser,
  doctor,
  group_discussion_icon,
  // hideVideo,
  interview,
  lamp1,
  lamp2,
  lamp3,
  // linkSearch,
  loaderGif,
  logo_mobile,
  medicalModal,
  olq,
  physicalModal,
  ppdtIcon,
  profile_mic,
  profile_mic_active,
  psychology,
  // searchBook,
  spokenEnglish,
  trainer,
  why1,
} from "../../assets/images";
import userService from "../../services/userService";
import bookService from "../../services/bookService";
import { toast } from "react-toastify";
import { BsPlayCircle } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { userEndPoints } from "../../config";
import Loader from "../reusable/Loader";
import { useAuth } from "../../context/userContext";
// import moment from "moment";
import CustomHelmet from "../reusable/Helmet";
import WhatCanDo from "./WhatCanDo";
import GptBooks from "./gptBooks";
import { HiOutlinePaperAirplane } from "react-icons/hi";
import { FaRegUser } from "react-icons/fa";
import logo_mobile_small from "../../images/logo_mobile.svg";
import SSBMentorIcon from "../../images/SSBMentorIcon.png";
import CustomButton from "../reusable/CustomButton";
import FlightSimulation from "./FlightSimulation";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const GptSearch = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const localToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : {};

    const data = {
      success: true,
      token: token,
    };
    if (!localToken) {
      localStorage.setItem("ssb-token", data);
    }
  }, [location.search]);

  const [searchValue, setSearchValue] = useState("");
  const [placeHolderInput, setPlaceholderInput] = useState(
    ""
    // "Search anything related to SSB"
  );
  const [activeTab, setActiveTab] = useState("");
  const [activeinput, setActiveInput] = useState(false);
  const [outputData, setOutputData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mediaOpen, setMediaOpen] = useState(false);
  // const [mediaFile, setMediaFile] = useState([]);
  const [ppdtImage, setPpdtImage] = useState();
  const [imageQuery, setImageQuery] = useState("");
  const [ppdtTimer, setPpdtTimer] = useState(30);
  const [pdfFile, setPdfFile] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [gptMic, setGptMic] = useState("");
  const [gptSpeaker, setGptSpeaker] = useState("");
  const [captainImage, setCaptainImage] = useState("");

  const [srchToday, setSrchToday] = useState(false);

  const [welcomeText, setWelcomeText] = useState({});
  const [showExerciseBar, setshowExerciseBar] = useState(false);

  const noCategoryWlcmMsg = {
    logoImg: "",
    name: `Hello ${
      auth?.user?.first_name ? auth?.user?.first_name : "Warrior"
    }`,
    tagLine: "",
    description:
      "Let's gear up for SSB Interview prep – choose your classroom and let’s get to work!",
    tutorialLink: "",
    startLink: "",
  };
  const captainAlphaWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Capt. Alpha",
    tagLine: "AI SSB Mentor",
    description:
      "Discover Captain Alpha's powerful capabilities—watch the tutorial to fully unlock the potential of SSB-GPT and elevate your defense preparation.",
    tutorialLink: "",
    startLink: "startQuestion",
  };
  const medicalWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Ravinder Yadav",
    tagLine: "AI Medical Expert",
    description:
      "Gain medical insights with expert Ravinder Yadav—watch the tutorial to make the most of SSB-GPT for your defense readiness!",
    tutorialLink: "",
    startLink: "startMedical",
  };
  const physicalWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Vikram Kumar",
    tagLine: "AI Fitness Trainer",
    description:
      "Boost your fitness journey with expert Vikram Kumar—watch the tutorial to maximize your SSB-GPT training advantage!",
    tutorialLink: "",
    startLink: "startPhysical",
  };
  const cpssWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Sanjeev Kumar",
    tagLine: "AI CPSS Expert",
    description:
      "Unlock CPSS expertise with Sanjeev Kumar—watch the tutorial to maximize your SSB-GPT advantage!",
    tutorialLink: "",
    startLink: "startCPSS",
  };

  // ALL BOOK SAVE STATE STARTS
  // const todatDate = new Date();
  // const currentDate = moment(todatDate).format("YYYY-MM-DD");

  const handleVoice = () => {
    setIsListening(true);
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.maxResults = 10;
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchValue(transcript);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.start();
  };

  const [disableVideo, setDisableVideo] = useState(
    () => JSON.parse(localStorage.getItem("videoSrch")) ?? true
  );

  const handleDisableVideo = () => {
    setDisableVideo((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("videoSrch", JSON.stringify(disableVideo));
  }, [disableVideo]);

  //  NO CATEGORY CHOSEN
  const handleNoCategory = () => {
    setSrchToday(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setPlaceholderInput("");
    setActiveInput(false);
    setMediaOpen(false);
    setActiveTab("");
    setOutputData([]);
    setWelcomeText(noCategoryWlcmMsg);
  };

  //  POST DATA TO QUESTION API START
  const handleAskQuestion = () => {
    setShowClassCategory(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setSrchToday(false);
    setPlaceholderInput("Ask anything related to SSB");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("question");
    setOutputData([]);
    navigate("/chat?type=question");
    setWelcomeText(captainAlphaWlcmMsg);
  };

  //  POST DATA TO Medical API START
  const handleMedicalQuestion = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setPlaceholderInput("Ask anything related to medical");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("medical");
    setConversationId("");
    setOutputData([]);
    navigate("/chat?type=medical");
    setWelcomeText(medicalWlcmMsg);
  };

  //  POST DATA TO Physical API START
  const handlePhysicalQuestion = () => {
    setShowClassCategory(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setSrchToday(false);
    setPlaceholderInput("Ask anything related to physical");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("physical");
    setConversationId("");
    setOutputData([]);
    navigate("/chat?type=physical");
    setWelcomeText(physicalWlcmMsg);
  };

  //  POST DATA TO CPSS API START
  const handleCPSSGuidance = () => {
    setShowClassCategory(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setSrchToday(false);
    setPlaceholderInput("Ask anything related to CPSS");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("CPSS");
    setConversationId("");
    setOutputData([]);
    navigate("/chat?type=CPSS");
    setWelcomeText(cpssWlcmMsg);
  };

  const handleSearchResult = async (query) => {
    setIsLoading(true);
    setSearchValue("");
    setSrchToday(true);
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      // const result = await userService.userChat(searchValue);
      let result;
      if (activeTab === "question") {
        result = await axios.post(`https://chat.ssbgpt.in/api/v1/chat`, {
          question: searchValue,
        });
      }
      if (activeTab === "medical") {
        result = await axios.post(
          `https://chat.ssbgpt.in/api/v1/medical-chat`,
          {
            inputText: searchValue,
            conversationId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }
      if (activeTab === "physical") {
        result = await axios.post(
          `https://chat.ssbgpt.in/api/v1/physical-chat`,
          {
            inputText: searchValue,
            conversationId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }
      // if (!disableVideo) {
      //   var vdResult = await bookService.getVideoSearch(searchValue);
      // }

      if (result) {
        setConversationId(result?.data?.conversation_id);
        // const videoId = vdResult?.data?.video?.url;
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.answer.replace(/Alpha Bot:\s*/g, ""),
                  // url: videoId,
                  // videoTitle: vdResult?.data?.title,
                }
              : entry
          )
        );

        // IN FUNCTION SPEAK FUNCTIONALITY START
        if (gptSpeaker) {
          const speech = new SpeechSynthesisUtterance(result?.data?.answer);
          // speech.lang = "en-IN";
          speech.lang = "en-IN";
          window.speechSynthesis.speak(speech);
          speech.onend = () => {
            setSearchValue("");
            result = "";
          };
        }
        // IN FUNCTION SPEAK FUNCTIONALITY ENDS

        // let updatedList = [...localHistory];
        // const existingEntry = updatedList.find(
        //   (item) => item.dayData === currentDate
        // );

        // if (existingEntry) {
        //   existingEntry.searchData.push({
        //     question: searchValue,
        //     answer: result?.data?.response,
        //     url: videoId,
        //     videoTitle: vdResult?.data?.title,
        //   });
        // } else {
        //   updatedList.push({
        //     dayData: currentDate,
        //     searchData: [
        //       {
        //         question: searchValue,
        //         answer: result?.data?.response,
        //         url: videoId,
        //         videoTitle: vdResult?.data?.title,
        //       },
        //     ],
        //   });
        // }
        // setQuestionList(updatedList);
        // setlocalHistory(updatedList);
        // localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  //  POST DATA TO API ENDS

  const handleMediaOpen = (e) => {
    e.stopPropagation();
    setMediaOpen(!mediaOpen);
  };

  useEffect(() => {
    const handleClickOutside = () => {
      if (mediaOpen) {
        setMediaOpen(false);
        console.log("click outside, media closed");
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [mediaOpen]);

  // OPEN MEDIA SELECT BAR ENDS
  useEffect(() => {
    if (ppdtImage && ppdtTimer > 0) {
      setTimeout(() => {
        setPpdtTimer(ppdtTimer - 1);
      }, 1000);
    }
  });

  const handlePsychologyTest = () => {
    navigate("/psychology-test-attempt");
  };

  // IMAGE GENRATE STARTS
  const handleImageGenrate = async (query) => {
    setShowClassCategory(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setIsLoading(true);
    setActiveInput(true);
    setActiveTab(query);
    setMediaOpen(false);
    setSrchToday(true);
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    setPlaceholderInput("Please wait for sometime we are generating the data");
    try {
      const apiResponseData = await userService.generateTatImage(query);
      if (apiResponseData) {
        setImageQuery(query);
        setPpdtImage(apiResponseData?.data?.data);
        setIsLoading(false);
        setMediaOpen(false);
        setPlaceholderInput("Ask anything related to SSB");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  // IMAGE GENRATE ENDS

  // OIR MCQ'S TEST GENRATE START
  const handleOIR = () => {
    setPlaceholderInput("Write the topic for generate MCQ");
    setActiveInput(true);
    setActiveTab("oir");
    setMediaOpen(false);
    setShowClassCategory(false);
    setInitialSearchType("");
    setInitialQuestion("");
  };
  // OIR MCQ'S TEST GENRATE ENDS

  // Link TEST GENRATE START
  // const handleLinkSearch = () => {
  //   // setPlaceholderInput("Write the link to summerise the data");
  //   // setActiveInput(true);
  //   // setActiveTab("link");
  //   // setMediaOpen(false);
  //   toast.warning("Not integrated yet");
  // };

  const handleLinkGenrate = async () => {
    setIsLoading(true);
    setSearchValue("");
    setSrchToday(true);
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await axios.post(userEndPoints.linkSummarize, {
        input_text: searchValue,
      });

      if (result?.data?.response !== "No input provided") {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                }
              : entry
          )
        );

        // const currentDate = moment().format("YYYY-MM-DD");
        // let updatedList = [...questionList];

        // if (
        //   updatedList.length > 0 &&
        //   updatedList[updatedList.length - 1].dayData === currentDate
        // ) {
        //   updatedList[updatedList.length - 1].searchData.push({
        //     question: searchValue,
        //     answer: result?.data?.response,
        //   });
        // } else {
        //   updatedList.push({
        //     dayData: currentDate,
        //     searchData: [
        //       {
        //         question: searchValue,
        //         answer: result?.data?.response,
        //       },
        //     ],
        //   });
        // }
        // setQuestionList(updatedList);
        // setlocalHistory(updatedList);
        // localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
      } else {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: "Sorry i have no provided data for that",
                }
              : entry
          )
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setMediaOpen(false);
    }
  };
  //Link TEST GENRATE ENDS

  // HANDLE PDF UPLOAD START
  // const handlePdfSearch = () => {
  //   setActiveInput(true);
  //   setActiveTab("pdf");
  //   setMediaOpen(false);
  // };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPdfFile(file);
    }
  };

  const handlePdfUpload = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", pdfFile);

      const result = await axios.post(userEndPoints.pdfUpload, formData);

      if (result) {
        setOutputData([
          ...outputData,
          { answer: result?.data?.response, question: searchValue },
        ]);
        setIsLoading(false);
        setSearchValue("");
        setPlaceholderInput("Search anything from provided pdf");
        setActiveTab("pdf_srch");
      }
    } catch (error) {
      setIsLoading(false);
      setMediaOpen(false);
    }
  };

  const handlePdfSearchQuestion = async () => {
    setIsLoading(true);
    setSearchValue("");
    setMediaOpen(false);
    setSrchToday(true);
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await axios.post(userEndPoints.askQuestion, {
        input_text: searchValue,
      });

      if (result) {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                }
              : entry
          )
        );
        // const currentDate = moment().format("YYYY-MM-DD");
        // let updatedList = [...questionList];

        // if (
        //   updatedList.length > 0 &&
        //   updatedList[updatedList.length - 1].dayData === currentDate
        // ) {
        //   updatedList[updatedList.length - 1].searchData.push({
        //     question: searchValue,
        //     answer: result?.data?.response,
        //   });
        // } else {
        //   updatedList.push({
        //     dayData: currentDate,
        //     searchData: [
        //       {
        //         question: searchValue,
        //         answer: result?.data?.response,
        //       },
        //     ],
        //   });
        // }
        // setQuestionList(updatedList);
        // setlocalHistory(updatedList);
        // localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
        setPlaceholderInput("Search anything from provided pdf");
        setActiveTab("pdf_srch");
      }
    } catch (error) {
      setIsLoading(false);
      setMediaOpen(false);
    }
  };

  // HANDLE PDF UPLOAD ENDS

  // HANDLE VIDEO SEARCH STARTS

  // const getYouTubeVideoId = (url) => {
  //   const regex = /https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;
  //   const match = url.match(regex);
  //   return match ? match[1] : null;
  // };

  // const fetchVideo = async () => {
  //   setIsLoading(true);
  //   setSearchValue("");
  //   setMediaOpen(false);
  //   const newEntry = {
  //     question: searchValue,
  //     url: null,
  //     videoTitle: "",
  //   };
  //   setOutputData((prevData) => [...prevData, newEntry]);
  //   try {
  //     const result = await bookService.getVideoSearch(searchValue);
  //     if (result) {
  //       // const videoId = getYouTubeVideoId(result?.data?.video?.url);
  //       const videoId = result?.data?.video?.url;

  //       setIsLoading(false);
  //       setPlaceholderInput("Search video");
  //       setActiveTab("video");
  //       setOutputData((prevData) =>
  //         prevData.map((entry, index) =>
  //           index === prevData.length - 1
  //             ? {
  //                 ...entry,
  //                 url: videoId,
  //                 answer: `Here is your video for your ${searchValue} search`,
  //                 videoTitle: result?.data?.title,
  //               }
  //             : entry
  //         )
  //       );

  //       const currentDate = moment().format("YYYY-MM-DD");
  //       let updatedList = [...questionList];
  //       if (
  //         updatedList.length > 0 &&
  //         updatedList[updatedList.length - 1].dayData === currentDate
  //       ) {
  //         updatedList[updatedList.length - 1].searchData.push({
  //           question: searchValue,
  //           answer: `Here is your video for your ${searchValue} search`,

  //           url: videoId,
  //         });
  //       } else {
  //         updatedList.push({
  //           dayData: currentDate,
  //           searchData: [
  //             {
  //               question: searchValue,
  //               answer: `Here is your video for your ${searchValue} search`,
  //               url: videoId,
  //             },
  //           ],
  //         });
  //       }
  //       setQuestionList(updatedList);
  //       setlocalHistory(updatedList);
  //       localStorage.setItem("userHistory", JSON.stringify(updatedList));
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     setMediaOpen(false);
  //   }
  // };

  const handleVideoView = (url) => {
    // const videoId = getYouTubeVideoId(url);
    if (url) {
      navigate(`/video?streamId=${url}`);
    }
  };

  // HANDLE VIDEO SEARCH ENDS

  // INTERVIEW ATTEND FUNCTION API'S CALL STARTS
  // INTERVIEW ATTEND FUNCTION API'S CALL STARTS

  const handleAttendInterview = () => {
    if (auth?.user?._id) {
      navigate("/interview");
    } else {
      navigate("/login", { state: "/interview" });
    }
  };

  const handleNavigateCourses = () => {
    window.open("https://alpharegiment.in/ssb-courses");
  };

  // INTERVIEW ATTEND FUNCTION API'S CALL ENDS
  // INTERVIEW ATTEND FUNCTION API'S CALL ENDS

  // test start timer

  // STEPS FOR GIVING OVERVIEW
  const { currentStep, isOpen } = useTour();

  useEffect(() => {
    if (currentStep === 0 && isOpen) {
      setMediaOpen(true);
    }
  }, [currentStep, isOpen]);

  // SCROLL HER PAGE AFTER EVERY SEARCH STARTS
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current && outputData) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [outputData]);

  // SCROLL HER PAGE AFTER EVERY SEARCH ENDS

  // CHECK USER TODAY SEARCH HISTORY STARTS
  const savedList = localStorage.getItem("userHistory");

  useEffect(() => {
    if (savedList) {
      const data = JSON.parse(savedList);
      if (data === null) {
        setSrchToday(true);
      } else {
        const today = new Date().toISOString().split("T")[0];
        const checkSearchToday = data.some((item) => item.dayData === today);
        const searchHistory = data.find(
          (item) => item.dayData === today
        )?.searchData;
        if (searchHistory) {
          setOutputData(searchHistory);
        }
        setSrchToday(checkSearchToday);
      }
      setQuestionList(data);
    }
  }, [savedList]);

  const handleShowHistory = (date) => {
    const extractedData = questionList
      ?.filter((ele) => ele?.dayData === date)
      .map((item) => item.searchData)
      .flat();
    setOutputData(extractedData);
  };

  // CHECK USER TODAY SEARCH HISTORY ENDS

  // SPOKEN ENGLISH STARTS

  // const handleSpokenEnglish = () => {
  //   setActiveTab("spokenEnglish");
  //   setMediaOpen(false);
  //   setSrchToday(true);
  //   setOutputData((prevData) => [
  //     ...prevData,
  //     {
  //       question:
  //         "Everyone learns differently, so here you can improve your spoken english in various ways. Choose the method that works best for you.",
  //       spokenType: [
  //         {
  //           type: "Watch Videos for Spoken English Basics",
  //           query: "",
  //         },
  //         {
  //           type: "Learn Spoken English with AI",
  //           query: "/spoken-english",
  //         },
  //         {
  //           type: "Group Discussion with Other Aspirants",
  //           query: "/spoken-english",
  //         },
  //       ],
  //     },
  //   ]);
  //   let updatedList = [...localHistory];
  //   const existingEntry = updatedList.find(
  //     (item) => item.dayData === currentDate
  //   );
  //   if (existingEntry) {
  //     existingEntry.searchData.push({
  //       question:
  //         "Everyone learns differently, so here you can improve your spoken english in various ways. Choose the method that works best for you.",

  //       spokenType: [
  //         {
  //           type: "Watch Videos for Spoken English Basics",
  //           query: "",
  //         },
  //         {
  //           type: "Learn Spoken English with AI",
  //           query: "/spoken-english",
  //         },
  //         {
  //           type: "Group Discussion with Other Aspirants",
  //           query: "/spoken-english",
  //         },
  //       ],
  //     });
  //   } else {
  //     updatedList.push({
  //       dayData: currentDate,
  //       searchData: [
  //         {
  //           question:
  //             "Everyone learns differently, so here you can improve your spoken english in various ways. Choose the method that works best for you.",

  //           spokenType: [
  //             {
  //               type: "Watch Videos for Spoken English Basics",
  //               query: "",
  //             },
  //             {
  //               type: "Learn Spoken English with AI",
  //               query: "/spoken-english",
  //             },
  //             {
  //               type: "Group Discussion with Other Aspirants",
  //               query: "/spoken-english",
  //             },
  //           ],
  //         },
  //       ],
  //     });
  //   }
  //   setQuestionList(updatedList);
  //   // setlocalHistory(updatedList);
  //   // localStorage.setItem("userHistory", JSON.stringify(updatedList));
  // };

  // HANDLE SPOKEN QUERY
  const handleSpokenQuery = (query) => {
    navigate(query);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchValue !== "") {
      handleSearch();
    }
  };

  // HANDLE FUNCTION FOR ANALYSING USER INPUT
  const keywords = {
    srt: ["srt", "situation", "situations", "Give SRT", "Practice srt"],
    link: ["http", "https", "www"],
    ppdt: ["PP&DT", "PP & DT", "ppdt", "PPDT"],
    tat: ["tat", "TAT"],
    wat: ["word", "wat", "WAT"],
    interview: ["interview"],
    pdfSearch: ["pdf", "pdfSearch"],
    olq: ["olq", "OLQ", "olq analysis"],
    oir: ["Give OIR", "OIr test", "oir"],
    spoken: ["Spoken English", "Spoken", "english"],
    video: ["video", "learn by video"],
    courses: [
      "Coaching",
      "SSB Institute",
      "Psychology dossier checked by ex-psychologist ",
      "Mock Psychology",
      "Mock Interview",
      "Mock SSB",
    ],
  };

  const [message, setMessage] = useState("");
  const [suggestionTab, setSuggestionTab] = useState("ask");

  const handleInputChange = (e) => {
    const valueData = e.target.value;
    setSearchValue(valueData);
    analyzeInput(valueData.trim());
  };

  const analyzeInput = (input) => {
    const lowerCaseInput = String(input).toLowerCase();
    let matchedKeyword = null;
    Object.keys(keywords).forEach((keyword) => {
      if (
        keywords[keyword].some((pattern) =>
          lowerCaseInput.includes(pattern.toLowerCase())
        )
      ) {
        matchedKeyword = keyword;
      }
    });
    if (matchedKeyword === "srt") {
      setMessage(`Would you like to get SRT?`);
      setSuggestionTab("srt");
    }
    if (matchedKeyword === "wat") {
      setMessage(`Would you like to get WAT?`);
    }
    if (matchedKeyword === "ppdt") {
      setMessage(`Would you like to get  PPDT / PP&DT?`);
      setSuggestionTab("PPDT");
    }
    if (matchedKeyword === "tat") {
      setMessage(`Would you like to get TAT ?`);
      setSuggestionTab("TAT");
    }
    if (matchedKeyword === "oir") {
      setMessage(`Would you like to get OIR ?`);
      setSuggestionTab("oir");
    }
    if (matchedKeyword === "pdfSearch") {
      setMessage(`Would you like a summary, MCQs, or specific information from the PDF?
`);
      setSuggestionTab("pdf");
    }
    if (matchedKeyword === "olq") {
      setMessage(
        `Do you mean you want to get your OLQ analysis through a simple psychometric test?`
      );
    }
    if (matchedKeyword === "link") {
      setMessage(
        `Would you like a summary, MCQs, or specific information from this link?`
      );
      setSuggestionTab("link");
    }

    if (matchedKeyword === "interview") {
      setMessage(`Want to give an interview`);
      setSuggestionTab("interview");
    }
    if (matchedKeyword === "courses") {
      setMessage(`Want to browse ssb courses`);
      setSuggestionTab("courses");
    }

    if (matchedKeyword === "video") {
      setMessage(`Would you like your questions answered in a video?
`);
      setSuggestionTab("video");
    }

    if (matchedKeyword === "spoken") {
      setMessage(`Do you mean you want to practice spoken English with AI?
`);
      setSuggestionTab("spokenEnglish");
    }

    return matchedKeyword;
  };

  // HANDLE SEARCH VIW POPUP MESSAGE

  const handleSearchPop = () => {
    setMessage("");
    if (
      suggestionTab === "question" ||
      activeTab === "medical" ||
      activeTab === "physical"
    ) {
      handleSearchResult();
    }
    if (suggestionTab === "oir") {
      toast.warning("We Launch OIR test feature soon");
    }
    if (suggestionTab === "link") {
      handleLinkGenrate();
    }
    if (suggestionTab === "pdf") {
      handlePdfUpload();
    }

    if (suggestionTab === "pdf_srch") {
      handlePdfSearchQuestion();
    }

    if (suggestionTab === "courses") {
      handleNavigateCourses();
    }
  };

  const handleSearch = () => {
    setMessage("");
    if (
      activeTab === "question" ||
      activeTab === "medical" ||
      activeTab === "physical"
    ) {
      handleSearchResult();
    }
    if (activeTab === "oir") {
      toast.warning("We Launch OIR test feature soon");
    }
    if (activeTab === "link") {
      handleLinkGenrate();
    }
    if (activeTab === "pdf") {
      handlePdfUpload();
    }

    if (activeTab === "pdf_srch") {
      handlePdfSearchQuestion();
    }
  };

  // SPOKEN ENGLISH ENDS

  const [loadingNotes, setLoadingNotes] = useState(false);
  const [bookList, setBookList] = useState([]);
  const fetchBookList = async () => {
    try {
      setLoadingNotes(true);
      const res = await bookService.myAllBookList();
      if (res) {
        setLoadingNotes(false);
        setBookList(res?.data?.books);
      }
    } catch (error) {
      setLoadingNotes(false);
    }
  };

  useEffect(() => {
    if (auth?.user?._id) {
      fetchBookList();
    }
  }, [auth?.user?._id]);

  const [saveValue, setSaveValue] = useState();

  const handleSave = (idx) => {
    setSaveValue(idx);
  };

  const handleMicFunction = () => {
    // const micValue = localStorage.getItem("ssbgptmic");
    // if (micValue === "true") {
    //   localStorage.setItem("ssbgptmic", "false");
    // } else {
    //   localStorage.setItem("ssbgptmic", "true");
    // }
    if (gptMic) {
      setGptMic(false);
    } else {
      setGptMic(true);
      handleVoice();
    }
  };

  const handleSpeakFunction = () => {
    if (gptSpeaker) {
      setGptSpeaker(false);
    } else {
      setGptSpeaker(true);
    }
  };

  useEffect(() => {
    if (activeTab === "question") {
      setCaptainImage(captainAlphaModal);
    } else if (activeTab === "medical") {
      setCaptainImage(medicalModal);
    } else if (activeTab === "physical") {
      setCaptainImage(physicalModal);
    } else if (activeTab === "CPSS") {
      setCaptainImage(cpssModal);
    } else {
      setCaptainImage(captainAlphaModal);
    }
  }, [activeTab]);

  // GET type from URL
  const queryParams = new URLSearchParams(location.search);
  const modalType = queryParams.get("type");

  // HANDLE MODAL TYPE BY PAGE LINK
  useEffect(() => {
    if (modalType === "question") {
      setCaptainImage(captainAlphaModal);
      handleAskQuestion();
    } else if (modalType === "medical") {
      setCaptainImage(medicalModal);
      handleMedicalQuestion();
    } else if (modalType === "physical") {
      setCaptainImage(physicalModal);
      handlePhysicalQuestion();
    } else if (modalType === "CPSS") {
      setCaptainImage(cpssModal);
      handleCPSSGuidance();
    } else {
      setCaptainImage("");
      handleNoCategory();
    }
  }, [modalType]);

  const [showClassCategory, setShowClassCategory] = useState(false);
  const handleShowClassCategory = () => {
    setShowClassCategory(true);
    setInitialSearchType("");
    setInitialQuestion("");
    setSrchToday(true);
  };

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [initialSearchType, setInitialSearchType] = useState("");
  const [initialQuestion, setInitialQuestion] = useState("");

  const handleInitialStartAction = (startType) => {
    setInitialSearchType(startType);
    setSrchToday(true);
  };

  // CAPTAIN ALPHA/ASK QUESTION INITIAL ACTIONS MESSAGES
  const handleQuestionInitialAction = (searchType) => {
    setInitialQuestion(searchType);
    if (searchType === "option-A") {
      setShowSearchInput(true);
    } else if (searchType === "option-B") {
      setShowSearchInput(true);
    } else if (searchType === "option-C") {
      setShowSearchInput(false);
    } else {
      setShowSearchInput(false);
    }
  };

  // CPSS INITIAL ACTIONS MESSAGES
  const handleCPSSInitialAction = (searchType) => {
    setInitialQuestion(searchType);
    if (searchType === "CPSS-A") {
      setShowSearchInput(true);
    }
  };

  const psychoMotorTestArr = [
    {
      name: "Sensory Motor Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Control Velocity Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Dual Task Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Dot Estimation Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Speed Anticipation Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Size Anticipation Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Pursuit Tracking Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Memory Recalling Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Color Naming Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Vigilance Test",
      testLink: "",
      knowMoreLink: "",
    },
  ];

  const [showFlightSimulation, setShowFlightSimulation] = useState(false);

  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
          <section className="gpt_layout_wrapper">
            <GptSidebar showExerciseBar={showExerciseBar} />
            <section className="gpt_Search_box">
              <div className="input_box">
                <div className={`srch_output custonScroll`} ref={scrollRef}>
                  {!srchToday && (
                    <div className="wlcm_msg">
                      <div className="modal_text">
                        <img
                          src={
                            welcomeText.logoImg
                              ? welcomeText.logoImg
                              : auth?.user?.profile?.url
                              ? auth?.user?.profile?.url
                              : defaultUser
                          }
                          style={{
                            borderRadius: welcomeText.logoImg ? 0 : "50%",
                          }}
                          alt="ssb gpt mobile logo"
                          className="mobile_logo_small"
                        />
                        <h1 className="title">{welcomeText.name}</h1>
                        <p className="tagLine">{welcomeText.tagLine}</p>
                        <p className="description">{welcomeText.description}</p>
                        <div className="btn_grp">
                          <CustomButton
                            className="watch_tutorial"
                            title="WATCH TUTORIAL"
                            tooltip="WATCH TUTORIAL"
                          />
                          {welcomeText.startLink ? (
                            <CustomButton
                              className="start_chat"
                              title="START"
                              tooltip="START CHAT"
                              onClick={() =>
                                handleInitialStartAction(welcomeText.startLink)
                              }
                            />
                          ) : (
                            <CustomButton
                              className="choose_classroom"
                              title="CHOOSE CLASSROOM"
                              tooltip="CHOOSE CLASSROOM"
                              onClick={handleShowClassCategory}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showClassCategory && (
                    <div className="choose_category_wrapper">
                      <div className="title">Choose Classroom</div>
                      <ul>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "question" ? "active" : ""
                            }`}
                            onClick={() => handleAskQuestion()}
                          >
                            <img src={SSBMentorIcon} alt="SSBMentorIcon" />
                            <p>SSB Mentor</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "physical" ? "active" : ""
                            }`}
                            onClick={() => handlePhysicalQuestion()}
                          >
                            <img src={trainer} alt="trainer" />
                            <p>Physical</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "medical" ? "active" : ""
                            }`}
                            onClick={() => handleMedicalQuestion()}
                          >
                            <img src={doctor} alt="doctor" />
                            <p>Medical</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que1 ${
                              activeTab === "oir" ? "active" : ""
                            }`}
                            onClick={() => handleOIR()}
                          >
                            <img src={OIR} alt="OIR" />
                            <p>OIR Test</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que2 ${
                              activeTab === "groupDiscussion" ? "active" : ""
                            }`}
                          >
                            <img
                              src={group_discussion_icon}
                              alt="group_discussion_icon"
                            />
                            <p>Group Discussion</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que3 ${
                              activeTab === "TAT" ? "active" : ""
                            }`}
                            onClick={() => handlePsychologyTest()}
                          >
                            <img src={psychology} alt="psychology" />
                            <p>Psychology Test</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que4 ${
                              activeTab === "PPDT" ? "active" : ""
                            }`}
                            onClick={() => handleImageGenrate("PPDT")}
                          >
                            <img src={ppdtIcon} alt="ppdtIcon" />
                            <p>PPDT</p>
                          </button>
                        </li>
                        <li>
                          <button className="media_select ask_que9">
                            <img src={Gto} alt="wat1" />
                            <p>GTO</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className="media_select ask_que10"
                            onClick={() => handleNavigateCourses()}
                          >
                            <img src={courses} alt="wat1" />
                            <p>Courses</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className="media_select ask_que11"
                            // onClick={() => handleVideoSearch()}
                          >
                            <img src={olq} alt="wat1" />
                            <p>OLQ Analysis</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className="media_select ask_que12"
                            onClick={() => handleAttendInterview()}
                          >
                            <img src={interview} alt="wat1" />
                            <p>Mock Interview</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que13 ${
                              activeTab === "currentAffair" ? "active" : ""
                            }`}
                            // onClick={() => handleSpokenEnglish()}
                          >
                            <img src={currentAffair} alt="currentAffair" />
                            <p>Current Affairs</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que14`}
                            // onClick={() => handleSpokenEnglish()}
                            onClick={() => navigate("/spoken-english")}
                          >
                            <img src={spokenEnglish} alt="spokenEnglish" />
                            <p>Spoken English</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que15 ${
                              activeTab === "CPSS" ? "active" : ""
                            }`}
                            onClick={() => handleCPSSGuidance()}
                          >
                            <img src={CPSS_logo} alt="CPSS_logo" />
                            <p>CPSS</p>
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}

                  {/* CAPTAIN ALPHA CATEGORISED QUESTIONS START */}
                  {initialSearchType === "startQuestion" && (
                    <div className="answer_box">
                      <div className="profile_img">
                        <img
                          className="captainProfilePic"
                          src={captainImage}
                          alt="captain modal"
                        />
                      </div>{" "}
                      <div>
                        <div className="output_box">
                          {initialQuestion === "" && (
                            <p>
                              Jai Hind! I am Captain Alpha, your dedicated AI
                              expert for SSB interview preparation, trained by
                              experienced ex-SSB/AFSB/NSB officers. You can rely
                              on me to guide you through your journey. Please
                              choose the role you'd like me to play:
                            </p>
                          )}
                          {initialQuestion === "option-A" ? (
                            <p>
                              Alright, Alpha Warrior. I'm ready. How can I
                              assist you today?
                            </p>
                          ) : initialQuestion === "option-B" ? (
                            <p>
                              Excellent choice, Alpha Warrior! Let's dive into
                              analyzing your personality and Officer-Like
                              Qualities (OLQs). I'll use a mix of psychometric
                              multiple-choice questions and some casual
                              conversation to get a better understanding. Rest
                              assured, everything between us is completely
                              private—only you and I will see this. Shall we
                              start?
                            </p>
                          ) : (
                            initialQuestion === "option-C" && (
                              <>
                                <p>
                                  If you choose Option A, I'll provide answers
                                  to general queries about the SSB interview
                                  process. This is a great option if you're
                                  looking to gather information about the
                                  interview, for example, what is PGT
                                  (Progressive Group Task).
                                </p>
                                <p>
                                  However, if you're seeking personalized
                                  guidance—then Option B would be the better
                                  choice. With Option B, I'll take the time to
                                  understand your situation more closely and
                                  give tailored advice based on your needs.
                                </p>
                                <p>
                                  As your personal mentor, I will thoroughly
                                  analyze every aspect of your personality using
                                  psychometric tests, situational questions, and
                                  casual interactions. I will assess your
                                  physical stamina through activities like
                                  running, exercising, or cycling. Additionally,
                                  I will ask miscellaneous questions to better
                                  understand you as an individual. Based on
                                  this, I will create a personalized roadmap.
                                </p>
                                <p>
                                  Our daily interactions will include psychology
                                  tests, which I will analyze and provide
                                  feedback on to help you improve. We will
                                  conduct mock interviews, followed by detailed
                                  feedback and guidance for improvement. We will
                                  also have unlimited group discussions or
                                  casual conversations to enhance your
                                  communication skills. I'll ensure you're
                                  well-versed in military awareness, current
                                  affairs, and prescribe exercises to boost your
                                  stamina in a short period.
                                </p>
                                <p>
                                  If you have any medical concerns, I will refer
                                  you to retired military doctors for expert
                                  advice. In short, everything required to
                                  prepare you for the SSB interview will be
                                  taken care of by me. You won’t need to go
                                  elsewhere or attend additional classes—I will
                                  be your mentor, guiding you through all
                                  aspects of the SSB interview preparation. So
                                  tell me which option you want to choose.
                                </p>
                              </>
                            )
                          )}
                          <div className="action_btn_grp">
                            {initialQuestion === "" && (
                              <>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-A")
                                  }
                                >
                                  (A) Answer my Questions
                                </button>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-B")
                                  }
                                >
                                  (B) Become my Personal Mentor{" "}
                                  <b>[Recommended]</b>
                                </button>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-C")
                                  }
                                >
                                  (C) Explain the difference between (A) & (B)
                                  to help you make an informed choice.
                                </button>
                              </>
                            )}
                            {initialQuestion === "option-C" && (
                              <>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-A")
                                  }
                                >
                                  (A) Answer my Questions
                                </button>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-B")
                                  }
                                >
                                  (B) Become my Personal Mentor{" "}
                                  <b>[Recommended]</b>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* CAPTAIN ALPHA CATEGORISED QUESTIONS ENDS */}

                  {/* CPSS CATEGORISED QUESTIONS START */}
                  {initialSearchType === "startCPSS" && (
                    <div className="answer_box txt_color">
                      <div className="profile_img">
                        <img
                          className="captainProfilePic"
                          src={captainImage}
                          alt="captain modal"
                        />
                      </div>{" "}
                      <div>
                        <div className="output_box">
                          {initialQuestion === "" && (
                            <p>
                              Jai Hind, Alpha Warrior! I am Sanjeev Kumar, your
                              AI CPSS mentor. Please choose an option for today:
                            </p>
                          )}
                          {initialQuestion === "CPSS-A" && (
                            <p>
                              Jai Hind, Alpha Warrior! Ask questions relaed CPSS
                            </p>
                          )}
                          {initialQuestion === "CPSS-B" && (
                            <p>
                              CPSS includes two levels of testing. Please select
                              the one you'd like to practice:
                            </p>
                          )}
                          {initialQuestion === "CPSS-C" && (
                            <>
                              <ul>
                                <li>
                                  <strong>
                                    Total Tests in Cognitive Ability Test:{" "}
                                  </strong>{" "}
                                  6
                                </li>
                                <li>
                                  <strong>
                                    Total Questions in Cognitive Ability Test:{" "}
                                  </strong>{" "}
                                  212 (MCQ Based)
                                </li>
                                <li>
                                  <strong>
                                    Total Duration in Cognitive Ability Test:{" "}
                                  </strong>{" "}
                                  2 hours 30 minutes
                                </li>
                              </ul>
                              <p>Test Breakdown: </p>
                              <ol>
                                <li>
                                  INSB 1
                                  <ul>
                                    <li>
                                      <b>Questions: </b>15
                                    </li>
                                    <li>
                                      <b>Time: </b>10 mins
                                    </li>
                                    <li>
                                      <b>Focus: </b>Recognizing scales and
                                      meters (Magnetic Compass, Airspeed
                                      Indicator, Altimeter, Altitude Indicator,
                                      Vertical Speed Indicator, Turn
                                      Coordinator)
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  INSB 2
                                  <ul>
                                    <li>
                                      <b>Questions: </b>60
                                    </li>
                                    <li>
                                      <b>Time: </b>20 mins
                                    </li>
                                    <li>
                                      <b>Focus: </b>Determining airplane
                                      position from instruments
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Spatial Ability Test
                                  <ul>
                                    <li>
                                      <b>Questions: </b>30
                                    </li>
                                    <li>
                                      <b>Time: </b>15 mins
                                    </li>
                                    <li>
                                      <b>Focus: </b>Perceiving 2D and 3D figures
                                      in space
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Spatial Visualization Test
                                  <ul>
                                    <li>
                                      <b>Questions: </b>57
                                    </li>
                                    <li>
                                      <b>Time: </b>5 mins
                                    </li>
                                    <li>
                                      <b>Focus: </b>Perceiving the position of
                                      objects in space
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Numerical Reasoning Test
                                  <ul>
                                    <li>
                                      <b>Questions: </b>20
                                    </li>
                                    <li>
                                      <b>Time: </b>20 mins
                                    </li>
                                    <li>
                                      <b>Focus: </b>Numerical skills
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Reasoning Test
                                  <ul>
                                    <li>
                                      <b>Questions: </b>30
                                    </li>
                                    <li>
                                      <b>Time: </b>15 mins
                                    </li>
                                    <li>
                                      <b>Focus: </b>Verbal and non-verbal
                                      reasoning
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <p>
                                As you may have noticed, reading and analyzing
                                instruments such as the Magnetic Compass,
                                Airspeed Indicator, Altimeter, Altitude
                                Indicator, Vertical Speed Indicator, and Turn
                                Coordinator forms a significant part of the
                                Cognitive Ability Test. Since learning by doing
                                is the most effective approach, practicing
                                instrument reading and analysis is essentially
                                like flying in a simulation with all the
                                instrumentation, designed to be as accurate as
                                in a real flight.
                              </p>
                            </>
                          )}
                          {initialQuestion === "CPSS-D" && (
                            <>
                              <ul>
                                <li>
                                  <strong>Total Tests in Psychomotor Test: </strong> 10
                                </li>
                                <li>
                                  <strong>Attempts per Test: </strong> 4 (Best
                                  attempt is considered)
                                </li>
                                <li>
                                  <strong>Total Time given for Psychomotor Test: </strong> 1 hour 30
                                  minutes
                                </li>
                              </ul>
                              <p>Tests in <b>Psychomotor Test</b>:</p>
                              <div className="psychomotor_test">
                                <ol>
                                  {psychoMotorTestArr?.map(
                                    (item, itemIndex) => (
                                      <li key={itemIndex}>
                                        <p>
                                          <span>{item?.name}</span>
                                          <span>
                                            <button>Practice</button>
                                            <div
                                              className="knowMore_tile"
                                              title="Know More"
                                            >
                                              <MdQuestionMark />
                                            </div>
                                          </span>
                                        </p>
                                      </li>
                                    )
                                  )}
                                </ol>
                              </div>
                            </>
                          )}
                          {initialQuestion === "CPSS-E" && (
                            <div className="flightSimulation">
                              {!showFlightSimulation ? (
                                <>
                                  <p>
                                    Before we begin, let’s go over a brief
                                    introduction of all the instruments shown in
                                    CPSS, as they are also widely used in all
                                    aircraft.
                                  </p>
                                  <div className="action_btn_grp">
                                    <button
                                      onClick={() =>
                                        setShowFlightSimulation(true)
                                      }
                                    >
                                      START
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <FlightSimulation />
                              )}
                            </div>
                          )}
                          <div className="action_btn_grp">
                            {initialQuestion === "" && (
                              <>
                                <button
                                  onClick={() =>
                                    handleCPSSInitialAction("CPSS-A")
                                  }
                                >
                                  (A) Have a query related to CPSS
                                </button>
                                <button
                                  onClick={() =>
                                    handleCPSSInitialAction("CPSS-B")
                                  }
                                >
                                  (B) Want to practice CPSS.
                                </button>
                              </>
                            )}
                            {initialQuestion === "CPSS-B" && (
                              <>
                                <button
                                  onClick={() =>
                                    handleCPSSInitialAction("CPSS-C")
                                  }
                                >
                                  (A) Cognitive Ability Test
                                </button>
                                <button
                                  onClick={() =>
                                    handleCPSSInitialAction("CPSS-D")
                                  }
                                >
                                  (B) Psychomotor Test
                                </button>
                              </>
                            )}
                            {initialQuestion === "CPSS-C" && (
                              <>
                                <button
                                  onClick={() =>
                                    handleCPSSInitialAction("CPSS-E")
                                  }
                                >
                                  (A) Flight Simulation
                                </button>
                                <button
                                  onClick={() =>
                                    handleCPSSInitialAction("CPSS-F")
                                  }
                                >
                                  (B) Reasoning Test
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* CPSS CATEGORISED QUESTIONS ENDS */}

                  {/* SEARCH HISORTY AND ASK QUESTION BOX  */}
                  {outputData?.length > 0 &&
                    outputData?.map((ele, idx) => (
                      <>
                        {ele?.question && (
                          <h5 className="gpt_questions" key={idx}>
                            <div className="question_text">{ele?.question}</div>
                            <div
                              className="profile_img"
                              // onClick={handleSpeakFunction}
                            >
                              {
                                // auth?.user?.profile?.url ? <img src={auth?.user?.profile?.url} alt={auth?.user?.profile?.url} className="captainProfilePic" /> :
                                <FaRegUser className="captainProfilePic" />
                              }
                            </div>{" "}
                          </h5>
                        )}
                        {ele?.srtList?.length > 0 && (
                          <h5>
                            {ele?.srtList?.map((itm, id) => (
                              <span key={id}>
                                {id + 1}. {itm}
                              </span>
                            ))}
                          </h5>
                        )}
                        {(ele?.answer || ele?.spokenType?.length > 0) && (
                          <div className="answer_box">
                            <div
                              className="profile_img"
                              onClick={handleSpeakFunction}
                            >
                              <img
                                className="captainProfilePic"
                                src={captainImage}
                                alt="captain modal"
                              />
                              <button>
                                {gptSpeaker ? (
                                  <img
                                    src={profile_mic_active}
                                    alt="speaker function"
                                    className="mic_btn active"
                                  />
                                ) : (
                                  <img
                                    src={profile_mic}
                                    alt="speaker function"
                                    className="mic_btn"
                                  />
                                )}
                              </button>
                            </div>{" "}
                            <div>
                              <div className="output_box">
                                {ele?.spokenType?.length > 0 &&
                                  ele?.spokenType?.map((itm, id) => (
                                    <button
                                      key={id}
                                      onClick={() =>
                                        handleSpokenQuery(itm?.query)
                                      }
                                    >
                                      {itm?.type}
                                    </button>
                                  ))}

                                <div className="video_data">
                                  <div className="video_content" key={idx}>
                                    {ele?.title !== "" && <p>{ele?.title}</p>}
                                    {ele?.url && ele?.url !== null && (
                                      <div className="thumbnail-container">
                                        <iframe
                                          className="thumbnail"
                                          src={ele?.url}
                                          title={ele?.url}
                                          frameBorder="0"
                                          allowFullScreen
                                        />
                                        <div
                                          className="overlay"
                                          onClick={() =>
                                            handleVideoView(ele?.url)
                                          }
                                        >
                                          <BsPlayCircle />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {ele?.answer !== "" ? (
                                  <>
                                    {ele?.answer?.Title && (
                                      <p>
                                        <strong>Title :</strong>
                                        <span>{ele?.answer?.Title}</span>
                                      </p>
                                    )}
                                    {ele?.answer?.Content && (
                                      <p>
                                        <strong>Content :</strong>
                                        <span>{ele?.answer?.Content}</span>
                                      </p>
                                    )}
                                    {ele?.answer?.Summary && (
                                      <p>
                                        <strong>Summary :</strong>
                                        <span>{ele?.answer?.Summary}</span>
                                      </p>
                                    )}
                                    {!ele?.answer?.Title &&
                                      !ele?.answer?.Content &&
                                      !ele?.answer?.Summary && (
                                        <p>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: ele?.answer,
                                            }}
                                          ></span>
                                        </p>
                                      )}
                                  </>
                                ) : (
                                  <div className="video_data">
                                    <div className="video_content" key={idx}>
                                      {ele?.title !== "" && <p>{ele?.title}</p>}
                                      {ele?.url === "Loading..." ? (
                                        <p>{ele?.url}</p>
                                      ) : (
                                        <div
                                          className="thumbnail"
                                          onClick={() =>
                                            handleVideoView(ele?.url)
                                          }
                                        >
                                          <img
                                            src={logo_mobile}
                                            alt="thumbnail"
                                          />
                                          <span>
                                            <BsPlayCircle />
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="action_btn">
                                <button
                                  className="stop_video"
                                  title="Disable Video suggestion"
                                  onClick={() => handleDisableVideo()}
                                >
                                  {disableVideo ? (
                                    <CiVideoOff />
                                  ) : (
                                    <CiVideoOn />
                                  )}
                                  {/* <img src={hideVideo} alt="hideVideo" /> */}
                                </button>
                                <span onClick={() => handleSave(idx)}>
                                  <LuBookmark />
                                  Save
                                </span>
                                <span>
                                  <AiFillDislike />
                                </span>
                                <span>
                                  <AiFillLike />
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}

                  {/* SEARCH HISTORY AND ASK QUESTION BOX ENDS */}

                  <div className="tour_msg"></div>
                  {/* HANLDE TAT LOGICS STARTS  */}

                  {activeTab === "TAT" && (
                    <div className="tat_test_msg">
                      <p>
                        Your test is preparing. It will take upto 5-6 min.{" "}
                        <br />
                        Do not refresh the page in case you may loss data
                      </p>
                    </div>
                  )}

                  {activeTab === "PPDT" && (
                    <div className="tat_test_msg">
                      <p>
                        Your test is preparing. It will take upto 40 - 50 sec.{" "}
                        <br />
                        Do not refresh the page in case you may loss data
                      </p>
                    </div>
                  )}

                  <div className="mock_text_wrapper">
                    {imageQuery === "PPDT" && (
                      <div className="test_start">
                        <div className="test_start">
                          {ppdtTimer > 0 ? (
                            <div className="question_list">
                              <img src={ppdtImage} alt={ppdtImage} />
                            </div>
                          ) : (
                            <textarea rows="5" placeholder="Start writing..." />
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {isLoading && activeTab === "video" && (
                    <div className="search_loader">
                      <img src={loaderGif} alt="loader" />
                    </div>
                  )}

                  {/* {activity === "pushUps" && (
                  <PushupTracker
                    setPushupCount={setPushupCount}
                    setActivity={setActivity}
                    setActivityStatus={setActivityStatus}
                  />
                )} */}
                </div>

                {showSearchInput && (
                  <div className="srch_input">
                    {message !== "" && (
                      <div className="suggestion_box">
                        <p>{message}</p>
                        <div className="box">
                          <button onClick={() => setMessage("")}>No</button>
                          <button onClick={handleSearchPop}>Yes</button>
                        </div>
                      </div>
                    )}

                    {/* SEARCH INPUT AND CATEGORIES START */}
                    <div>
                      {activeTab === "pdf" && (
                        <div
                          className="upload_box"
                          style={{
                            border: activeinput ? "1px solid #fff" : "",
                          }}
                        >
                          <input
                            type="file"
                            accept="*/.pdf"
                            onFocus={() => {
                              setMediaOpen(false);
                            }}
                            onChange={(e) => handleMediaChange(e)}
                            placeholder={placeHolderInput}
                          />
                          <button
                            className="media_btn"
                            onClick={() => handleSearch()}
                          >
                            {isLoading ? <LuLoader /> : <TiArrowUp />}
                          </button>
                        </div>
                      )}

                      {activeTab !== "pdf" && (
                        // && activeTab !== "srt"
                        <input
                          type="text"
                          value={searchValue}
                          style={{
                            border: activeinput ? "1px solid #fff" : "",
                          }}
                          onChange={(e) => handleInputChange(e)}
                          onFocus={() => {
                            setMediaOpen(false);
                          }}
                          onKeyDown={handleKeyDown}
                          placeholder={placeHolderInput}
                        />
                      )}

                      <div className="media_wrapper">
                        <div
                          className={`media_drop_up ${
                            mediaOpen ? "media_drop_up_on" : ""
                          }`}
                        >
                          <ul>
                            <li>
                              <button
                                className={`media_select ask_que ${
                                  activeTab === "question" ? "active" : ""
                                }`}
                                onClick={() => handleAskQuestion()}
                              >
                                <img src={SSBMentorIcon} alt="SSBMentorIcon" />
                                <p>SSB Mentor</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que ${
                                  activeTab === "physical" ? "active" : ""
                                }`}
                                onClick={() => handlePhysicalQuestion()}
                              >
                                <img src={trainer} alt="trainer" />
                                <p>Physical</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que ${
                                  activeTab === "medical" ? "active" : ""
                                }`}
                                onClick={() => handleMedicalQuestion()}
                              >
                                <img src={doctor} alt="doctor" />
                                <p>Medical</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que1 ${
                                  activeTab === "oir" ? "active" : ""
                                }`}
                                onClick={() => handleOIR()}
                              >
                                <img src={OIR} alt="OIR" />
                                <p>OIR Test</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que2 ${
                                  activeTab === "groupDiscussion"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <img
                                  src={group_discussion_icon}
                                  alt="group_discussion_icon"
                                />
                                <p>Group Discussion</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que3 ${
                                  activeTab === "TAT" ? "active" : ""
                                }`}
                                onClick={() => handlePsychologyTest()}
                              >
                                <img src={psychology} alt="psychology" />
                                <p>Psychology Test</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que4 ${
                                  activeTab === "PPDT" ? "active" : ""
                                }`}
                                onClick={() => handleImageGenrate("PPDT")}
                              >
                                <img src={ppdtIcon} alt="ppdtIcon" />
                                <p>PPDT</p>
                              </button>
                            </li>

                            {/*  <li>
                          <button
                            className={`media_select ask_que6 ${
                              activeTab === "pdf" ? "active" : ""
                            }`}
                            onClick={() => handlePdfSearch()}
                          >
                            <img src={searchBook} alt="searchBook" />
                            <p>Book Search</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className="media_select ask_que7"
                            onClick={() => handleMediaOpen()}
                          >
                            <img src={wat1} alt="wat1" />
                            <p>WAT</p>
                          </button>
                        </li> */}
                            {/* <li>
                          <button
                            className="media_select ask_que8"
                            onClick={() => handleVideoSearch()}
                          >
                            <img src={musicPlayer} alt="wat1" />
                            <p>Video Search</p>
                          </button>
                        </li> */}
                            <li>
                              <button className="media_select ask_que9">
                                <img src={Gto} alt="wat1" />
                                <p>GTO</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className="media_select ask_que10"
                                onClick={() => handleNavigateCourses()}
                              >
                                <img src={courses} alt="wat1" />
                                <p>Courses</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className="media_select ask_que11"
                                // onClick={() => handleVideoSearch()}
                              >
                                <img src={olq} alt="wat1" />
                                <p>OLQ Analysis</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className="media_select ask_que12"
                                onClick={() => handleAttendInterview()}
                              >
                                <img src={interview} alt="wat1" />
                                <p>Mock Interview</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que13 ${
                                  activeTab === "currentAffair" ? "active" : ""
                                }`}
                                // onClick={() => handleSpokenEnglish()}
                              >
                                <img src={currentAffair} alt="currentAffair" />
                                <p>Current Affairs</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que14`}
                                // onClick={() => handleSpokenEnglish()}
                                onClick={() => navigate("/spoken-english")}
                              >
                                <img src={spokenEnglish} alt="spokenEnglish" />
                                <p>Spoken English</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que15 ${
                                  activeTab === "CPSS" ? "active" : ""
                                }`}
                                onClick={() => handleCPSSGuidance()}
                              >
                                <img src={CPSS_logo} alt="CPSS_logo" />
                                <p>CPSS</p>
                              </button>
                            </li>
                          </ul>
                        </div>

                        {searchValue === "" ? (
                          <button
                            className="media_btn"
                            onClick={() => handleVoice()}
                          >
                            {isListening ? (
                              <RiVoiceprintFill />
                            ) : (
                              <AiFillAudio />
                            )}
                          </button>
                        ) : (
                          <button
                            className="media_btn"
                            onClick={() => handleSearch()}
                          >
                            {isLoading ? (
                              <LuLoader />
                            ) : (
                              <HiOutlinePaperAirplane />
                            )}
                          </button>
                        )}
                      </div>
                      <button
                        className="search"
                        onClick={(e) => handleMediaOpen(e)}
                      >
                        {mediaOpen ? <MdOutlineKeyboardArrowUp /> : <MdAdd />}
                      </button>
                    </div>
                    {/* SEARCH INPUT AND CATEGORIES ENDS */}

                    {/* <p className="advice_txt">
                    Read our <Link to="/term-of-use">Terms</Link> and{" "}
                    <Link to="/privacy-policies">Privacy Policy</Link>.
                  </p> */}
                  </div>
                )}
              </div>
            </section>
            <GptBooks
              bookList={bookList}
              fetchBookList={fetchBookList}
              handleSave={saveValue}
              outputData={outputData}
              // activeTab={activeTab}
              questionList={questionList}
              handleShowHistory={handleShowHistory}
              activeCaptain={activeTab}
              handleMicFunction={handleMicFunction}
              handleSpeakFunction={handleSpeakFunction}
              gptMic={gptMic}
              gptSpeaker={gptSpeaker}
              loadingNotes={loadingNotes}
              setshowExerciseBar={setshowExerciseBar}
            />
          </section>

          <WhatCanDo />
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default GptSearch;
