import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import {  useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { mountainClimber } from "../../../assets/images";

const TricepsDipsGuidance = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Triceps Dips the Right Way</h2>
          <p>
            Triceps dips are a fantastic bodyweight exercise for strengthening
            the triceps, the back of the shoulders, and even the chest. They can
            be done on a bench, chair, or any stable surface. Follow these steps
            to master triceps dips with good form.
          </p>
          <br />
         
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/6kALZikXxLc"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Set Up the Starting Position</h4>

          <span>
            1. <strong> Find a Stable Surface:</strong>
            Use a bench, sturdy chair, or elevated surface that won’t move.
          </span>

          <span>
            2. <strong> Sit on the Edge and Place Hands Next to Hips: </strong>
            Sit on the edge of the surface, placing your hands shoulder-width
            apart next to your hips, fingers pointing forward.
          </span>
          <span>
            3. <strong>Extend Legs or Bend Knees:</strong> Walk your feet out so
            your hips are off the edge of the surface, and your legs are
            extended in front of you (for more intensity) or bent with feet flat
            on the ground (for easier control).
          </span>
          <img
            src={mountainClimber}
            alt="mountainClimber"
            className="image_box"
          />
          <br />
          <h4 className="subHeading">Step 2: Lower Your Body</h4>
          <span>
            1. <strong>Bend Your Elbows:</strong>
            Slowly bend your elbows to lower your body toward the ground. Your
            elbows should point backward, forming about a 90-degree angle.
          </span>
          <span>
            2. <strong> Keep Your Back Close to the Bench:</strong> Avoid
            letting your body drift too far forward; keep your back close to the
            bench to reduce shoulder strain.
          </span>
          <br />
          <h4 className="subHeading">Step 3: Press Back Up</h4>
          <span>
            1. <strong> Straighten Your Arms:</strong>
            Press through your palms to extend your arms and lift your body back
            to the starting position.
          </span>

          <span>
            2. <strong>Keep Control: </strong>
            Keep your movements slow and controlled, focusing on squeezing your
            triceps at the top.
          </span>
          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Flaring Elbows Outward:</strong> Keep your elbows
            pointed backward, not out to the sides, to protect your shoulders.
          </span>
          <span>
            {"b)."} <strong> Lowering Too Far:</strong> Only lower until your
            elbows are at a 90-degree angle to prevent shoulder strain.
          </span>
          <span>
            {"c)."} <strong>Moving Too Fast: </strong>
            Slow, controlled movement is key to engaging the triceps effectively
            and avoiding injury.
          </span>
          <br />
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default TricepsDipsGuidance;
