import React from "react";
import styled from "styled-components";
import { GrRotateRight } from "react-icons/gr";

const LoaderSec = styled.div`
  color: #081336;
  > svg {
    animation: rotateLoader 1s infinite linear;
  }
`;

const ButtonLoader = () => {
  return (
    <LoaderSec>
      <GrRotateRight />
    </LoaderSec>
  );
};

export default ButtonLoader;
