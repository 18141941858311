export const ATTACH_CHILD_REQUEST = "ATTACH_CHILD_REQUEST";
export const ATTACH_CHILD_SUCCESS = "ATTACH_CHILD_SUCCESS";
export const ATTACH_CHILD_FAIL = "ATTACH_CHILD_FAIL";

export const GET_CHILDS_REQUEST = "GET_CHILDS_REQUEST";
export const GET_CHILDS_SUCCESS = "GET_CHILDS_SUCCESS";
export const GET_CHILDS_FAIL = "GET_CHILDS_FAIL";

export const UPDATE_CHILD_REQUEST = "UPDATE_CHILD_REQUEST";
export const UPDATE_CHILD_SUCCESS = "UPDATE_CHILD_SUCCESS";
export const UPDATE_CHILD_FAIL = "UPDATE_CHILD_FAIL";

export const DELETE_CHILD_REQUEST = "DELETE_CHILD_REQUEST";
export const DELETE_CHILD_SUCCESS = "DELETE_CHILD_SUCCESS";
export const DELETE_CHILD_FAIL = "DELETE_CHILD_FAIL";


export const CLEAR_STATE_ATTACH_CHILD = "CLEAR_STATE_ATTACH_CHILD";
export const CLEAR_ERROR_ATTACH_CHILD = "CLEAR_ERROR_ATTACH_CHILD";