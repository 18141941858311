import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import {  gluteBridge } from "../../../assets/images";

const GluteBridgeGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Glute Bridges the Right Way</h2>
          <p>
            Glute bridges are an effective exercise for strengthening the
            glutes, hamstrings, and lower back. They’re also helpful for
            improving hip mobility and stability. Here’s a step-by-step guide to
            doing glute bridges correctly.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/OUgsJ8-Vi0E"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Get Into Starting Position</h4>

          <span>
            1. <strong>Lie on Your Back: </strong>
            Start by lying on the floor or a mat, with your knees bent and feet
            flat on the ground, hip-width apart. Place your arms by your sides
            with palms facing down.
          </span>

          <span>
            2. <strong>Engage Your Core: </strong>
            Tighten your core to stabilize your spine and avoid arching your
            lower back.
          </span>

          <img src={gluteBridge} alt="gluteBridge" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Lift Your Hips</h4>
          <span>
            1. <strong>Press Through Your Heels:</strong>
            Push through your heels to lift your hips off the ground, creating a
            straight line from your shoulders to your knees.
          </span>
          <span>
            2. <strong>Squeeze Your Glutes: </strong> Focus on engaging and
            squeezing your glute muscles at the top of the movement, while
            keeping your core engaged to avoid overextending your back.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Lower Back Down</h4>
          <span>
            1. <strong>Control the Descent: </strong>
            Slowly lower your hips back to the ground without letting them drop
            too quickly. Keep your core engaged to maintain control.
          </span>

          <span>
            2. <strong>Repeat: </strong>
            Perform the movement for the desired number of repetitions,
            maintaining steady control and focus on your glutes.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Lifting Too High: </strong> Avoid arching your lower
            back by only lifting your hips to the point where your body forms a
            straight line from shoulders to knees.
          </span>
          <span>
            {"b)."} <strong> Pushing Through the Toes: </strong>Focus on
            pressing through your heels to fully engage your glutes. strain your
            back.
          </span>
          <span>
            {"c)."} <strong>Rushing the Movement: </strong>
            Use a slow and controlled motion to ensure proper activation of your
            glutes and hamstrings.
          </span>
          <br />
          <h4 className="subHeading">Variations for Added Intensity</h4>
          <span>
            {"a)."} <strong>Single-Leg Glute Bridge </strong> Perform the bridge
            on one leg to add more resistance and engage your core.
          </span>
          <span>
            {"b)."} <strong>Weighted Glute Bridge: </strong> Place a weight
            (such as a dumbbell or barbell) on your hips to increase resistance.
          </span>

          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default GluteBridgeGuidance;
