import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import {  kneePushUp } from "../../../assets/images";

const KneePushUpGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Knee Push-Ups the Right Way</h2>
          <p>
            Knee push-ups are a great way to build upper body and core strength
            if you’re working up to a standard push-up. They’re effective for
            targeting the chest, shoulders, triceps, and core, but with less
            body weight involved. Here’s a step-by-step guide to ensure you’re
            doing knee push-ups with good form.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/WcHtt6zT3Go"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Get Into Position</h4>

          <span>
            1. <strong>Start in a Plank Position on Your Knees: </strong>
            Begin on your hands and knees, placing your hands slightly wider
            than shoulder-width apart. Shift your body weight forward so your
            shoulders are over your wrists.
          </span>

          <span>
            2. <strong> Engage Your Core:</strong>
            Tighten your core, keeping a straight line from your head to your
            knees.
          </span>

          <img src={kneePushUp} alt="kneePushUp" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Lower Your Body</h4>
          <span>
            1. <strong>Bend Your Elbows: </strong>
            Slowly bend your elbows, lowering your chest toward the ground. Keep
            your elbows at about a 45-degree angle to your body.
          </span>
          <span>
            2. <strong>Inhale as You Lower: </strong> Breathe in as you descend,
            focusing on controlled movement. Lower until your chest is just
            above the floor or as low as your strength allows.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Push Back Up</h4>
          <span>
            1. <strong> Press Through Your Palms: </strong>
            Push through your hands to straighten your arms and return to the
            starting position.
          </span>

          <span>
            2. <strong>Exhale as You Rise:</strong>
            Exhale as you press up, maintaining core engagement to keep your
            body aligned.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Letting Hips Sag or Lift: </strong> Keep a straight
            line from your head to your knees to avoid arching your back or
            lifting your hips.
          </span>
          <span>
            {"b)."} <strong> Flared Elbows: </strong> Keep your elbows angled
            slightly back, not flaring out to the sides, which can strain your
            shoulders.
          </span>
          <span>
            {"c)."} <strong>Limited Range of Motion: </strong>
            Lower as close to the ground as possible to fully activate your
            muscles.
          </span>
          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default KneePushUpGuidance;
