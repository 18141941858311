import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { reverseLunge } from "../../../assets/images";

const ReverseLungeGuidance = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Reverse Lunges the Right Way</h2>
          <p>
            Reverse lunges are an effective exercise for building strength in
            your quads, hamstrings, and glutes, while also enhancing balance and
            stability. Unlike forward lunges, they’re a bit easier on the knees,
            making them a great alternative for those with knee sensitivities.
            Here’s a step-by-step guide to mastering the reverse lunge.
          </p>
          <br />

          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/3XDriUn0udo"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Start in a Standing Position</h4>

          <span>
            1. <strong> Stand with Feet Hip-Width Apart: </strong>
            Begin with your feet about hip-width apart and hands either on your
            hips or resting by your sides.
          </span>
          <span>
            2. <strong>Engage Your Core: </strong>
            Keep your core tight and shoulders back to maintain good posture.
          </span>
          <img src={reverseLunge} alt="reverseLunge" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Rise Up onto Your Toes</h4>
          <span>
            1. <strong>Lift Your Heels: </strong> Slowly raise your heels off
            the ground, shifting your weight onto the balls of your feet. Try to
            reach the highest point you can without losing balance.
          </span>
          <span>
            2. <strong>Squeeze Your Calves:</strong> Focus on squeezing your
            calf muscles at the top of the movement.
          </span>

          {/* <img src={bodySquat2} alt="pushup2" className="image_box" /> */}
          <br />
          <h4 className="subHeading">Step 3: Return to Starting Position</h4>
          <span>
            1. <strong> Push Through the Front Heel:</strong> Press through the
            heel of your front foot to bring your back leg forward and return to
            the starting position.
          </span>

          <span>
            3. <strong>Repeat on the Opposite Side:</strong>
            Alternate legs, performing the same movement with the left leg
            stepping back.
          </span>
          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Leaning Forward Too Much:</strong> Keep your torso
            upright and avoid excessive leaning, which can strain your lower
            back.
          </span>
          <span>
            {"b)."} <strong> Knee Collapsing Inward:</strong> Keep your front
            knee aligned with your front foot to avoid putting stress on your
            knee joint.
          </span>
          <span>
            {"c)."} <strong>Short or Shallow Steps:</strong>
            Take a big enough step back so your front knee stays above your
            ankle rather than moving forward beyond it.
          </span>
          <br />
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default ReverseLungeGuidance;
