import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import {  jumpJack } from "../../../assets/images";

const LegRaisesGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Jumping Jacks the Right Way</h2>
          <p>
            Jumping jacks are a classic full-body exercise that increase heart
            rate, improve endurance, and engage multiple muscle groups,
            including the calves, glutes, shoulders, and core. Here’s a
            step-by-step guide to performing jumping jacks with proper form.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/CWpmIW6l-YA"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Start in a Standing Position</h4>

          <span>
            1.{" "}
            <strong> Stand with Feet Together and Arms by Your Sides: </strong>
            Start by standing tall, with your feet together and arms relaxed by
            your sides.
          </span>

          <span>
            2. <strong>Engage Your Core: </strong>
            Tighten your core to maintain a stable posture throughout the
            movement.
          </span>

          <img src={jumpJack} alt="jumpJack" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Jump and Spread Your Legs</h4>
          <span>
            1. <strong>Jump While Raising Arms and Separating Feet:</strong>
            Simultaneously jump and spread your feet to shoulder-width (or
            wider) apart while raising your arms overhead until your hands meet
            or nearly touch.
          </span>
          <span>
            2. <strong>Land Softly on the Balls of Your Feet: </strong>Focus on
            a gentle landing to reduce impact on your joints.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Return to Starting Position</h4>
          <span>
            1.{" "}
            <strong>
              {" "}
              Jump Again to Bring Feet and Arms Back to Starting Position:{" "}
            </strong>
            Jump once more to bring your feet back together and lower your arms
            to your sides.
          </span>

          <span>
            2. <strong>Repeat for Desired Repetitions: </strong>
            Perform the movement for the desired number of repetitions or as
            part of a timed exercise, maintaining a steady rhythm.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Landing Too Hard:</strong> Land softly on the balls
            of your feet to protect your joints.
          </span>
          <span>
            {"b)."} <strong> Swinging Arms Too Much: </strong> Keep your arm
            movements controlled rather than flailing, for more effective
            shoulder engagement.
          </span>
          <span>
            {"c)."} <strong>Using Poor Posture:</strong>
            Avoid leaning forward or arching your back; maintain an upright
            posture with a tight core.
          </span>
          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default LegRaisesGuidance;
