import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { crunches } from "../../../assets/images";

const CrunchGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Crunches the Right Way</h2>
          <p>
            Crunches are a classic core exercise that specifically targets the
            upper abdominal muscles. They’re simple to perform but require
            attention to form to avoid neck and back strain. Here’s a
            step-by-step guide to doing crunches correctly.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/MKmrqcoCZ-M"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Get Into Starting Position</h4>

          <span>
            1. <strong>Lie on Your Back: </strong>
            Start by lying flat on the floor or a mat. Bend your knees, keeping
            your feet flat on the ground, hip-width apart.
          </span>

          <span>
            2.{" "}
            <strong>Place Hands Behind Your Head or Crossed on Chest: </strong>
            If placing your hands behind your head, keep your elbows wide and
            avoid pulling on your neck. Alternatively, you can cross your arms
            over your chest.
          </span>

          <img src={crunches} alt="crunches" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Engage Your Core and Lift</h4>
          <span>
            1. <strong>Exhale and Lift Your Shoulders:</strong>
            Engage your core and lift your shoulders and upper back off the
            ground, aiming to bring your rib cage closer to your pelvis.
          </span>
          <span>
            2. <strong>Focus on Using Your Core: </strong> Avoid using momentum
            or pulling with your neck. Your lower back should stay on the ground
            as you lift.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Lower Back Down</h4>
          <span>
            1. <strong>Inhale and Lower Slowly: </strong>
            Control your descent as you lower your shoulders back to the ground.
            Avoid letting your body fall quickly.
          </span>

          <span>
            2. <strong>Repeat for Desired Reps: </strong>
            Perform the movement for your desired number of repetitions,
            maintaining steady control and core engagement.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Pulling on the Neck: </strong> Avoid using your
            hands to pull your head forward; instead, focus on lifting with your
            core muscles.
          </span>
          <span>
            {"b)."} <strong> Lifting Too High: </strong> You only need to lift
            your shoulders a few inches off the ground; going too high may
            strain your back.
          </span>
          <span>
            {"c)."} <strong>Using Momentum: </strong>
            Keep the movement slow and controlled to fully engage your abs.
          </span>
          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default CrunchGuidance;
