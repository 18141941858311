import React, { Fragment } from "react";
import "../../styles/englishTrainerProfile.scss";
import CustomHelmet from "../reusable/Helmet";
import GptLayout from "../ssbGpt/gptLayout";
import { FiMessageCircle } from "react-icons/fi";
import markImpectful from "../../images/markImpectful.png";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import meeting_icon from "../../images/icons/meeting_icon.png";
import interview_icon from "../../images/icons/interview_icon.png";
import officeMan from "../../images/icons/office-man.png";
import personalizedPracticeSessions from "../../images/personalizedPracticeSessions.jpg";
import groupDiscussion from "../../images/groupDiscussion.jpg";
import interviewPrep from "../../images/interviewPrep.jpg";
import realTimeFeedback from "../../images/realTimeFeedback.png";
import { GoPlus } from "react-icons/go";
import { markModal } from "../../assets/images";
import { useAuth } from "../../context/userContext";

const EnglishTrainerProfile = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();

  const handleRedirectToChat = () => {
    if (auth?.user?._id) {
      navigate(`/spoken-english`);
    } else {
      navigate("/login", { state: `/spoken-english` });
    }
  };
  return (
    <Fragment>
      <CustomHelmet
        title="Meet Sanjeev Kumar - CPSS Expert - SSB-GPT"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
      <div className="container">
        <div className="englishSpeakingExpertProfile_main txt_color">
          <div className="hero_section">
            <div className="left_sec">
              <div className="heading_top">Your AI Spoken English Trainer</div>
              <h1 className="main_heading">
                Meet Mark – Your AI <span>Spoken English Trainer</span>
              </h1>
              <p className="description">
                Mark is a state-of-the-art AI language trainer designed to help
                SSB aspirants develop clear, confident, and effective English
                communication skills. Tailored to meet the needs of SSB
                interviews, Mark focuses on improving vocabulary, pronunciation,
                fluency, and confidence, helping you make a strong impression.
              </p>
              <button className="talk_captain" onClick={handleRedirectToChat}>
                <FiMessageCircle /> Talk to Mark
              </button>
            </div>
            <div className="right_sec">
              <div className="intro_modal_sec">
                <div className="profile_pic">
                  <img src={markModal} alt="markModal" />
                </div>
                <div className="name_sec">
                  <div className="name">Mark</div>
                  <div className="desc">AI English Trainer</div>
                </div>
              </div>
              <div className="social_links">
                <Link to="https://www.facebook.com/profile.php?id=61568445329140" title="Facebook" target="_blank">
                  <FaFacebookF />
                </Link>
                <Link to="https://x.com/AImarktrainer" title="Twitter" target="_blank">
                  <FaXTwitter />
                </Link>
                <Link to="https://www.youtube.com/@AI-Mark" title="Youtube" target="_blank">
                  <FaYoutube />
                </Link>
                <Link to="https://www.instagram.com/aimarktrainer/" title="Instagram" target="_blank">
                  <FaInstagram />
                </Link>
              </div>
            </div>
          </div>

          <div className="effective_member">
            <h2 className="heading">
              MARK: YOUR AI SPOKEN ENGLISH TRAINER,{" "}
              <span>AS IMPACTFUL AS A PERSONAL LANGUAGE COACH!</span>
            </h2>
            <p className="desc">
              Advanced AI provides realistic conversation practice, helping
              candidates enhance their spoken English skills dynamically.
            </p>
            <div className="ai_img">
              <img src={markImpectful} alt="markImpectful" />
            </div>
          </div>

          <div className="next_level_prep">
            <h2 className="heading">
              EXPERIENCE ELITE-LEVEL SPOKEN ENGLISH{" "}
              <span>TRAINING AND GUIDANCE TODAY.</span>
            </h2>
            <div className="group_task_strategy">
              <div className="left_sec">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  Personalized Practice <img src={officeMan} alt="office-man" />{" "}
                  Sessions
                </h2>
                <p className="desc">
                  Mark identifies your strengths and areas for improvement,
                  offering targeted exercises to help you achieve fluent,
                  natural conversation skills.
                </p>
                <button className="talk_btn" onClick={handleRedirectToChat}>
                  <GoPlus />
                  Talk You To Mark
                </button>
              </div>
              <div className="right_sec">
                <div className="img_sec">
                  <img src={personalizedPracticeSessions} alt="personalizedPracticeSessions" />
                </div>
              </div>
            </div>

            <div className="core_expretise_sec">
              <div className="psychTestPrep">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  Group Discussion and <img src={meeting_icon} alt="meeting_icon" />{" "}
                  Lecturette
                </h2>
                <p className="desc">
                  Improve your fluency, clarity, and confidence in delivering
                  structured responses in group and individual formats.
                </p>
                <img src={groupDiscussion} alt="groupDiscussion" />
              </div>
              <div className="interviewSkillsPersonality">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">Interview Preparation <img src={interview_icon} alt="interview_icon" /></h2>
                <p className="desc">
                  Practice question-and-answer formats that enhance your ability
                  to express ideas clearly, showcasing confidence and presence.
                </p>
                <img
                  src={interviewPrep}
                  alt="interviewPrep"
                />
              </div>
            </div>

            <div className="militaryAwareness">
              <div className="right_sec">
                <div className="img_sec">
                  <div className="circle_sec">
                    <div className="red"></div>
                    <div className="blue"></div>
                    <div className="yellow"></div>
                  </div>
                </div>
                <img
                  src={realTimeFeedback}
                  alt="realTimeFeedback"
                />
              </div>
              <div className="left_sec">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  Real-Time Feedback and Corrections
                </h2>
                <p className="desc">
                  Receive instant feedback on pronunciation, grammar, and
                  fluency, helping you improve with every practice session.
                </p>
                <p className="desc">
                  Vocabulary Building for Impact: Mark guides you in developing
                  a powerful vocabulary to enhance the clarity and effectiveness
                  of your responses.
                </p>
                <p className="desc">
                  Pronunciation and Accent Practice: Mark uses advanced AI to
                  correct and refine your accent, making sure you sound clear
                  and confident.
                </p>
                <button className="talk_btn" onClick={handleRedirectToChat}>
                  <GoPlus />
                  Talk you to Mark
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default EnglishTrainerProfile;
