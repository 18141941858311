import React, { Fragment } from "react";
import "../../styles/whatCanDo.scss";
import {
  askSsbInterview,
  assessOlq,
  enhanceEnglish,
  getExpertGuidance,
  gtoGroundAccess,
  keepNotesOrganized,
  masterCurrent,
  practiceWatSrt,
  practicetatPpdt,
  unlimitedMockInterviews,
  unlimitedOirpractice,
  videoNotesfeature,
} from "../../assets/images";

const WhatCanDo = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="why_choose_us_ssb_gpt">
          <h1 className="title">What Can You Do with SSB-GPT?</h1>
          <div className="card_list">
            <div className="card">
              <img src={assessOlq} alt="explore campus" />
              <h2> Assess Your OLQs</h2>
              <p>
                Take a simple psychometrics MCQ test to evaluate your Officer
                Like Qualities (OLQs), identify your weaknesses, and start
                working on them effectively.
              </p>
            </div>
            <div className="card">
              <img src={practicetatPpdt} alt="connect improve" />
              <h2>Practice TAT & PPDT</h2>
              <p>
                Enhance your TAT & PPDT prep with AI-generated images and story
                checks. Start preparing now and boost your success with AI's
                assistance!
              </p>
            </div>
            <div className="card">
              <img src={keepNotesOrganized} alt="connect practice" />
              <h2>Keep Your Notes Organized</h2>
              <p>
                Save and organize screenshots, images, text, and videos. Evolve
                your note-taking with all-in-one digital access. Stay organized
                and access your notes anytime, anywhere.
              </p>
            </div>
            <div className="card">
              <img src={videoNotesfeature} alt="connect practice" />
              <h2>Video Notes Feature</h2>
              <p>
                Easily find videos related to your questions, add notes directly
                within the video for future reference, and save everything in
                your notes for convenient access.
              </p>
            </div>
            <div className="card">
              <img src={practiceWatSrt} alt="connect practice" />
              <h2>Practice WAT & SRT</h2>
              <p>
                Enhance your WAT & SRT preparation with AI-generated scenarios
                and timed word exercises. Receive instant AI feedback on your
                answers. Begin your preparation today!
              </p>
            </div>
            <div className="card">
              <img src={gtoGroundAccess} alt="connect practice" />
              <h2>Master GTO Tasks</h2>
              <p>
                Practice GTO tasks using 3D simulation to excel in PGT, HGT, and
                FGT by analyzing structures and enhancing performance for the
                Service Selection Board
              </p>
            </div>
            <div className="card">
              <img src={unlimitedOirpractice} alt="connect practice" />
              <h2>Unlimited OIR Test Practice</h2>
              <p>
                Practice unlimited OIR tests to get immediate ratings, then
                improve with regular practice to enhance skills and boost
                confidence quickly.
              </p>
            </div>
            <div className="card">
              <img src={unlimitedMockInterviews} alt="connect practice" />
              <h2>Unlimited Mock Interviews with AI</h2>
              <p>
                Practice unlimited mock personal interviews with our advanced AI
                to prepare for your SSB interview and build your confidence
                effectively.
              </p>
            </div>
            <div className="card">
              <img src={askSsbInterview} alt="connect practice" />
              <h2>Ask SSB Interview Questions from AI</h2>
              <p>
                Get answers to your SSB interview queries instantly from our
                AI-powered platform.
              </p>
            </div>
            <div className="card">
              <img src={getExpertGuidance} alt="connect practice" />
              <h2>Get Expert Guidance</h2>
              <p>
                Prepare for your SSB Interview with ease. Stay updated on daily
                current affairs without reading multiple newspapers.
              </p>
            </div>

            <div className="card">
              <img src={masterCurrent} alt="connect practice" />
              <h2>Master Current Affairs for SSB</h2>
              <p>
                Prepare for your SSB Interview with ease. Stay updated on daily
                current affairs without reading multiple newspapers.
              </p>
            </div>

            <div className="card">
              <img src={enhanceEnglish} alt="connect practice" />
              <h2>Enhance Your English with SSB-GPT</h2>
              <p>
                Improve your English communication skills with SSB-GPT. Get
                tailored practice and feedback for effective speaking and
                writing.
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WhatCanDo;
