import {
  ATTACH_CHILD_FAIL,
  ATTACH_CHILD_REQUEST,
  ATTACH_CHILD_SUCCESS,
  CLEAR_ERROR_ATTACH_CHILD,
  CLEAR_STATE_ATTACH_CHILD,
  DELETE_CHILD_FAIL,
  DELETE_CHILD_REQUEST,
  DELETE_CHILD_SUCCESS,
  GET_CHILDS_FAIL,
  GET_CHILDS_REQUEST,
  GET_CHILDS_SUCCESS,
  UPDATE_CHILD_FAIL,
  UPDATE_CHILD_REQUEST,
  UPDATE_CHILD_SUCCESS,
} from "../constants/attachChildConstant";

// ATTACH CHILD BY PARENT REDUCER
export const attachChildReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTACH_CHILD_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case ATTACH_CHILD_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case ATTACH_CHILD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_STATE_ATTACH_CHILD:
      return {
        loading: false,
        data: {},
      };
    case CLEAR_ERROR_ATTACH_CHILD:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET CHILD BY PARENT REDUCER
export const getChildsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHILDS_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case GET_CHILDS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_CHILDS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR_ATTACH_CHILD:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE CHILD BY PARENT REDUCER
export const updateChildReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CHILD_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case UPDATE_CHILD_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case UPDATE_CHILD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_STATE_ATTACH_CHILD:
      return {
        loading: false,
        data: {},
      };
    case CLEAR_ERROR_ATTACH_CHILD:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE CHILD BY PARENT REDUCER
export const deleteChildReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CHILD_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case DELETE_CHILD_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case DELETE_CHILD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_STATE_ATTACH_CHILD:
      return {
        loading: false,
        data: {},
      };
    case CLEAR_ERROR_ATTACH_CHILD:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
