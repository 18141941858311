// ExerciseTracker.js
import React, { useState, useRef, useEffect, Fragment } from "react";
import * as posenet from "@tensorflow-models/posenet";
import "@tensorflow/tfjs";
import { useLocation, useNavigate } from "react-router-dom";
import {
  armExercise,
  bearExercise,
  bicycleExercise,
  burpeeExercise,
  calfExercise,
  crunchExercise,
  exerciseStep1,
  exerciseStep2,
  gluteExercise,
  jumpingExercise,
  kneePushExercise,
  legExercise,
  lungeExercise,
  mountainExercise,
  pullExercise,
  PushUpExercise,
  squatExercise,
  squatExercise2,
  stepExercise,
  tricepExercise,
  wallExercise,
} from "../../assets/images";
import "../../styles/exercise.scss";
import CustomButton from "../reusable/CustomButton";

const ExerciseTracker = () => {
  const location = useLocation();
  const [exercise, setExercise] = useState("");
  const [count, setCount] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [isTracking, setIsTracking] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [net, setNet] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get("type");
    if (type !== "") {
      setExercise(type);
    }
  }, [location]);

  // useEffect(() => {
  //   const loadModel = async () => {
  //     const loadedNet = await posenet.load();
  //     setNet(loadedNet);
  //   };
  //   loadModel();
  // }, []);

  useEffect(() => {
    if (isTracking) {
      startVideo();
    } else {
      stopVideo();
    }
  }, [isTracking]);

  const startVideo = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = stream;

    // Add an event listener to ensure video is ready before starting pose detection
    videoRef.current.onloadeddata = () => {
      videoRef.current.play();
      detectPose();
      // Start detection after video is ready
    };
  };

  const stopVideo = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
  };

  const detectPose = async () => {
    // Check if the model is loaded and tracking is enabled

    if (
      !net ||
      !isTracking ||
      videoRef.current.videoWidth === 0 ||
      videoRef.current.videoHeight === 0
    ) {
      requestAnimationFrame(detectPose); // Try again on next frame
      return;
    }

    const pose = await net.estimateSinglePose(videoRef.current, {
      flipHorizontal: false,
    });
    console.log("runnning....");

    trackExercise(pose);
    drawPose(pose);
    requestAnimationFrame(detectPose);
  };

  // LOAD OR DETECT CANVAS ANGEL AND DRAW IT ON SCREEN
  let keypoints;

  const drawPose = (pose, exercise) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Set canvas dimensions to match the video
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    // Clear the previous drawings on the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Filter keypoints with a minimum confidence score of 0.5
    keypoints = pose.keypoints.filter((keypoint) => keypoint.score > 0.5);

    // Draw all keypoints
    keypoints.forEach((keypoint) => {
      const { y, x } = keypoint.position;
      ctx.beginPath();
      ctx.arc(x, y, 5, 0, 2 * Math.PI);
      ctx.fillStyle = "red";
      ctx.fill();
    });

    // Helper function to draw lines
    const drawLine = (start, end, color = "blue") => {
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      ctx.lineTo(end.x, end.y);
      ctx.lineWidth = 4;
      ctx.strokeStyle = color;
      ctx.stroke();
    };

    // Exercise-specific lines

    switch (exercise) {
      case "Pushups":
        // Lines for arm and shoulder alignment
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftWrist")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightElbow")
        );
        drawLine(
          getKeypointPosition("rightElbow"),
          getKeypointPosition("rightWrist")
        );
        break;

      case "Squats":
        // Lines for hips to knees alignment
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Reverse Lunges":
        // Hip and knee alignment for each leg
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Mountain Climbers":
        // Lines for arm and hip movements
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftWrist")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightElbow")
        );
        drawLine(
          getKeypointPosition("rightElbow"),
          getKeypointPosition("rightWrist")
        );
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        break;

      case "Tricep dips":
        // Arm and shoulder positioning
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftWrist")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightElbow")
        );
        drawLine(
          getKeypointPosition("rightElbow"),
          getKeypointPosition("rightWrist")
        );
        break;

      case "Burpee":
        // Whole body lines for squat and jump phases
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftWrist")
        );
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        break;

      case "Calf raises":
        // Foot and ankle alignment
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Knee push-ups":
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftWrist")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightElbow")
        );
        drawLine(
          getKeypointPosition("rightElbow"),
          getKeypointPosition("rightWrist")
        );
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        break;
      case "Crunch":
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftHip")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightHip")
        );
        break;

      case "Glute bridge":
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftHip")
        );
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightHip")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Jumping Jack":
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftWrist")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightElbow")
        );
        drawLine(
          getKeypointPosition("rightElbow"),
          getKeypointPosition("rightWrist")
        );
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Leg raises":
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Step-up":
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Bodyweight Squat":
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Bicycle crunches":
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftHip")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightElbow")
        );
        drawLine(
          getKeypointPosition("rightElbow"),
          getKeypointPosition("rightHip")
        );
        break;

      case "Wall Sit":
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftHip")
        );
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightHip")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Arm Circles":
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftWrist")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightElbow")
        );
        drawLine(
          getKeypointPosition("rightElbow"),
          getKeypointPosition("rightWrist")
        );
        break;

      case "Bear Crawl":
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftWrist")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightElbow")
        );
        drawLine(
          getKeypointPosition("rightElbow"),
          getKeypointPosition("rightWrist")
        );
        drawLine(
          getKeypointPosition("leftHip"),
          getKeypointPosition("leftKnee")
        );
        drawLine(
          getKeypointPosition("leftKnee"),
          getKeypointPosition("leftAnkle")
        );
        drawLine(
          getKeypointPosition("rightHip"),
          getKeypointPosition("rightKnee")
        );
        drawLine(
          getKeypointPosition("rightKnee"),
          getKeypointPosition("rightAnkle")
        );
        break;

      case "Pull-ups":
        drawLine(
          getKeypointPosition("leftShoulder"),
          getKeypointPosition("leftElbow")
        );
        drawLine(
          getKeypointPosition("leftElbow"),
          getKeypointPosition("leftWrist")
        );
        drawLine(
          getKeypointPosition("rightShoulder"),
          getKeypointPosition("rightElbow")
        );
        drawLine(
          getKeypointPosition("rightElbow"),
          getKeypointPosition("rightWrist")
        );
        break;

      default:
        console.warn("Exercise not recognized in drawing function");
    }
  };

  // Helper to get keypoint positions safely
  const getKeypointPosition = (part) => {
    const keypoint = keypoints.find((k) => k.part === part);
    return keypoint ? keypoint.position : { x: 0, y: 0 };
  };

  let isExercising = {
    pushUp: false,
    squat: false,
    lunge: false,
    reverseLunge: false,
    burpee: false,
    calfRaise: false,
    kneePushUp: false,
    crunch: false,
    gluteBridge: false,
    jumpingJack: false,
    legRaise: false,
    stepUp: false,
    bodyweightSquat: false,
    bicycleCrunch: false,
    wallSit: false,
    armCircle: false,
    bearCrawl: false,
    pullUp: false,
  };

  // PUSH-UP detector
  const detectPushUp = (pose) => {
    // Extract necessary keypoints for push-up
    const leftElbow = pose.keypoints.find(
      (point) => point.part === "leftElbow"
    );
    const rightElbow = pose.keypoints.find(
      (point) => point.part === "rightElbow"
    );
    const leftShoulder = pose.keypoints.find(
      (point) => point.part === "leftShoulder"
    );
    const rightShoulder = pose.keypoints.find(
      (point) => point.part === "rightShoulder"
    );

    if (leftElbow && rightElbow && leftShoulder && rightShoulder) {
      // Measure the vertical distance from elbow to shoulder to track depth
      const elbowToShoulderDistance = Math.abs(
        leftElbow.position.y - leftShoulder.position.y
      );

      // Check if the user is in the downward position of a push-up
      if (elbowToShoulderDistance < 20 && !isExercising.pushUp) {
        setFeedback("Going down...");
        isExercising.pushUp = true; // Start counting the push-up
      }
      // When the user pushes up and extends their arms, complete the push-up
      else if (isExercising.pushUp && elbowToShoulderDistance > 50) {
        setCount(count + 1);
        setFeedback("Push-up complete!");
        isExercising.pushUp = false; // Reset for the next push-up
      }
    }
  };

  // SQUAT detector
  const detectSquat = (pose) => {
    // Extract necessary keypoints for squat
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );

    if (leftHip && leftKnee && leftAnkle) {
      // Measure distance from knee to ankle to determine squat depth
      const kneeAnkleDistance = Math.abs(
        leftKnee.position.y - leftAnkle.position.y
      );

      // Check if the user is in the bottom position of a squat
      if (kneeAnkleDistance < 30 && !isExercising.squat) {
        setFeedback("Squatting...");
        isExercising.squat = true; // Begin tracking squat
      }
      // When the user returns to a standing position, complete the squat
      else if (isExercising.squat && kneeAnkleDistance > 50) {
        setCount(count + 1);
        setFeedback("Squat complete!");
        isExercising.squat = false; // Reset for the next squat
      }
    }
  };

  // LUNGE detector
  const detectLunge = (pose) => {
    // Extract keypoints for lunge tracking
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );

    if (leftHip && leftKnee && leftAnkle) {
      // Check the vertical alignment between hip, knee, and ankle to determine lunge depth
      const kneeAnkleDistance = Math.abs(
        leftKnee.position.y - leftAnkle.position.y
      );
      const hipKneeDistance = Math.abs(
        leftHip.position.y - leftKnee.position.y
      );

      // Detect if the user has reached the lunge depth position
      if (
        hipKneeDistance < 30 &&
        kneeAnkleDistance < 20 &&
        !isExercising.lunge
      ) {
        setFeedback("Lunging...");
        isExercising.lunge = true;
      }
      // When the user returns to standing, the lunge is complete
      else if (isExercising.lunge && hipKneeDistance > 50) {
        setCount(count + 1);
        setFeedback("Lunge complete!");
        isExercising.lunge = false; // Reset for the next lunge
      }
    }
  };

  // MOUNTAIN CLIMBER detector
  const detectMountainClimber = (pose) => {
    // Extract keypoints for knee and hip tracking
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const rightKnee = pose.keypoints.find(
      (point) => point.part === "rightKnee"
    );
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const rightHip = pose.keypoints.find((point) => point.part === "rightHip");

    if (leftKnee && rightKnee && leftHip && rightHip) {
      // Measure distance from knees to hips to detect leg movement
      const leftKneeToHip = Math.abs(leftKnee.position.y - leftHip.position.y);
      const rightKneeToHip = Math.abs(
        rightKnee.position.y - rightHip.position.y
      );

      // Detect if one knee is high, indicating a mountain climber step
      if (
        (leftKneeToHip < 20 || rightKneeToHip < 20) &&
        !isExercising.mountainClimber
      ) {
        setFeedback("Knee up!");
        isExercising.mountainClimber = true;
      }
      // When both knees are down, a mountain climber step is complete
      else if (
        isExercising.mountainClimber &&
        leftKneeToHip > 50 &&
        rightKneeToHip > 50
      ) {
        setCount(count + 1);
        setFeedback("Mountain climber step complete!");
        isExercising.mountainClimber = false; // Reset for the next step
      }
    }
  };

  // TRICEPS DIP detector
  const detectTricepsDip = (pose) => {
    // Extract keypoints for triceps dip tracking
    const leftElbow = pose.keypoints.find(
      (point) => point.part === "leftElbow"
    );
    const leftShoulder = pose.keypoints.find(
      (point) => point.part === "leftShoulder"
    );
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");

    if (leftElbow && leftShoulder && leftHip) {
      // Measure distance from elbow to shoulder and shoulder to hip
      const elbowShoulderDist = Math.abs(
        leftElbow.position.y - leftShoulder.position.y
      );
      const hipShoulderDist = Math.abs(
        leftHip.position.y - leftShoulder.position.y
      );

      // Check if the user is lowering into a triceps dip
      if (
        elbowShoulderDist < 20 &&
        hipShoulderDist > 50 &&
        !isExercising.tricepsDip
      ) {
        setFeedback("Going down...");
        isExercising.tricepsDip = true;
      }
      // When user pushes up, the dip is complete
      else if (isExercising.tricepsDip && elbowShoulderDist > 50) {
        setCount(count + 1);
        setFeedback("Triceps dip complete!");
        isExercising.tricepsDip = false; // Reset for the next dip
      }
    }
  };

  // REVERSE LUNGE tracker
  const detectReverseLunge = (pose) => {
    // Keypoints for tracking leg positions
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );
    const rightHip = pose.keypoints.find((point) => point.part === "rightHip");
    const rightKnee = pose.keypoints.find(
      (point) => point.part === "rightKnee"
    );
    const rightAnkle = pose.keypoints.find(
      (point) => point.part === "rightAnkle"
    );

    if (
      leftHip &&
      leftKnee &&
      leftAnkle &&
      rightHip &&
      rightKnee &&
      rightAnkle
    ) {
      // const leftKneeToAnkle = Math.abs(
      //   leftKnee.position.y - leftAnkle.position.y
      // );
      const rightKneeToAnkle = Math.abs(
        rightKnee.position.y - rightAnkle.position.y
      );

      // Check for back leg bent position
      if (
        rightKnee.position.y < rightHip.position.y &&
        !isExercising.reverseLunge
      ) {
        setFeedback("Step back...");
        isExercising.reverseLunge = true;
      }
      // When front knee straightens back up, lunge is complete
      else if (isExercising.reverseLunge && rightKneeToAnkle > 40) {
        setCount(count + 1);
        setFeedback("Reverse Lunge complete!");
        isExercising.reverseLunge = false;
      }
    }
  };

  // BURPEE tracker
  const detectBurpee = (pose) => {
    // Keypoints for tracking push-up and jump positions
    const leftShoulder = pose.keypoints.find(
      (point) => point.part === "leftShoulder"
    );
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );

    if (leftShoulder && leftHip && leftKnee && leftAnkle) {
      const shoulderToHip = Math.abs(
        leftShoulder.position.y - leftHip.position.y
      );
      const kneeToAnkle = Math.abs(leftKnee.position.y - leftAnkle.position.y);

      // Detect plank position (starting phase of burpee)
      if (shoulderToHip < 20 && kneeToAnkle < 20 && !isExercising.burpee) {
        setFeedback("In plank position...");
        isExercising.burpee = true;
      }
      // Detect jump position by checking the leg extension
      else if (isExercising.burpee && shoulderToHip > 50 && kneeToAnkle > 50) {
        setCount(count + 1);
        setFeedback("Burpee complete!");
        isExercising.burpee = false;
      }
    }
  };

  // CALF RAISE tracker
  const detectCalfRaise = (pose) => {
    // Keypoints for tracking ankle elevation
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");

    if (leftAnkle && leftHip) {
      const ankleToGround = leftAnkle.position.y;

      // Detect elevation of ankle from the ground
      if (ankleToGround < 30 && !isExercising.calfRaise) {
        setFeedback("Raising...");
        isExercising.calfRaise = true;
      }
      // When ankle returns to original position, calf raise is complete
      else if (isExercising.calfRaise && ankleToGround > 50) {
        setCount(count + 1);
        setFeedback("Calf Raise complete!");
        isExercising.calfRaise = false;
      }
    }
  };

  // KNEE PUSH-UP tracker
  const detectKneePushUp = (pose) => {
    // Keypoints for tracking arm and hip positions for a knee push-up
    const leftElbow = pose.keypoints.find(
      (point) => point.part === "leftElbow"
    );
    const leftShoulder = pose.keypoints.find(
      (point) => point.part === "leftShoulder"
    );
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");

    if (leftElbow && leftShoulder && leftHip && leftKnee) {
      const elbowToShoulder = Math.abs(
        leftElbow.position.y - leftShoulder.position.y
      );
      const shoulderToHip = Math.abs(
        leftShoulder.position.y - leftHip.position.y
      );

      // Detect the downward position in a knee push-up
      if (
        elbowToShoulder < 20 &&
        shoulderToHip < 20 &&
        !isExercising.kneePushUp
      ) {
        setFeedback("Lowering...");
        isExercising.kneePushUp = true;
      }
      // When the user pushes back up, complete the knee push-up
      else if (isExercising.kneePushUp && elbowToShoulder > 50) {
        setCount(count + 1);
        setFeedback("Knee Push-up complete!");
        isExercising.kneePushUp = false;
      }
    }
  };

  // CRUNCH tracker
  const detectCrunch = (pose) => {
    // Keypoints for tracking crunch movement
    const leftShoulder = pose.keypoints.find(
      (point) => point.part === "leftShoulder"
    );
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");

    if (leftShoulder && leftHip && leftKnee) {
      const shoulderToHipDistance = Math.abs(
        leftShoulder.position.y - leftHip.position.y
      );

      // Detect upward crunch position (when shoulders are lifted closer to knees)
      if (shoulderToHipDistance < 50 && !isExercising.crunch) {
        setFeedback("Crunch up...");
        isExercising.crunch = true;
      }
      // When shoulders move back down, complete the crunch
      else if (isExercising.crunch && shoulderToHipDistance > 70) {
        setCount(count + 1);
        setFeedback("Crunch complete!");
        isExercising.crunch = false;
      }
    }
  };

  // GLUTE BRIDGE tracker
  const detectGluteBridge = (pose) => {
    // Keypoints for tracking hip lift
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );

    if (leftHip && leftKnee && leftAnkle) {
      const hipToGround = leftHip.position.y;
      const kneeToAnkleDistance = Math.abs(
        leftKnee.position.y - leftAnkle.position.y
      );

      // Detect upward position (hips lifted off the ground)
      if (hipToGround < kneeToAnkleDistance && !isExercising.gluteBridge) {
        setFeedback("Lift up...");
        isExercising.gluteBridge = true;
      }
      // When hips return to ground level, glute bridge is complete
      else if (isExercising.gluteBridge && hipToGround > kneeToAnkleDistance) {
        setCount(count + 1);
        setFeedback("Glute Bridge complete!");
        isExercising.gluteBridge = false;
      }
    }
  };

  // JUMPING JACK tracker
  const detectJumpingJack = (pose) => {
    // Keypoints for tracking arms and legs positions
    const leftWrist = pose.keypoints.find(
      (point) => point.part === "leftWrist"
    );
    const rightWrist = pose.keypoints.find(
      (point) => point.part === "rightWrist"
    );
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );
    const rightAnkle = pose.keypoints.find(
      (point) => point.part === "rightAnkle"
    );

    if (leftWrist && rightWrist && leftAnkle && rightAnkle) {
      const handsRaised =
        leftWrist.position.y < leftAnkle.position.y &&
        rightWrist.position.y < rightAnkle.position.y;
      const legsApart =
        Math.abs(leftAnkle.position.x - rightAnkle.position.x) > 50;

      // Detect jumping jack "jumping" position (arms raised and legs apart)
      if (handsRaised && legsApart && !isExercising.jumpingJack) {
        setFeedback("Jumping...");
        isExercising.jumpingJack = true;
      }
      // When hands lower and legs close, complete the jumping jack
      else if (isExercising.jumpingJack && !handsRaised && !legsApart) {
        setCount(count + 1);
        setFeedback("Jumping Jack complete!");
        isExercising.jumpingJack = false;
      }
    }
  };

  // LEG RAISE tracker
  const detectLegRaise = (pose) => {
    // Keypoints for tracking leg lift
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );

    if (leftHip && leftKnee && leftAnkle) {
      const kneeToHip = Math.abs(leftKnee.position.y - leftHip.position.y);

      // Detect raised leg position (when knee and ankle are lifted towards hip)
      if (kneeToHip < 30 && !isExercising.legRaise) {
        setFeedback("Legs up...");
        isExercising.legRaise = true;
      }
      // When legs return to initial position, complete the leg raise
      else if (isExercising.legRaise && kneeToHip > 50) {
        setCount(count + 1);
        setFeedback("Leg Raise complete!");
        isExercising.legRaise = false;
      }
    }
  };

  // STEP-UP tracker
  const detectStepUp = (pose) => {
    // Keypoints for tracking leg movement for step-up
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");

    if (leftAnkle && leftKnee && leftHip) {
      const kneeToAnkleDistance = Math.abs(
        leftKnee.position.y - leftAnkle.position.y
      );

      // Detect upward step-up position (when knee is closer to hip)
      if (kneeToAnkleDistance < 50 && !isExercising.stepUp) {
        setFeedback("Stepping up...");
        isExercising.stepUp = true;
      }
      // When knee returns to original position, complete the step-up
      else if (isExercising.stepUp && kneeToAnkleDistance > 70) {
        setCount(count + 1);
        setFeedback("Step-up complete!");
        isExercising.stepUp = false;
      }
    }
  };

  // BODYWEIGHT SQUAT tracker
  const detectBodyweightSquat = (pose) => {
    // Keypoints for tracking squat movement
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );

    if (leftHip && leftKnee && leftAnkle) {
      const hipToKneeDistance = Math.abs(
        leftHip.position.y - leftKnee.position.y
      );

      // Detect squat position (when hips are lowered near knee level)
      if (hipToKneeDistance < 30 && !isExercising.bodyweightSquat) {
        setFeedback("Squatting down...");
        isExercising.bodyweightSquat = true;
      }
      // When hips return to starting position, complete the squat
      else if (isExercising.bodyweightSquat && hipToKneeDistance > 50) {
        setCount(count + 1);
        setFeedback("Squat complete!");
        isExercising.bodyweightSquat = false;
      }
    }
  };

  // BICYCLE CRUNCH tracker
  const detectBicycleCrunch = (pose) => {
    // Keypoints for tracking elbow and knee positioning
    const leftElbow = pose.keypoints.find(
      (point) => point.part === "leftElbow"
    );
    const rightKnee = pose.keypoints.find(
      (point) => point.part === "rightKnee"
    );
    const rightElbow = pose.keypoints.find(
      (point) => point.part === "rightElbow"
    );
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");

    if (leftElbow && rightKnee && rightElbow && leftKnee) {
      const leftElbowToRightKnee = Math.hypot(
        leftElbow.position.x - rightKnee.position.x,
        leftElbow.position.y - rightKnee.position.y
      );
      const rightElbowToLeftKnee = Math.hypot(
        rightElbow.position.x - leftKnee.position.x,
        rightElbow.position.y - leftKnee.position.y
      );

      // Detect crunch position (when opposite elbow and knee are close)
      if (leftElbowToRightKnee < 30 && !isExercising.bicycleCrunch) {
        setFeedback("Left Crunch...");
        isExercising.bicycleCrunch = true;
      } else if (rightElbowToLeftKnee < 30 && isExercising.bicycleCrunch) {
        setCount(count + 1);
        setFeedback("Right Crunch complete!");
        isExercising.bicycleCrunch = false;
      }
    }
  };

  // WALL SIT tracker
  const detectWallSit = (pose) => {
    // Keypoints for tracking hip and knee alignment
    const leftHip = pose.keypoints.find((point) => point.part === "leftHip");
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const leftAnkle = pose.keypoints.find(
      (point) => point.part === "leftAnkle"
    );

    if (leftHip && leftKnee && leftAnkle) {
      const hipToKneeDistance = Math.abs(
        leftHip.position.y - leftKnee.position.y
      );
      const kneeToAnkleDistance = Math.abs(
        leftKnee.position.y - leftAnkle.position.y
      );

      // Detect wall sit position (hips aligned with knees, knees above ankles)
      if (
        hipToKneeDistance < 30 &&
        kneeToAnkleDistance > 50 &&
        !isExercising.wallSit
      ) {
        setFeedback("Hold Wall Sit position...");
        isExercising.wallSit = true;
      }
      // Maintain count for wall sit duration (optional for static hold)
      else if (!isExercising.wallSit || kneeToAnkleDistance < 50) {
        setCount(count + 1);
        setFeedback("Wall Sit duration increased!");
        isExercising.wallSit = false;
      }
    }
  };

  // ARM CIRCLE tracker
  const detectArmCircle = (pose) => {
    // Keypoints for tracking arm rotation movements
    const leftWrist = pose.keypoints.find(
      (point) => point.part === "leftWrist"
    );
    const leftElbow = pose.keypoints.find(
      (point) => point.part === "leftElbow"
    );
    const rightWrist = pose.keypoints.find(
      (point) => point.part === "rightWrist"
    );
    const rightElbow = pose.keypoints.find(
      (point) => point.part === "rightElbow"
    );

    if (leftWrist && leftElbow && rightWrist && rightElbow) {
      const leftWristToElbow = Math.hypot(
        leftWrist.position.x - leftElbow.position.x,
        leftWrist.position.y - leftElbow.position.y
      );
      const rightWristToElbow = Math.hypot(
        rightWrist.position.x - rightElbow.position.x,
        rightWrist.position.y - rightElbow.position.y
      );

      // Detecting large, circular arm movements
      if (
        leftWristToElbow > 50 &&
        rightWristToElbow > 50 &&
        !isExercising.armCircle
      ) {
        setFeedback("Starting Arm Circles...");
        isExercising.armCircle = true;
      } else if (
        isExercising.armCircle &&
        leftWristToElbow < 30 &&
        rightWristToElbow < 30
      ) {
        setCount(count + 1);
        setFeedback("Arm Circle complete!");
        isExercising.armCircle = false;
      }
    }
  };

  // BEAR CRAWL tracker
  const detectBearCrawl = (pose) => {
    // Keypoints for tracking hand and knee movement for bear crawl
    const leftWrist = pose.keypoints.find(
      (point) => point.part === "leftWrist"
    );
    const leftKnee = pose.keypoints.find((point) => point.part === "leftKnee");
    const rightWrist = pose.keypoints.find(
      (point) => point.part === "rightWrist"
    );
    const rightKnee = pose.keypoints.find(
      (point) => point.part === "rightKnee"
    );

    if (leftWrist && leftKnee && rightWrist && rightKnee) {
      const leftHandToKneeDistance = Math.abs(
        leftWrist.position.y - leftKnee.position.y
      );
      const rightHandToKneeDistance = Math.abs(
        rightWrist.position.y - rightKnee.position.y
      );

      // Detects forward movement of arms and knees in a crawling position
      if (
        leftHandToKneeDistance < 50 &&
        rightHandToKneeDistance < 50 &&
        !isExercising.bearCrawl
      ) {
        setFeedback("Bear Crawl forward...");
        isExercising.bearCrawl = true;
      } else if (
        isExercising.bearCrawl &&
        (leftHandToKneeDistance > 60 || rightHandToKneeDistance > 60)
      ) {
        setCount(count + 1);
        setFeedback("Bear Crawl step complete!");
        isExercising.bearCrawl = false;
      }
    }
  };

  // PULL-UP tracker
  const detectPullUp = (pose) => {
    // Keypoints for tracking vertical movement of shoulders for pull-up
    const leftShoulder = pose.keypoints.find(
      (point) => point.part === "leftShoulder"
    );
    const rightShoulder = pose.keypoints.find(
      (point) => point.part === "rightShoulder"
    );
    const leftElbow = pose.keypoints.find(
      (point) => point.part === "leftElbow"
    );
    const rightElbow = pose.keypoints.find(
      (point) => point.part === "rightElbow"
    );

    if (leftShoulder && rightShoulder && leftElbow && rightElbow) {
      const shoulderToElbowDistance = Math.hypot(
        leftShoulder.position.y - leftElbow.position.y,
        rightShoulder.position.y - rightElbow.position.y
      );

      // Detect pulling up position (shoulders closer to elbows)
      if (shoulderToElbowDistance < 40 && !isExercising.pullUp) {
        setFeedback("Pulling up...");
        isExercising.pullUp = true;
      } else if (isExercising.pullUp && shoulderToElbowDistance > 60) {
        setCount(count + 1);
        setFeedback("Pull-up complete!");
        isExercising.pullUp = false;
      }
    }
  };

  const trackExercise = (pose) => {
    switch (exercise) {
      case "Pushups":
        detectPushUp(pose);
        break;
      case "Squats":
        detectSquat(pose);
        break;
      case "Lunge":
        detectLunge(pose);
        break;
      case "Mountain Climbers":
        detectMountainClimber(pose);
        break;
      case "Tricep dips":
        detectTricepsDip(pose);
        break;
      case "Burpee":
        detectBurpee(pose);
        break;
      case "Calf raises":
        detectCalfRaise(pose);
        break;
      case "Knee push-ups":
        detectKneePushUp(pose);
        break;
      case "Reverse Lunges":
        detectReverseLunge(pose);
        break;

      case "Crunch":
        detectCrunch(pose);
        break;

      case "Glute Bridge":
        detectGluteBridge(pose);
        break;

      case "Jumping jack":
        detectJumpingJack(pose);
        break;

      case "Leg raises":
        detectLegRaise(pose);
        break;

      case "Step-up":
        detectStepUp(pose);
        break;

      case "Bodyweight Squat":
        detectBodyweightSquat(pose);
        break;

      case "Bicycle crunches":
        detectBicycleCrunch(pose);
        break;

      case "Wall Sit":
        detectWallSit(pose);
        break;

      case "Arm Circles":
        detectArmCircle(pose);
        break;

      case "Bear Crawl":
        detectBearCrawl(pose);
        break;

      case "Pull-ups":
        detectPullUp(pose);
        break;

      default:
        setFeedback("");
        break;
    }
  };

  // const handleStartStop = () => {
  //   setIsTracking(!isTracking);
  //   if (!isTracking) {
  //     setCount(0);
  //     setFeedback("");
  //   }
  // };

  const instruction = [
    { name: "Pushups", image: PushUpExercise },
    { name: "Squats", image: squatExercise },
    {
      name: "Reverse Lunges",
      image: lungeExercise,
    },
    { name: "Lunge", image: lungeExercise },
    { name: "Tricep dips", image: tricepExercise },
    {
      name: "Mountain Climbers",

      image: mountainExercise,
    },
    { name: "Burpee", image: burpeeExercise },
    { name: "Calf raises", images: calfExercise },
    { name: "Knee push-ups", image: kneePushExercise },
    { name: "Crunch", image: crunchExercise },
    { name: "Glute bridge", image: gluteExercise },
    { name: "Jumping jack", image: jumpingExercise },
    { name: "Leg raises", image: legExercise },
    { name: "Step-up", image: stepExercise },
    { name: "Bodyweight Squat", image: squatExercise2 },
    {
      name: "Bicycle crunches",

      image: bicycleExercise,
    },
    { name: "Wall sit", image: wallExercise },
    { name: "Arm Circles", image: armExercise },
    { name: "Bear Crawl", image: bearExercise },
    { name: "Pull-ups", image: pullExercise },
  ];

  const [steps, setSteps] = useState(1);
  const [isPaused, setIsPaused] = useState(false);

  const handleStart = () => {
    const loadModel = async () => {
      const loadedNet = await posenet.load();
      setNet(loadedNet);
    };
    loadModel();
    setSteps(4);
    setIsTracking(!isTracking);
    if (!isTracking) {
      setCount(0);
      setFeedback("");
    }
  };

  const pauseTracking = () => {
    setIsPaused(true);
    videoRef.current.pause();
  };

  const resumeTracking = () => {
    setIsPaused(false);
    videoRef.current.play();
  };

  const stopTracking = () => {
    setIsTracking(false);
    setIsPaused(false);
    const existingData = JSON.parse(localStorage.getItem("exerciseData")) || [];

    const currentExercise = {
      [exercise]: count,
    };
    const updatedData = [...existingData, currentExercise];

    localStorage.setItem("exerciseData", JSON.stringify(updatedData));
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      navigate("/chat?type=question&query=dn");
    } else {
      console.warn("No active stream to stop");
    }
  };

  const [skipSteps, setSkipSteps] = useState(false);

  useEffect(() => {
    const skip = JSON.parse(localStorage.getItem("skipSteps")) || false;
    setSkipSteps(skip);
    if (skip) {
      setSteps(3);
    }
  }, []);

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setSkipSteps(isChecked);
    localStorage.setItem("skipSteps", JSON.stringify(isChecked));
  };

  return (
    <Fragment>
      <section className="exercise_traker">
        <div className="container">
          {steps !== 4 && (
            <div className="instruction">
              <h2>Instruction</h2>
              {steps === 1 && (
                <div className="step">
                  <img src={exerciseStep1} alt="exerciseStep1" />
                </div>
              )}
              {steps === 2 && (
                <div className="step">
                  <img src={exerciseStep2} alt="exerciseStep2" />
                </div>
              )}
              {steps === 3 && (
                <div className="step">
                  {instruction
                    ?.filter((itm) => itm?.name === exercise)
                    ?.map((ele, id) => (
                      <img key={id} src={ele?.image} alt="exerciseStep2" />
                    ))}
                </div>
              )}
            </div>
          )}

          {steps === 4 && (
            <div className="camera_feed">
              <div className="start_exercise">
                <h2>
                  {exercise} Count: {count}
                </h2>
                <p>{feedback}</p>
              </div>
              <div className="screen">
                <video ref={videoRef} />
                <canvas ref={canvasRef} />
              </div>
            </div>
          )}

          <div className="step_btns">
            {steps !== 4  && !skipSteps &&  (
              <div className="skip_steps">
                <label>
                  <input
                    type="checkbox"
                    checked={skipSteps}
                    onChange={handleCheckboxChange}
                  />
                  Skip Instructions Next Time
                </label>
              </div>
            )}
            <div className="controls">
              {steps !== 1 && !skipSteps && (
                <CustomButton
                  title="Previous"
                  width="110px"
                  className="btn"
                  onClick={() => setSteps((prev) => prev - 1)}
                />
              )}
              {(steps === 1 || steps === 2) && !skipSteps && (
                <CustomButton
                  title="Next"
                  width="110px"
                  className="btn"
                  onClick={() => setSteps((prev) => prev + 1)}
                />
              )}
              {steps === 3 && (
                <CustomButton
                  title="Start"
                  width="110px"
                  className="btn"
                  onClick={handleStart}
                />
              )}

              {steps === 4 && (
                <>
                  {isPaused ? (
                    <CustomButton
                      onClick={resumeTracking}
                      width="110px"
                      className="btn"
                      title="Resume"
                    />
                  ) : (
                    <CustomButton
                      onClick={pauseTracking}
                      width="110px"
                      className="btn"
                      title="Pause"
                    />
                  )}
                  <CustomButton
                    onClick={stopTracking}
                    width="110px"
                    className="btn"
                    title="Stop"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ExerciseTracker;
