import React, { Fragment, useState } from "react";
import "../styles/home.scss";
import GptLayout from "../component/ssbGpt/gptLayout";
import CustomHelmet from "../component/reusable/Helmet";
import {
  armyDoctor,
  cpssTrainer,
  home2,
  homeBanner,
  physicalTrainer,
  review1,
  review2,
  review3,
  review4,
  review5,
  review6,
  spokenEnglishTrainer,
} from "../assets/images";
import { GiNothingToSay } from "react-icons/gi";
import { BiLogoPlayStore } from "react-icons/bi";
import { PiStarFourThin } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/userContext";
import { FaUser } from "react-icons/fa";
import { HiChatBubbleOvalLeftEllipsis } from "react-icons/hi2";

const Home = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();
  const handleNavigate = () => {
    if (auth?.user?._id) {
      navigate("/chat");
    } else {
      navigate("/login", { state: "/chat" });
    }
  };
  const [planType, setPlanType] = useState("Monthly");

  const testimonials = [
    {
      name: "Aayush Raghuvanshi",
      degree: "B.Com",
      stars: 5,
      image: review1,
      feedback:
        "SSB-GPT was a game-changer in my SSB preparation. The AI mentor provided real-time feedback on my OLQs, helping me improve where it mattered. It felt like having an ex-SSB officer guiding me through the process. I went into my interview confident and well-prepared, all thanks to SSB-GPT!",
    },
    {
      name: "Rae Cooper",
      degree: "B.A",
      stars: 5,
      image: review2,

      feedback:
        "The AI-driven feedback was spot-on! SSB-GPT made my preparation so much easier and more effective.",
    },
    {
      name: "Rohit Solanki",
      degree: "B.Tech",
      stars: 5,
      image: review3,
      feedback:
        "I felt well-prepared for every stage of the SSB process, thanks to the guidance from SSB-GPT.",
    },
    {
      name: "Rishabh Sethi",
      degree: "LLB",
      stars: 5,
      image: review4,
      feedback:
        "The personalized mock interviews and instant feedback boosted my confidence. SSB-GPT is a game-changer!",
    },
    {
      name: "Anurag Bheleswale",
      degree: "Software Engineer",
      stars: 5,
      image: review5,
      feedback:
        "SSB-GPT’s psychometric analysis gave me a deep understanding of my OLQs and where I needed to improve.",
    },
    {
      name: "Rohit Nagarkoti",
      degree: "IT, Data Analyst",
      stars: 5,
      image: review6,
      feedback:
        "The real-time coaching and practice tests made all the difference. I highly recommend SSB-GPT!",
    },
  ];

  const faqData = [
    {
      question: "What is SSBGPT?",
      answer:
        "SSBGPT is an AI-powered platform designed to help defense aspirants prepare for the SSB interview. It offers personalized mock interviews, psychology tests, GTO simulation, group discussions, psychometric analysis of Officer Like Qualities (OLQs), spoken English practice, note-saving capabilities, and even physical coaching, all in a single AI-based environment.",
    },
    {
      question: "Can I attend unlimited mock SSB interviews with SSBGPT?",
      answer:
        "Yes! In the Paid plan, SSBGPT allows you to attend unlimited mock personal interviews, simulating real SSB interviews with AI-based feedback to help you improve.",
    },
    {
      question: "What types of psychology tests are available on SSBGPT?",
      answer:
        "SSBGPT provides tests like the Thematic Apperception Test (TAT), Word Association Test (WAT), Situation Reaction Test (SRT), and Self-Description Test (SD), all designed to assess your psychological preparedness for SSB.",
    },
    {
      question: "How does the GTO Simulation work?",
      answer:
        "SSBGPT includes an interactive GTO Simulation feature where you can practice tasks like group planning, outdoor tasks, and progressive group exercises in a virtual environment to enhance your group dynamics and leadership qualities.",
    },
    {
      question: "Can I participate in group discussions on SSBGPT?",
      answer:
        "Yes, the platform allows you to practice group discussions with AI-generated topics or connect with other aspirants to simulate the Group Discussion task, helping you improve your communication and teamwork skills.",
    },
    {
      question: "What is the psychometric test for analyzing OLQs?",
      answer:
        "The psychometric test is designed to evaluate your Officer Like Qualities (OLQs). It analyzes key traits such as leadership, decision-making, and problem-solving abilities to provide insights on areas that need improvement.",
    },
    {
      question: "How can I improve my spoken English on SSBGPT?",
      answer:
        "SSBGPT offers spoken English practice sessions where you can engage in conversations and receive feedback on your fluency, pronunciation, and vocabulary, helping you speak confidently during the SSB interview.",
    },
    {
      question: "Can I save notes on SSBGPT?",
      answer:
        "Yes, you can save notes, record observations, and keep track of your progress on the platform, ensuring you stay organized throughout your preparation journey.",
    },
    {
      question: "What kind of physical coaching does SSBGPT provide?",
      answer:
        "SSBGPT includes AI-based physical coaching to guide you in maintaining fitness levels required for SSB. It provides exercise routines, posture corrections, and fitness tips to ensure you're physically prepared for the tasks.",
    },
    {
      question: "How does SSBGPT personalize the preparation experience?",
      answer:
        "SSBGPT adapts to your performance, providing feedback and recommendations tailored to your individual strengths and weaknesses, ensuring a focused and efficient preparation for each aspect of the SSB process.",
    },
    {
      question:
        "Is there any limit to the number of tests or simulations I can take?",
      answer:
        "No, SSBGPT offers unlimited access to all its features, including interviews, psychology tests, GTO simulations, and more, so you can practice as much as you need.",
    },
    {
      question: "How does the AI analyze my performance?",
      answer:
        "The AI evaluates your responses, body language (in interviews), psychometric test results, and performance in tasks and discussions to give you comprehensive feedback on your Officer Like Qualities and overall preparedness.",
    },
  ];

  const aiMentorProfiles = [
    {
      name: "Captain Alpha",
      desc: "AI SSB Mentor & Expert",
      profileButtonLink: "/profile/captain-alpha",
      chatButtonLink: "question",
      profilePic: homeBanner,
      features: [
        "SSB Process Expert.",
        "Trained as an IO, GTO, and Psychologist.",
        "Expert in diplomacy and international relations.",
        "Trained Personality Development Coach.",
      ],
    },
    {
      name: "Ravinder Yadav",
      desc: "AI Medical Expert",
      profileButtonLink: "/profile/medical-expert",
      chatButtonLink: "medical",
      profilePic: armyDoctor,
      features: [
        "Expert in military medical procedures.",
        "Trained on Medical Board data.",
        "AI-Enhanced Predictive Analysis.",
        "24/7 Virtual Medical Guidance.",
      ],
    },
    {
      name: "Mark",
      desc: "AI Spoken English Trainer",
      profileButtonLink: "/profile/english-trainer",
      chatButtonLink: "/spoken-english",
      profilePic: spokenEnglishTrainer,
      features: [
        "Language Assessments Expert.",
        "Responsive to Emotional Cues.",
        "Engaging Conversationalist.",
        "Empathetic Listener.",
      ],
    },
    {
      name: "Vikram Kumar",
      desc: "AI Fitness Trainer",
      profileButtonLink: "/profile/fitness-coach",
      chatButtonLink: "physical",
      profilePic: physicalTrainer,
      features: [
        "Expert in Creating Customized Workout Plans.",
        "Gives Real-Time Performance Feedback.",
        "Nutrition Guide.",
        "Injury Prevention and Recovery Expert.",
      ],
    },
    {
      name: "Sanjeev Kumar",
      desc: "AI CPSS Expert",
      profileButtonLink: "/profile/cpss-expert",
      chatButtonLink: "CPSS",
      profilePic: cpssTrainer,
      features: [
        "Expert in Cognitive Ability Test",
        "Expert in Psychomotor Test",
        "Trained on PABT/CPSS Data",
      ],
    },
  ];

  const handleRedirectToChat = (query) => {
    if (query === "/spoken-english") {
      navigate(query);
    } else if (auth?.user?._id) {
      navigate(`/chat?type=${query}`);
    } else {
      navigate("/login", { state: `/chat?type=${query}` });
    }
  };

  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
          <section className="home_page">
            <section className="banner_section">
              <div className="left">
                <span>Looking for your own personal SSB Mentor?</span>
                <h2>
                  Prepare for your SSB <br /> with an{" "}
                  <strong>AI SSB Mentor!</strong>
                </h2>
                <p>
                  Unlock success with SSB-GPT: Master Psychology, Interviews,
                  GTO, Fitness, and Spoken English. Get personalised insights
                  with AI SSB Mentor and OLQ Analysis for your recommendation.
                </p>
                <p>
                  Begin with a <strong>FREE</strong>
                  <span> Psychometric Test for OLQs Analysis!</span>
                </p>
                <div className="btn_wrap">
                  <button className="go_btn" onClick={() => handleNavigate()}>
                    <GiNothingToSay /> Ask your AI SSB Mentor Now
                  </button>
                  <button className="app_btn">
                    <BiLogoPlayStore /> Download on Google Play
                  </button>
                </div>
              </div>
              <div className="right">
                <img src={homeBanner} alt="home-banner" />
              </div>
            </section>

            <section className="mentor_section">
              <div className="title">
                <h2>
                  SSB-GPT: AI SSB MENTOR,{" "}
                  <strong>AS EFFECTIVE AS A REAL EX-SSB MEMBER!</strong>
                </h2>
                <p>
                  Unlock the power of SSB-GPT! Get instant answers with our AI
                  SSB Mentor, perfect for those searching for 'SSB GPT,' 'SSB
                  Interview Preparation,' or 'SSB Coaching near me.
                </p>
              </div>
              <img src={home2} alt="home2" />
            </section>

            <section className="ai_ssb">
              <div className="title">
                <h2>AI SSB Mentors</h2>
                <p>Meet Our AI SSB Mentors</p>
              </div>
              {aiMentorProfiles?.map((item, itemIndex) => (
                <div className="profile" key={itemIndex}>
                  <div className="left">
                    <img src={item.profilePic} alt="home_banner" />
                  </div>

                  <div className="right">
                    <div className="title">
                      <h4>
                        {/* Sun<strong>ita</strong> */}
                        {item.name}
                      </h4>
                      <p>{item.desc}</p>
                      <div className="btnGrp">
                        <button
                          onClick={() => navigate(item?.profileButtonLink)}
                        >
                          <FaUser /> Profile
                        </button>
                        <button
                          onClick={() =>
                            handleRedirectToChat(item?.chatButtonLink)
                          }
                        >
                          <HiChatBubbleOvalLeftEllipsis />{" "}
                          {item.name === "Mark" ? "Talk" : "Chat"}
                        </button>
                      </div>
                    </div>
                    <ul>
                      {item.features.map((subItem, subItemIndex) => (
                        <li key={subItemIndex}>
                          <p>
                            <PiStarFourThin />
                          </p>
                          <p>{subItem}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </section>

            <section className="mission_rdy">
              <div className="title">
                <h2>Mission-ready pricing</h2>
                <p>
                  Tailored for Every Aspirant! Choose the plan that fits your
                  journey, and march forward with confidence under the guidance
                  of SSB-GPT, your AI mentor in SSB <br /> preparation. Prepare
                  like a soldier, succeed like an officer!
                </p>
              </div>
              <div className="switch_card_plan">
                <div className="plan-toggle">
                  <button
                    className={planType === "Monthly" ? "active" : ""}
                    onClick={() => setPlanType("Monthly")}
                  >
                    Monthly
                  </button>
                  <button
                    className={planType === "Yearly" ? "active" : ""}
                    onClick={() => setPlanType("Yearly")}
                  >
                    Yearly
                  </button>
                </div>

                <div className="plan-cards custonScroll">
                  <div className="plan-card">
                    <h3>Platoon Plan</h3>
                    <h2>Free</h2>
                    <ul>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>1 OIR test/month</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>1 Short Mock Psychology</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>1 Short Mock Interview</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Limited Access to GTO</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Spoken English 10 min/daily</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Limited Group Discussion</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Unlimited Question Ask</span>
                      </li>
                    </ul>
                    <div className="see_btn">
                      <button>Get Started</button>
                    </div>
                  </div>

                  <div className="plan-card">
                    <h3>Regiment Plan</h3>
                    {planType === "Monthly" ? (
                      <h2>₹249/month</h2>
                    ) : (
                      <h2>₹2490/year</h2>
                    )}
                    <ul>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Unlimited OIR Test</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Unlimited Mock Psychology</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Unlimited Group Discussion</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Unlimited GTO Simulation</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Unlimited Mock Interview</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Spoken English Practice</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span className="green">
                          10% Discount on all Alpha Regiment Courses
                        </span>
                      </li>
                    </ul>
                    <div className="see_btn">
                      <button>Get Started</button>
                    </div>
                  </div>

                  <div className="plan-card">
                    <h3>Brigade Plan</h3>
                    <h2>₹**</h2>
                    <p>**Talk to Sales for Pricing</p>
                    <ul>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Ideal for Schools</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Under the school's branding</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Train with your data</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Can add multiple Admin</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Unlimited Psychometric test</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Unlimited Mock Tests</span>
                      </li>
                      <li>
                        <span className="checkmark">✓</span>
                        <span>Teacher's Dashboard</span>
                      </li>
                    </ul>
                    <div className="see_btn">
                      <button>Talk to Sales</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="user_testimonials">
              <div className="title">
                <h2>User Words</h2>
                <p>
                  Here are some delightful words from our satisfied users. The
                  very <br />
                  essence that fuels our passion! 😊
                </p>
              </div>
              <div className="testimonial_card_plan">
                {testimonials.map((testimonial, index) => (
                  <div key={index} className="testimonial-card">
                    <div className="head">
                      <img src={testimonial?.image} alt="yser-ig"></img>
                      <div>
                        <h3>{testimonial.name}</h3>
                        <p>{testimonial.degree}</p>
                      </div>
                    </div>
                    <div className="stars">{"★".repeat(testimonial.stars)}</div>
                    <p>{testimonial.feedback}</p>
                  </div>
                ))}
              </div>
            </section>

            <section className="faqs">
              <div className="title">
                <h2>FAQs</h2>
                <p>Get answers to most asked questions about SSB-GPT</p>
              </div>
              <div className="faq_list">
                {faqData.map((item, index) => (
                  <div key={index} className="faq-item">
                    <h3 className="faq-question">{item.question}</h3>
                    <p className="faq-answer">{item.answer}</p>
                  </div>
                ))}
              </div>
            </section>
          </section>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default Home;
