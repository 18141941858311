import React from "react";
import "../../styles/otherGPT.scss";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

import inetGPT from "../../images/inetGPT.png";
import afcatGPT from "../../images/afcatGPT.png";
import cdsGPT from "../../images/cdsGPT.png";
import ndaGPT from "../../images/ndaGPT.png";

const OtherGPT = () => {
  const gptsContent = [
    {
      cardImg: ndaGPT,
      title: "NDA-GPT",
      description:
        "Your AI-powered guide to mastering the NDA, with expert insights and strategies to help you succeed.",
    },
    {
      cardImg: cdsGPT,
      title: "CDS-GPT",
      description:
        "Your AI-driven companion for conquering the CDS exam, delivering strategic insights and personalized prep for success.",
    },
    {
      cardImg: afcatGPT,
      title: "AFCAT-GPT",
      description:
        "Your AI-powered coach for excelling in the AFCAT exam, offering targeted strategies and expert guidance for your Air Force journey.",
    },
    {
      cardImg: inetGPT,
      title: "INET-GPT",
      description:
        "Your AI mentor for the Indian Navy Entrance Test, providing expert insights and tailored strategies for a successful naval career.",
    },
  ];

  return (
    <div className="other_gpts">
      <div className="heading">Our other GPTs</div>
      <div className="gptCards">
        {gptsContent?.map((item, itemIndex) => (
          <div className="card" key={itemIndex}>
            <img src={item.cardImg} alt="card" />
            <h2 className="gptName">{item.title}</h2>
            <p className="description">{item.description}</p>
            <Link>
              Coming Soon <FiArrowUpRight />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherGPT;
