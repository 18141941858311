import React, { Fragment, useEffect, useState } from "react";
import "../styles/studentProfile.scss";
import { FaExpeditedssl, FaUserEdit, FaUpload } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { useAuth } from "../context/userContext";
import { defaultUser } from "../assets/images";
import StudentLayout from "../component/reusable/ProfileLayout.js";
import CustomInput from "../component/reusable/CustomInput";
import UploadProfilePicForm from "../component/reusable/UploadProfilePicForm.js";
import Loader from "../component/reusable/Loader.js";
import GptLayout from "../component/ssbGpt/gptLayout.js";
import { FaMapLocationDot } from "react-icons/fa6";
import CustomButton from "../component/reusable/CustomButton.js";
import { ImLink } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  attachParentAction,
  clearErrors,
  clearState,
} from "../redux/actions/attachParentAction.js";
import ButtonLoader from "../component/reusable/ButtonLoader.js";
import { toast } from "react-toastify";
import {
  clearUpdateState,
  updateUserProfile,
  userProfile,
  clearErrors as clearProfileErrors,
} from "../redux/actions/userAction.js";
import { studentSidebarLinks } from "../profileSidebarLinks.js";

const StudentProfile = () => {
  const dispatch = useDispatch();
  const [uploadProfileModal, setUploadProfileModal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editParent, setEditParent] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("");
  const [auth] = useAuth();

  const [profileData, setProfileData] = useState({
    fatherName: auth?.user?.fatherName,
    motherName: auth?.user?.motherName,
    parentEmail: auth?.user?.parentEmail,
    motherEmail: auth?.user?.motherEmail,

    first_name: auth?.user?.first_name,
    last_name: auth?.user?.last_name,
    phone: auth?.user?.phone,
    email: auth?.user?.email,
    address: auth?.user?.address,
    dob: auth?.user?.dob,
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const handleCurrentLocation = () => {
    alert("location getting");
  };

  // UPDATE/ATTACH PARENT START
  const {
    loading: attachParentLoading,
    data: attachParentData,
    error: attachParentErr,
  } = useSelector((state) => state.attachParent);

  console.log("attachParentData", attachParentData);

  const handleAttachParent = (param) => {
    setLoadingBtn(param);
    dispatch(
      attachParentAction(
        profileData.fatherName,
        profileData.motherName,
        profileData.parentEmail,
        profileData.motherEmail
      )
    );
  };

  useEffect(() => {
    if (attachParentData?.success) {
      toast.success(attachParentData?.message);
      dispatch(clearState());
      dispatch(userProfile());
      setLoadingBtn("");
      setEditParent(false);
    }
    if (!attachParentErr) {
      toast.success(attachParentErr);
      dispatch(clearErrors());
      setLoadingBtn("");
    }
  }, [
    attachParentData?.message,
    attachParentData?.success,
    dispatch,
    attachParentErr,
  ]);
  // UPDATE/ATTACH PARENT ENDS

  // UPDATE USER PROFILE START
  const {
    loading: updateProfileLoading,
    data: updateProfileData,
    error: updateProfileErr,
  } = useSelector((state) => state.updateProfile);

  const handleUpdateProfile = () => {
    dispatch(
      updateUserProfile(
        profileData?.first_name,
        profileData?.last_name,
        profileData?.email,
        profileData?.phone,
        profileData?.dob,
        profileData?.address
      )
    );
  };

  useEffect(() => {
    if (updateProfileData?.success) {
      toast.success(updateProfileData?.message);
      dispatch(clearUpdateState());
      dispatch(userProfile());
      setEditable(false);
    }
    if (!updateProfileErr) {
      toast.success(updateProfileErr);
      dispatch(clearProfileErrors());
    }
  }, [
    updateProfileData?.message,
    updateProfileData?.success,
    dispatch,
    updateProfileErr,
  ]);
  // UPDATE USER PROFILE ENDS

  return (
    <Fragment>
      {updateProfileLoading ? (
        <Loader />
      ) : (
        <GptLayout>
          <StudentLayout sidebarLinks={studentSidebarLinks}>
            <section className="student_profile_section txt_color">
              <h2 className="profilePage_heading">Account Info</h2>

              <div className="user_details_main">
                <div className="user_pro_data">
                  <h2>
                    Profile{" "}
                    <button
                      className={editable ? "activeBtn" : ""}
                      onClick={() => setEditable(!editable)}
                    >
                      {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </h2>
                  <div>
                    <div className="details_sec">
                      <div className="data_card">
                        <CustomInput
                          label="First Name"
                          type="text"
                          placeholder="First Name"
                          impStar={editable ? "*" : ""}
                          name="first_name"
                          value={
                            editable
                              ? profileData?.first_name
                              : auth.user?.first_name
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Last Name"
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          value={
                            editable
                              ? profileData?.last_name
                              : auth.user?.last_name
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Whatsapp Number"
                          type="number"
                          placeholder="0987654321"
                          impStar={editable ? "*" : ""}
                          name="phone"
                          value={
                            editable ? profileData?.phone : auth.user?.phone
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Email"
                          type="email"
                          placeholder="example@email.com"
                          impStar={editable ? "*" : ""}
                          name="email"
                          value={
                            editable ? profileData?.email : auth.user?.email
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Address"
                          type="text"
                          placeholder="Ex: MG Road, Gurugram"
                          impStar={editable ? "*" : ""}
                          name="address"
                          value={
                            editable ? profileData?.address : auth.user?.address
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                          locationIcon={<FaMapLocationDot />}
                          handleLocation={handleCurrentLocation}
                        />
                        <CustomInput
                          label="Date Of Birth"
                          type="date"
                          placeholder="26-11-1998"
                          impStar={editable ? "*" : ""}
                          name="dob"
                          value={editable ? profileData?.dob : auth.user?.dob}
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />

                        {editable && (
                          <CustomButton
                            className="pro_update_btn"
                            title={
                              updateProfileLoading ? <ButtonLoader /> : `Update`
                            }
                            disabled={updateProfileLoading}
                            width="100px"
                            onClick={handleUpdateProfile}
                          />
                        )}
                      </div>
                      <div className="card_heading">
                        <h2>Parents</h2>
                        <button
                          className={editParent ? "activeBtn" : ""}
                          onClick={() => setEditParent(!editParent)}
                        >
                          {editParent ? <FaExpeditedssl /> : <FaUserEdit />}{" "}
                          Edit
                        </button>
                      </div>
                      <div className="data_card">
                        <CustomInput
                          label="Father Name"
                          type="text"
                          placeholder="Ex: Naresh Kumar"
                          name="fatherName"
                          value={
                            editParent
                              ? profileData?.fatherName
                              : auth.user?.fatherName
                          }
                          readOnly={editParent ? false : true}
                          onChange={handleInputChange}
                          borderColor={editParent ? "#f2f2f2" : ""}
                        />
                        {/* fatherName, motherName, parentEmail, motherEmail */}
                        <div className="inputWithButton">
                          <CustomInput
                            label="Father's Email"
                            type="email"
                            placeholder="Ex: example@email.com"
                            name="parentEmail"
                            value={
                              editParent
                                ? profileData?.parentEmail
                                : auth.user?.parentEmail
                            }
                            readOnly={editParent ? false : true}
                            onChange={handleInputChange}
                            borderColor={editParent ? "#f2f2f2" : ""}
                          />
                          {editParent && (
                            <button onClick={() => handleAttachParent(1)}>
                              {attachParentLoading && loadingBtn === 1 ? (
                                <ButtonLoader />
                              ) : (
                                <>
                                  <ImLink /> Connect
                                </>
                              )}
                            </button>
                          )}
                        </div>
                        <hr
                          style={{
                            width: "100%",
                            marginBottom: "20px",
                            borderStyle: "dashed",
                            borderColor: "#f2f2f230",
                          }}
                        />
                        <CustomInput
                          label="Mother Name"
                          type="text"
                          placeholder="Ex: Sushma Devi"
                          name="motherName"
                          value={
                            editParent
                              ? profileData?.motherName
                              : auth.user?.motherName
                          }
                          readOnly={editParent ? false : true}
                          onChange={handleInputChange}
                          borderColor={editParent ? "#f2f2f2" : ""}
                        />
                        <div className="inputWithButton">
                          <CustomInput
                            label="Mother's Email"
                            type="email"
                            placeholder="Ex: example@email.com"
                            name="motherEmail"
                            value={
                              editParent
                                ? profileData?.motherEmail
                                : auth.user?.motherEmail
                            }
                            readOnly={editParent ? false : true}
                            onChange={handleInputChange}
                            borderColor={editParent ? "#f2f2f2" : ""}
                          />
                          {editParent && (
                            <button onClick={() => handleAttachParent(2)}>
                              {attachParentLoading && loadingBtn === 2 ? (
                                <ButtonLoader />
                              ) : (
                                <>
                                  <ImLink /> Connect
                                </>
                              )}
                            </button>
                          )}
                        </div>

                        {/* {editParent && (
                          <CustomButton
                            className="pro_update_btn"
                            title={`Update`}
                            width="100px"
                          />
                        )} */}
                      </div>
                    </div>
                    <div className="profile_picSec">
                      <div className="user_pro_pic">
                        {editable && (
                          <button
                            className="absolute_btn deleteImage_btn"
                            title="Remove Profile Picture"
                          >
                            <RiDeleteBinLine />
                          </button>
                        )}

                        <img
                          src={
                            auth?.user?.profile?.url
                              ? auth?.user?.profile?.url
                              : defaultUser
                          }
                          alt="alpha regiment user"
                        />
                        {editable && (
                          <button
                            title="Upload Profile Picture"
                            className="absolute_btn uploadImage_btn"
                            onClick={() => setUploadProfileModal(true)}
                          >
                            <FaUpload />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* UPLOAD PROFILE PICTURE FORM START */}
            {uploadProfileModal && (
              <section className="upload_profile_pic_main">
                <UploadProfilePicForm
                  setUploadProfileModal={setUploadProfileModal}
                />
              </section>
            )}

            {/* UPLOAD PROFILE PICTURE FORM ENDS */}
          </StudentLayout>
        </GptLayout>
      )}
    </Fragment>
  );
};

export default StudentProfile;
