import React, { Fragment } from "react";
import "../../styles/aboutUs.scss";
import CustomHelmet from "../reusable/Helmet";
import GptLayout from "./gptLayout";
import {
  captainAlphaModal,
  cpssModal,
  gpt_mobilelogo,
  greenGradient,
  markModal,
  medicalModal,
  physicalModal,
  redGradient,
} from "../../assets/images";
import sehgal_sir_award from "../../images/sehgal_sir_award.jpg";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import OtherGPT from "../reusable/OtherGPT";

const AboutUs = () => {
  const missionCards = [
    {
      cardImg: cpssModal,
      title: "AI CPSS Expert",
      description:
        "Your AI CPSS Expert is here to guide you through every step, ensuring you clear the CPSS with confidence and precision.",
      exploreLink: "/profile/cpss-expert",
    },
    {
      cardImg: physicalModal,
      title: "AI Fitness Trainer",
      description:
        "Your dedicated ally in achieving peak physical performance and overall well-being.",
      exploreLink: "/profile/fitness-coach",
    },
    {
      cardImg: captainAlphaModal,
      title: "AI SSB Mentor",
      description:
        "Your tactical edge for excelling in Service Selection Board assessments",
      exploreLink: "/profile/captain-alpha",
    },
    {
      cardImg: markModal,
      title: "AI English Trainer",
      description:
        "Your personal guide to mastering English with precision and confidence.",
      exploreLink: "/profile/english-trainer",
    },
    {
      cardImg: medicalModal,
      title: "AI Military Doctor",
      description:
        "Your trusted ally for navigating medical evaluations and securing your place in the armed forces. ",
      exploreLink: "/profile/medical-expert",
    },
  ];
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
          <div className="about_us_main txt_color">
            <h1 className="hero_heading">
              <span className="highlight">SSB-GPT</span> is an 🤖 AI-powered
              platform designed to help candidates prepare for the SSB with{" "}
              <span className="greenish">personalized guidance</span>,{" "}
              <span className="highlight">interactive simulations</span>, and{" "}
              <span className="greenish">expert insights</span>.
            </h1>

            <div className="about_ai_section">
              <div className="container">
                <div className="about_ai_content">
                  <div className="bgImg left">
                    <img src={redGradient} alt="redGradient" />
                  </div>
                  <div className="bgImg center">
                    <img src={greenGradient} alt="greenGradient" />
                  </div>
                  <div className="bgImg right">
                    <img src={redGradient} alt="redGradient" />
                  </div>
                  <div className="main_content">
                    <div className="main_logo">
                      <img src={gpt_mobilelogo} alt="mobile logo" />
                    </div>
                    <h2 className="ai_heading">AI Models</h2>
                    <Link
                      to="/profile/english-trainer"
                      className="ai_card top_left"
                    >
                      <img src={markModal} alt="AI English Trainer" />
                      <div className="ai_modal_name">AI English Trainer</div>
                    </Link>
                    <Link
                      to="/profile/captain-alpha"
                      className="ai_card top_right"
                    >
                      <img src={captainAlphaModal} alt="AI SSB Expert" />
                      <div className="ai_modal_name">AI SSB Expert</div>
                    </Link>
                    <Link
                      to="/profile/fitness-coach"
                      className="ai_card bottom_left"
                    >
                      <img src={physicalModal} alt="AI Fitness Trainer" />
                      <div className="ai_modal_name">AI Fitness Trainer</div>
                    </Link>
                    <Link
                      to="/profile/cpss-expert"
                      className="ai_card bottom_right"
                    >
                      <img src={cpssModal} alt="AI CPSS Expert" />
                      <div className="ai_modal_name">AI CPSS Expert</div>
                    </Link>
                    <Link
                      to="/profile/medical-expert"
                      className="ai_card bottom_center"
                    >
                      <img src={medicalModal} alt="AI Military Doctor" />
                      <div className="ai_modal_name">AI Military Doctor</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="our_Mission_main">
              <h2 className="heading">
                <span>Our Mission</span>
                Most efficient and effective training resources for SSB
                aspirants, using cutting-edge technology to enhance their
                preparation experience.
              </h2>

              <div className="mission_cards">
                {missionCards?.map((item, itemIndex) => (
                  <div className="card" key={itemIndex}>
                    <div className="card_content">
                      <img src={item.cardImg} alt="cpss modal" />
                      <div className="text">
                        <h2>{item.title}</h2>
                        <p className="desc">{item.description}</p>
                        <Link to={item.exploreLink}>
                          Explore Profile <FiArrowUpRight />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="capability_section">
              <div className="left_sec">
                <h2 className="heading">Credibility of SSB-GPT</h2>
                <h2 className="content_heading">
                  Unmatched Credibility Backed by Expert Officers and
                  Specialists
                </h2>
                <p>
                  The credibility of SSB-GPT is built on the expertise of
                  multiple officers who have served as Interviewing Officers
                  (IO), Group Testing Officers (GTO), psychologists, military
                  doctors, English trainers, and fitness trainers. These experts
                  collaborated to ensure the precision and accuracy of all the
                  AI models integrated into the platform. The team’s commitment
                  to delivering a reliable product was further strengthened when
                  a team of officers, led by{" "}
                  <span>Lt Gen Ajai Kumar Sehgal</span>, who has served as SSB
                  President twice, thoroughly reviewed the platform. Once this
                  team was satisfied with the accuracy and performance, we
                  officially rolled out SSB-GPT for aspirants.
                </p>
              </div>
              <div className="right_sec">
                <div className="officer_img">
                  <img src={sehgal_sir_award} alt="sehgal_sir_award" />
                  <div className="img_text">
                    Lt Gen Ajai Kumar Sehgal (SSB-GPT Mentor)
                  </div>
                </div>
              </div>
            </div>

            <OtherGPT />
          </div>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default AboutUs;
