import {
  CLEAR_ERROR_SCHOOL,
  CLEAR_STATE_SCHOOL,
  CREATE_SCHOOL_FAIL,
  CREATE_SCHOOL_REQUEST,
  CREATE_SCHOOL_SUCCESS,
  GET_SCHOOL_FAIL,
  GET_SCHOOL_REQUEST,
  GET_SCHOOL_SUCCESS,
  MYREF_STUDENTS_FAIL,
  MYREF_STUDENTS_REQUEST,
  MYREF_STUDENTS_SUCCESS,
  UPDATE_SCHOOL_FAIL,
  UPDATE_SCHOOL_REQUEST,
  UPDATE_SCHOOL_SUCCESS,
} from "../constants/schoolByTeacherConstant";

// CREATE SCHOOL BY TEACHER REDUCER
export const createSchoolReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SCHOOL_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case CREATE_SCHOOL_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case CREATE_SCHOOL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_STATE_SCHOOL:
      return {
        loading: false,
        data: {},
      };
    case CLEAR_ERROR_SCHOOL:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET SCHOOL BY TEACHER REDUCER
export const getSchoolReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SCHOOL_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case GET_SCHOOL_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_SCHOOL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR_SCHOOL:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE SCHOOL BY TEACHER REDUCER
export const updateSchoolReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SCHOOL_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case UPDATE_SCHOOL_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case UPDATE_SCHOOL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR_SCHOOL:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// MY REFERRED STUDENTS REDUCER
export const myReferredStudentsReducer = (state = {}, action) => {
  switch (action.type) {
    case MYREF_STUDENTS_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case MYREF_STUDENTS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case MYREF_STUDENTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR_SCHOOL:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
