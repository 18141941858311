import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FaUserGraduate } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import CustomHelmet from "../component/reusable/Helmet";
import GptLayout from "../component/ssbGpt/gptLayout";
import { MdFamilyRestroom } from "react-icons/md";

const RegisterPage = () => {
  return (
    <Fragment>
      <GptLayout>
        <CustomHelmet
          keywords="Sign up SSB-GPT"
          description="Join the India's biggest community of veterans and defense aspirants."
          author="Team Alpha Regiment"
          title="SSB-GPT: Register"
          canonical={window?.location?.href}
        />
        <section className="signup_sec_main">
          <div className="container">
            <div className="signUpWrapper">
              <div className="signUp_content">
                <div className="title">
                  <h1>REGISTER</h1>
                </div>
                <div className="registerChangerSec">
                  <div className="btn_grp_Sec">
                    <Link
                      title="Click here to register as STUDENT"
                      to="/student-register"
                    >
                      <div className="btnBox">
                        <FaUserGraduate />
                        <p>As Student</p>
                      </div>
                    </Link>
                    <Link
                      title="Click here to register as OFFICER"
                      to="/teacher-register"
                    >
                      <div className="btnBox">
                        <GiTeacher />
                        <p>As Teacher</p>
                      </div>
                    </Link>
                    <Link
                      title="Click here to register as TEACHER"
                      to="/parent-register"
                    >
                      <div className="btnBox">
                        <MdFamilyRestroom />
                        <p>As Parent</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </GptLayout>
    </Fragment>
  );
};

export default RegisterPage;
