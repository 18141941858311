import React, { Fragment } from "react";
import GptHeader from "./gptHeader";
import "../../styles/gptLayout.scss";
import Footer from "../reusable/Footer";
const GptLayout = ({ children }) => {
  return (
    <Fragment>
      <GptHeader />
      <div className="">{children}</div>
      <Footer />
    </Fragment>
  );
};

export default GptLayout;
