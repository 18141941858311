import axios from "axios";
import {
  ATTACH_PARENT_FAIL,
  ATTACH_PARENT_REQUEST,
  ATTACH_PARENT_SUCCESS,
  CLEAR_ERROR_ATTACH_PARENT,
  CLEAR_STATE_ATTACH_PARENT,
} from "../constants/attachParentConstant";

// ATTACH PARENT ACTION
export const attachParentAction =
  (fatherName, motherName, parentEmail, motherEmail) => async (dispatch) => {
    try {
      dispatch({
        type: ATTACH_PARENT_REQUEST,
      });

      const localStorageToken = localStorage.getItem("ssb-token")
        ? JSON.parse(localStorage.getItem("ssb-token"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/ssb-gpt/attach-parent`,
        {
          fatherName,
          motherName,
          parentEmail,
          motherEmail,
        },
        config
      );
      dispatch({
        type: ATTACH_PARENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ATTACH_PARENT_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// CLEAR ERROR ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE_ATTACH_PARENT });
};

// CLEAR STATE ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR_ATTACH_PARENT });
};
