import React, { Fragment, useEffect, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import "../../styles/interview.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { userEndPoints } from "../../config";
import { toast } from "react-toastify";
import { useAuth } from "../../context/userContext";
import { audioWave, robot } from "../../assets/images";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const Interview = () => {
  const videoRef = useRef(null);
  const [auth] = useAuth();
  const navigate = useNavigate();
  const streamRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [startTest, setStartTest] = useState(false);
  const [answerBox, setAnswerBox] = useState(false);
  const [askQuestion, setAskQuestion] = useState(false);
  const [answerQuestion, setAnswerQuestion] = useState(false);
  const [preparingQuestion, setPreparingQuestion] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [questionCount, setQuestionCount] = useState(1);
  const [feedback, setFeedback] = useState();

  const [questionQueue, setQuestionQueue] = useState([]);
  const [candidateInfo, setCandidateInfo] = useState({});
  const [userAnswers, setUserAnswers] = useState([]);
  const [currentCiqTopic, setCurrentCiqTopic] = useState(null);

  const { transcript,resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const handleAttendInterview = async () => {
    try {
      setPreparingQuestion(true);
      setStartTest(true);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      if (stream) {
        try {
          const response = await axios.get(userEndPoints.askInterviewQuestion, {
            params: {
              question_count: questionCount,
              candidate_info: JSON.stringify(candidateInfo),
              question_queue: JSON.stringify(questionQueue),
              user_answers: JSON.stringify(userAnswers),
              current_ciq_topic: currentCiqTopic,
            },
          });
          const questionText = response?.data?.question;
          setQuestionCount(response.data.question_count);
          setQuestionQueue(response.data.question_queue);
          setCandidateInfo(response.data.candidate_info);
          setUserAnswers(response.data.user_answers);
          setCurrentCiqTopic(response.data.current_ciq_topic);
          setQuestionList((prevList) => [
            ...prevList,
            { question: questionText },
          ]);
          setFeedback(response?.data?.feedback);
          setPreparingQuestion(false);
          setAskQuestion(true);
          const speech = new SpeechSynthesisUtterance(questionText);
          speech.lang = "en-IN";
          window.speechSynthesis.speak(speech);
          speech.onend = () => {
            setAskQuestion(false);
            setAnswerQuestion(true);
            startListening();
            handleInterviewAnswer();
          };
        } catch (apiError) {
          setAskQuestion(false);
          setPreparingQuestion(false);
          toast.error("Failed to fetch interview question.");
        }
      }
    } catch (error) {
      setAskQuestion(false);
      toast.error("Camera access denied. Redirecting to home page.");
      navigate("/");
    }
  };

  useEffect(() => {
    if (questionList?.length > 37) {
      navigate("/feedback", { state: feedback });
    }
  });

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      return null;
    }
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      window.speechSynthesis.cancel();
    };
  }, [browserSupportsSpeechRecognition]);

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  };

  const handleInterviewAnswer = () => {
    setAnswerBox(true);
  };

  const uploadInterviewAnswer = async () => {
    if (searchValue !== "") {
      try {
        SpeechRecognition.stopListening();
        setAnswerQuestion(false);
        resetTranscript();
        const formData = new FormData();
        formData.append("user_answer", searchValue);
        formData.append("question_count", questionCount);
        formData.append("question_queue", JSON.stringify(questionQueue));
        const result = await axios.post(userEndPoints.giveAnswer, formData);
        if (result) {
          handleAttendInterview();
          setSearchValue("");
          setAnswerBox(false);
          setQuestionCount(questionCount + 1);
        }
      } catch (error) {
        toast.error(error);
        setAnswerBox(false);
      }
    } else {
      toast.error("Please write your answer");
    }
  };

  useEffect(() => {
    if (transcript) {
      setSearchValue(transcript);
    }
  }, [transcript]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchValue !== "") {
      uploadInterviewAnswer();
    }
  };

  return (
    <Fragment>
      <GptLayout>
        <section className="interview_wrapper">
          <div className="container">
            <div className="interview_sec">
              <div>
                <div className="title">
                  <h2>
                    You are attending an SSB mock personal interview on SSB-GPT
                  </h2>
                  <p>Powered By Alpha Regiment</p>
                </div>
                {!startTest ? (
                  <div className="test_instruction">
                    <h2>Points to be noted :</h2>
                    <ul>
                      <li>
                        <p>
                          The AI Bot is designed to conduct personal interviews
                          and analyze your Officer Like Qualities (OLQs) based
                          on your responses. However, Alpha Regiment does not
                          guarantee 100% accuracy.
                        </p>
                      </li>
                      <li>
                        <p>
                          Double-check your response before submitting to avoid
                          misunderstandings caused by mispronunciation or
                          background noise. You will be able to review and edit
                          your response before final submission, ensuring better
                          analysis.
                        </p>
                      </li>
                      <li>
                        <p>
                          Feel free to share any personal information with our
                          bot, and rest assured that your data is secure at all
                          times.
                        </p>
                      </li>
                    </ul>
                    <div className="start_btn">
                      <button onClick={() => handleAttendInterview()}>
                        Start Interview
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="attend_interview">
                      <div className="ai_img">
                        <div className="box">
                          <img src={robot} alt="ssbBot" />
                          {askQuestion && (
                            <div className="audio_wave">
                              <div>
                                <img src={audioWave} alt="audioWave" />
                              </div>
                            </div>
                          )}
                        </div>
                        {preparingQuestion && <h4>Analysing...</h4>}
                      </div>
                      <div className="student_face_cam">
                        <div className="box">
                          <video ref={videoRef} autoPlay />
                          {answerQuestion && (
                            <div className="audio_wave">
                              <div>
                                <img src={audioWave} alt="audioWave" />
                              </div>
                            </div>
                          )}
                        </div>

                        <h4>{auth?.user?.first_name}</h4>
                      </div>
                    </div>
                    <div className="input_box">
                      {answerBox && (
                        <div>
                          <textarea
                            type="text"
                            value={searchValue}
                            style={{ border: "1px solid #fff" }}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Write your answer"
                          />
                          <button
                            className="submit"
                            onClick={uploadInterviewAnswer}
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </GptLayout>
    </Fragment>
  );
};

export default Interview;
