export const studentSidebarLinks = [
  {
    id: 1,
    title: "Profile",
    link: "/student/profile",
  },
  {
    id: 2,
    title: "Payment",
    link: "/student/payment",
  },
  {
    id: 3,
    title: "How To Use",
    link: "/student/how-to-use",
  },
  {
    id: 4,
    title: "Help",
    link: "/student/help",
  },
];

export const teacherSidebarLinks = [
  {
    id: 1,
    title: "Profile",
    link: "/teacher/profile",
  },
  {
    id: 2,
    title: "Payment",
    link: "/teacher/payment",
  },
  {
    id: 3,
    title: "Students",
    link: "/teacher/students",
  },
  {
    id: 4,
    title: "Help",
    link: "/teacher/help",
  },
];

export const parentSidebarLinks = [
  {
    id: 1,
    title: "Profile",
    link: "/parent/profile",
  },
  {
    id: 2,
    title: "Payment",
    link: "/parent/payment",
  },
  {
    id: 3,
    title: "Your Child's Progress",
    link: "/chat",
  },
  {
    id: 4,
    title: "Help",
    link: "/parent/help",
  },
];
