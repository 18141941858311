import { applyMiddleware, combineReducers, createStore } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  forgotPasswordReducer,
  sendHelpMessageReducer,
  updateProfileReducer,
  userLoginReducer,
  userProfileReducer,
  userRegisterReducer,
} from "./reducers/userReducer";
import { attachParentReducer } from "./reducers/attachParentReducer";
import {
  attachChildReducer,
  deleteChildReducer,
  getChildsReducer,
  updateChildReducer,
} from "./reducers/attachChildReducer";
import {
  createSchoolReducer,
  getSchoolReducer,
  myReferredStudentsReducer,
  updateSchoolReducer,
} from "./reducers/schoolByTeacherReducer";

// get user from localStorage
const userFromLocalStorage = localStorage.getItem("ssb-token")
  ? JSON.parse(localStorage.getItem("ssb-token"))
  : {};

// COMBINE ALL REDUCERS
const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  profile: userProfileReducer,
  forgotPassword: forgotPasswordReducer,
  attachParent: attachParentReducer,
  updateProfile: updateProfileReducer,
  profileHelpMessage: sendHelpMessageReducer,
  attachChild: attachChildReducer,
  getChilds: getChildsReducer,
  updateChild: updateChildReducer,
  deleteChild: deleteChildReducer,
  createSchool: createSchoolReducer,
  getSchool: getSchoolReducer,
  updateSchool: updateSchoolReducer,
  myReferredStudents: myReferredStudentsReducer,
});

// INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userFromLocalStorage,
  },
};

// MIDDLEWARE // THUNK
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
