import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import {  useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { pushup1, pushup2, pushup3 } from "../../../assets/images";

const PushupGuidance = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Push-Ups the Right Way</h2>
          <p>
            Push-ups are a powerful bodyweight exercise that targets your chest,
            shoulders, triceps, and core. Mastering the right form is key to
            reaping the full benefits and avoiding injury. Here's a step-by-step
            guide to doing push-ups correctly.
          </p>
          <br />
          <h4 className="subHeading">Step 1: Get Into Position</h4>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/IODxDxX7oi4"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <span>
            1. <strong>Start on the Ground:</strong>
            Begin in a high plank position, with your palms flat on the ground
            and your hands placed slightly wider than shoulder-width apart. Your
            feet should be close together, forming a straight line from your
            head to your heels.
          </span>
          <span>
            2. <strong>Engage Your Core:</strong>
            Tighten your core to keep your back flat and prevent sagging or
            arching. Imagine pulling your belly button towards your spine.
          </span>
          <img src={pushup1} alt="pushup1" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Lower Your Body</h4>
          <span>
            1. <strong>Bend Your Elbows:</strong> Slowly lower your chest to the
            ground by bending your elbows, keeping them at a 45-degree angle
            from your body. Avoid letting them flare out too far to the sides,
            which can strain your shoulders.
          </span>
          <span>
            2. <strong>Inhale as You Lower:</strong> Take a breath in as you go
            down. Aim to lower your body until your chest is just above the
            ground (1-2 inches from the floor).
          </span>
          <img src={pushup2} alt="pushup2" className="image_box" />
          <br />
          <h4 className="subHeading">Step 3: Push Back Up</h4>
          <span>
            1. <strong>Exhale and Press Up:</strong> Push through your palms to
            extend your arms and return to the starting position. Exhale as you
            press up, keeping your core engaged and body aligned.
          </span>
          <span>
            2. <strong>Keep Your Movements Controlled:</strong> Don’t rush!
            Slow, controlled movements are key to building strength and proper
            form.
          </span>
          <img src={pushup3} alt="pushup3" className="image_box" />
          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Sagging or Arching Back:</strong> This is often due
            to lack of core engagement. Focus on tightening your core to
            maintain proper alignment.
          </span>
          <span>
            {"b)."} <strong>Flaring Elbows:</strong> Keep your elbows closer to
            your body to avoid shoulder strain.
          </span>
          <span>
            {"c)."} <strong>Limited Range of Motion:</strong> Lower your body as
            close to the ground as possible to fully activate your muscles.
          </span>
          <br />
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default PushupGuidance;
