import React, { Fragment } from "react";
import "../styles/paymentPage.scss";
import ProfileLayout from "../component/reusable/ProfileLayout.js";
import Loader from "../component/reusable/Loader.js";
import GptLayout from "../component/ssbGpt/gptLayout.js";
import {
  parentSidebarLinks,
  studentSidebarLinks,
  teacherSidebarLinks,
} from "../profileSidebarLinks.js";
import { useAuth } from "../context/userContext.js";

const PaymentPage = () => {
  const [auth] = useAuth();
  const user = auth?.user;
  const loading = false;
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <GptLayout>
          <ProfileLayout
            sidebarLinks={
              user?.role === "11"
                ? studentSidebarLinks
                : user?.role === "20"
                ? parentSidebarLinks
                : (user?.role === "12" || user?.role === "13") &&
                  teacherSidebarLinks
            }
          >
            <section className="paymentPage_main">
              <h1 className="pageHeading">Payment Page</h1>
            </section>
          </ProfileLayout>
        </GptLayout>
      )}
    </Fragment>
  );
};

export default PaymentPage;
