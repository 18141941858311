import React, { Fragment, useEffect, useRef, useState } from "react";
import userService from "../../services/userService";
import moment from "moment";
import GptLayout from "./gptLayout";
import GptSidebar from "./gptSidebar";
import { Link } from "react-router-dom";
import { TiArrowUp } from "react-icons/ti";
import { imgAdd, ssbBook } from "../../assets/images";
import bookService from "../../services/bookService";
import { RxCross2 } from "react-icons/rx";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { toast } from "react-toastify";
import PopupPrompt from "../reusable/PopupPrompt";
import { useAuth } from "../../context/userContext";
import { LuBookmark } from "react-icons/lu";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import Loader from "../reusable/Loader";

const SrtAttempt = () => {
  const scrollRef = useRef(null);
  const divRef = useRef(null);

  const [auth] = useAuth();
  const [showHistory, setShowHistory] = useState(false);
  const [outputData, setOutputData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [srtLoading, setSrtLoading] = useState(false);
  const [localHistory, setlocalHistory] = useState([]);

  // ALL BOOK SAVE STATE STARTS

  const [addBook, setAddBook] = useState(false);
  const [bookName, setBookName] = useState("");
  const [newBookLoading, setNewBookLoading] = useState(false);
  const [bookList, setBookList] = useState([]);
  const [addChapter, setAddChapter] = useState(false);
  const [chapterBook, setChapterBook] = useState("");
  const [chapterId, setChapterId] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [saveIndex, setSaveIndex] = useState(0);
  const [chapterModal, setChapterModal] = useState(false);
  const [bookChapterList, setBookChapterList] = useState();
  const [showBook, setShowBook] = useState(false);
  const [chapterLoading, setChapterLoading] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);

  const todatDate = new Date();
  const currentDate = moment(todatDate).format("YYYY-MM-DD");

  // GET DATA FROM LOCAL STORAGE
  useEffect(() => {
    const existingLocalHistory = localStorage.getItem("userHistory")
      ? JSON.parse(localStorage.getItem("userHistory"))
      : [];
    setlocalHistory(existingLocalHistory);
  }, []);

  // HANDLE SRT STARTS ==================
  const handleAskSrt = async () => {
    setSrtLoading(true);
    try {
      const result = await userService.generateSrt();
      if (result) {
        setOutputData((prevData) => [
          ...prevData,
          {
            srtList: result?.data?.situations,
          },
        ]);

        let updatedList = [...localHistory];
        const existingEntry = updatedList.find(
          (item) => item.dayData === currentDate
        );
        if (existingEntry) {
          existingEntry.searchData.push({
            srtList: result?.data?.situations,
          });
        } else {
          updatedList.push({
            dayData: currentDate,
            searchData: [
              {
                srtList: result?.data?.situations,
              },
            ],
          });
        }
        setQuestionList(updatedList);
        localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setSrtLoading(false);
      }
    } catch (error) {
      setSrtLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleAskSrt();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // const handleSubmitSrt = async () => {
  //   setIsLoading(true);
  //   try {
  //     const result = await userService.generateSrt();
  //     if (result) {
  //       setOutputData((prevData) => [
  //         ...prevData,
  //         {
  //           question: result?.data?.situation,
  //         },
  //       ]);
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };

  const handleAddSrt = () => {};

  // HANDLE SRT ENDS ===================

  // GET SCROLL ON USER SEARCH
  useEffect(() => {
    if (scrollRef.current && outputData) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [outputData]);

  const handleAddBookModal = () => {
    if (auth?.user?._id) {
      setAddBook(true);
    } else {
      toast.error("Login before adding new book");
    }

    setShowBook(false);
  };

  const fetchBookList = async () => {
    try {
      setIsLoading(true);
      const res = await bookService.myAllBookList();
      if (res) {
        setIsLoading(false);
        setBookList(res?.data?.books);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.user?._id) {
      fetchBookList();
    }
  }, [auth?.user?._id]);

  const handleAddnewBook = async () => {
    setNewBookLoading(true);
    try {
      const res = await bookService.createUserNewBook(bookName);
      if (res?.data) {
        toast.success("Book Add Successfully");
        setNewBookLoading(false);
        fetchBookList();
        setBookName("");
        setAddBook(false);
      }
    } catch (error) {
      setNewBookLoading(false);
      setBookName("");
      setAddBook(false);
    }
  };

  // ADD NEW BOOK ENDS

  let optionBookList = [];

  bookList?.forEach((ele) => {
    optionBookList.push({
      option: ele?.name,
      value: ele?._id,
    });
  });

  let chapterListFromBookId = [];
  bookChapterList?.chapters?.forEach((ele) => {
    chapterListFromBookId.push({
      option: ele?.name,
      value: ele?._id,
    });
  });

  // ADD NEW CHAPTER STARTS

  const handleChapterSave = (idx) => {
    setSaveIndex(idx);
  };

  const handleAddNewChapter = async () => {
    setNewBookLoading(true);
    try {
      const res = await bookService.addNewChapter(chapterBook, chapterName);
      if (res?.data?.success) {
        setAddChapter(false);
        setChapterBook("");
        setNewBookLoading(false);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
        setAddChapter(false);
        setChapterBook("");
        setNewBookLoading(false);
      }
    } catch (error) {
      setNewBookLoading(false);
      setAddChapter(false);
    }
  };

  const openBook = (id) => {
    setChapterModal(true);
    getChapterListBybookId(id);
  };

  const getChapterListBybookId = async (id) => {
    setChapterLoading({
      loading: true,
      index: id,
    });
    try {
      const res = await bookService.myAllChapterbyBookid(id);
      if (res?.data?.success) {
        setShowBook(false);
        setBookChapterList(res?.data);
        setChapterLoading(false);
      }
    } catch (error) {
      setShowBook(false);
      setChapterLoading(false);
    }
  };

  const handleShowHistory = (date) => {
    const extractedData = questionList
      ?.filter((ele) => ele?.dayData === date)
      .map((item) => item.searchData)
      .flat();
    setOutputData(extractedData);
  };

  const handleAddChapter = () => {
    setAddChapter(true);
    setChapterModal(false);
  };

  const [saveUnit, setSaveUnit] = useState(false);

  const addNewUnit = async () => {
    setNewBookLoading(true);
    const data = outputData?.find((_, id) => saveIndex === id);
    const type = "text";
    try {
      const res = await bookService.addNewChapterData(chapterId, type, data);
      if (res?.data?.success) {
        setSaveUnit(false);
        setChapterBook("");
        setChapterId("");
        setNewBookLoading(false);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
        setSaveUnit(false);
        setChapterBook("");
        setChapterId("");
        setNewBookLoading(false);
      }
    } catch (error) {
      setNewBookLoading(false);
      setSaveUnit(false);
    }
  };

  const initialState = Array.from({ length: 60 }, (_, i) => ({
    question: `Question ${i + 1}`,
    answer: "",
  }));

  const [answers, setAnswers] = useState(initialState);

  const handleInputChange = (index, value) => {
    setAnswers((prevAnswers) => {
      prevAnswers[index].answer = value;
      return [...prevAnswers];
    });
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <GptLayout>
          <div className="container">
            <section className="gpt_layout_wrapper">
              <GptSidebar setOutputData={setOutputData} />

              <section className="gpt_Search_box">
                <div className="input_box">
                  <div className={`srch_output custonScroll`} ref={scrollRef}>
                    {/* SEARCH HISORTY AND ASK QUESTION BOX  */}
                    {outputData?.length > 0 &&
                      outputData?.map((ele, idx) => (
                        <>
                          {ele?.srtList?.length > 0 && (
                            <h5>
                              {ele?.srtList?.map((itm, id) => (
                                <span key={id}>
                                  {id + 1}. {itm}
                                </span>
                              ))}
                              <div className="action_btn">
                                <span onClick={() => handleChapterSave(idx)}>
                                  <LuBookmark />
                                  Save
                                </span>
                                <span>
                                  <AiFillDislike />
                                </span>
                                <span>
                                  <AiFillLike />
                                </span>
                              </div>
                            </h5>
                          )}
                        </>
                      ))}

                    {srtLoading && (
                      <div className="tat_test_msg">
                        <p>
                          Your SRT test is preparing. It will take upto 5-6 min.{" "}
                          <br />
                          Do not refresh the page in case you may loss data
                        </p>
                      </div>
                    )}

                    {/* SEARCH HISTORY AND ASK QUESTION BOX ENDS */}

                    {/* HANLDE TAT LOGICS STARTS  */}
                  </div>
                  <div className="answer_input_gpt">
                    <div>
                      <div className="input_index">
                        {Array.from({ length: 60 }, (_, i) => (
                          <div key={i}>
                            <span>Ans {i + 1} :</span>
                            <textarea
                              value={answers[i].answer}
                              onChange={(e) =>
                                handleInputChange(i, e.target.value)
                              }
                              placeholder="Write your answer"
                            />
                          </div>
                        ))}
                      </div>
                      <button className="search" onClick={() => handleAddSrt()}>
                        <TiArrowUp />
                      </button>
                    </div>

                    <p className="advice_txt">
                      {/* SSB-GPT can make mistakes. We suggest seeking advice from
                    experts.  */}
                      Read our <Link to="/term-of-use">Terms</Link> and{" "}
                      <Link to="/privacy-policy">Privacy Policy</Link>.
                    </p>
                  </div>
                </div>
              </section>

              <div className="gpt_books_wrapper">
                <div className="notes_content">
                  <h2>Notes</h2>
                  <ul>
                    {bookList?.map((ele) => (
                      <li key={ele?._id}>
                        <div
                          className="book_tab"
                          onClick={() => openBook(ele?._id)}
                        >
                          <img src={ssbBook} alt="ssb_book" />
                          <p>{ele?.name}</p>
                        </div>
                      </li>
                    ))}
                    <li>
                      <div
                        className="book_tab"
                        onClick={() => handleAddBookModal(true)}
                      >
                        <img src={imgAdd} alt="imgAdd" />
                        <p>Add New</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <section className="mobile_bar_content">
                <div className="container">
                  <div className="mobile_navigation">
                    <span onClick={() => setShowHistory(true)}>History</span>
                    <span onClick={() => setShowBook(true)}>Notes</span>
                  </div>
                </div>

                <div
                  className={`book_list ${showBook ? "book_list_show" : ""}`}
                >
                  <div className="gpt_books">
                    <div className="title">
                      <h2>Books</h2>
                      <span onClick={() => setShowBook(false)}>
                        <RxCross2 />
                      </span>
                    </div>
                    <ul>
                      {bookList?.map((ele) => (
                        <li key={ele?._id}>
                          <div
                            className="book_tab"
                            onClick={() => getChapterListBybookId(ele?._id)}
                          >
                            <img src={ssbBook} alt="ssb_book" />
                            <p>
                              {chapterLoading?.loading &&
                              chapterLoading?.index === ele?._id
                                ? "Loading.."
                                : ele?.name}
                            </p>
                          </div>
                        </li>
                      ))}
                      <li>
                        <div
                          className="book_tab"
                          onClick={() => handleAddBookModal()}
                        >
                          <img src={imgAdd} alt="imgAdd" />
                          <p>Add New</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className={`history_list ${
                    showHistory ? "history_list_show" : ""
                  }`}
                >
                  <div className="gpt_books">
                    <div className="title">
                      <h2>Search History</h2>
                      <span onClick={() => setShowHistory(false)}>
                        <RxCross2 />
                      </span>
                    </div>
                    <ul>
                      {questionList?.length > 0 ? (
                        questionList?.map((ele, id) => (
                          <li
                            key={id}
                            onClick={() => setViewHistory(!viewHistory)}
                          >
                            <p>
                              {ele?.dayData}
                              {!viewHistory ? (
                                <RiArrowDropDownLine />
                              ) : (
                                <RiArrowDropUpLine />
                              )}
                            </p>

                            {viewHistory && (
                              <span
                                onClick={() => handleShowHistory(ele?.dayData)}
                              >
                                {ele?.searchData?.map((item, id) => (
                                  <p key={id}>
                                    {id + 1}. {item?.question}
                                  </p>
                                ))}
                              </span>
                            )}
                          </li>
                        ))
                      ) : (
                        <li>
                          <p>No search history found</p>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </section>
            </section>

            {addBook && (
              <PopupPrompt
                divRef={divRef}
                cancelBtnTxt="Cancel"
                cancelOnclick={() => setAddBook(false)}
                confirmBtnTxt="Save Note"
                confirmOnclick={() => handleAddnewBook()}
                title="Add Your New Notes"
                newBookPlaceholder="Write your Note name"
                newBookLabel="Note Name"
                newbookValue={bookName}
                loading={newBookLoading}
                onNewBookChange={(e) => setBookName(e.target.value)}
              />
            )}

            {addChapter && (
              <PopupPrompt
                divRef={divRef}
                cancelBtnTxt="Cancel"
                cancelOnclick={() => setAddChapter(false)}
                confirmBtnTxt="Save Chapter"
                confirmbtnWidth="140px"
                confirmOnclick={() => handleAddNewChapter()}
                title="Save Chapter"
                newChapterPlaceholder="Select your note name"
                newChapterLabel="Note Name"
                newChapterValue={chapterBook}
                loading={newBookLoading}
                bookOption={optionBookList}
                onNewChapterChange={(e) => setChapterBook(e.target.value)}
                onNewChapterNameChange={(e) => setChapterName(e.target.value)}
                newChapterNamePlaceholder="Enter your chapter name"
                newChapterNameLabel="Chapter Name"
                newChapterNameValue={chapterName}
              />
            )}

            {saveUnit && (
              <PopupPrompt
                divRef={divRef}
                cancelBtnTxt="Cancel"
                cancelOnclick={() => setSaveUnit(false)}
                confirmBtnTxt="Save Topic"
                confirmbtnWidth="140px"
                confirmOnclick={() => addNewUnit()}
                title="Add Chapter"
                newChapterPlaceholder="Select your note name"
                newChapterLabel="Note Name"
                newChapterValue={chapterBook}
                loading={newBookLoading}
                bookOption={optionBookList}
                onNewChapterChange={(e) => setChapterBook(e.target.value)}
                onChapterIdChange={(e) => setChapterId(e.target.value)}
                chapterIdPlaceholder="Select your chapter name"
                chapterIdLabel="Chapter Name"
                chapterIdValue={chapterId}
                chapterOption={chapterListFromBookId}
              />
            )}

            {chapterModal && (
              <PopupPrompt
                ref={divRef}
                confirmBtnTxt="Add Chapter"
                confirmbtnWidth="160px"
                confirmOnclick={() => handleAddChapter()}
                cancelBtnTxt="Close List"
                cancelOnclick={() => setChapterModal(false)}
                title={"Chapter List"}
                chapterData={bookChapterList?.chapters}
              />
            )}
          </div>
        </GptLayout>
      )}
    </Fragment>
  );
};

export default SrtAttempt;
