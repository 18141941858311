import React, { useEffect, useState } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import {
  IoArrowRedoSharp,
  IoArrowUndoSharp,
  IoShareSocial,
} from "react-icons/io5";
import styled from "styled-components";
import { RWebShare } from "react-web-share";

const Div = styled.div`
  position: fixed;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  box-sizing: border-box;
  z-index: 1;

  top: unset;
  left: unset;
  transform: unset;
  bottom: 150px;
  right: 10px;
  > p {
    background: #121d40;
    color: #f2f2f2;
    width: 45px;
    height: 45px;
    opacity: 0.5;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #3c3c77;
    border-radius: 50%;
    @media screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
    > svg {
      font-size: 25px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    &:hover {
      background: #f3ad6c;
      color: #081336;
      opacity: 1;
    }
  }

  // @media screen and (max-width: 991px) {
  //   top: unset;
  //   left: unset;
  //   transform: unset;
  //   bottom: 60px;
  //   right: 10px;
  // }

  > .socialBtns {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 0;
    transition: 0.4s;
    overflow: hidden;
    @media screen and (max-width: 991px) {
      height: 0;
    }

    &.show {
      width: 30px;
      @media screen and (max-width: 991px) {
        height: auto;
      }
    }

    > button {
      background: #121d40;
      color: #f2f2f2;
      width: 30px;
      height: 30px;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #3c3c77;
      > svg {
        font-size: 20px;
      }
      &:hover {
        background: #f3ad6c;
        color: #081336;
      }
    }
  }

  > .socialHandleArrow {
    border: none;
    background-color: transparent;
    color: #f2f2f2;
    outline: none;
    cursor: pointer;
    margin-top: 10px;
    > svg {
      transform: scaleY(-1);
      font-size: 15px;
    }

    @media screen and (max-width: 991px) {
      border: 1px solid #f3ad6c;
      background-color: #081336;
      color: #f2f2f2;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 50%;
    }
  }
`;

const SideSocialIcons = () => {
  const [seeIcons, setSeeIcons] = useState(true);

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setSeeIcons(false);
    }
  }, []);

  return (
    <Div>
      {/* <div className={`socialBtns ${seeIcons ? "show" : ""}`}>
        <button>
          <FaInstagram />
        </button>
        <button>
          <FaYoutube />
        </button>
        <button>
          <FaLinkedin />
        </button>
        <button>
          <FaFacebookF />
        </button>
        <button>
          <FaTwitter />
        </button>
      </div>
      {!seeIcons ? (
        <button
          className="socialHandleArrow"
          title="Show Icons"
          onClick={() => setSeeIcons(true)}
        >
          <IoArrowRedoSharp />
        </button>
      ) : (
        <button
          className="socialHandleArrow"
          title="Hide Icons"
          onClick={() => setSeeIcons(false)}
        >
          <IoArrowUndoSharp />
        </button>
      )} */}
      <RWebShare
        data={{
          url: `${window.location.href}`,
          title: `SSB-GPT`,
        }}
        onClick={() => console.log("shared")}
      >
        <p title="Share">
          <IoShareSocial />
        </p>
      </RWebShare>
    </Div>
  );
};

export default SideSocialIcons;
