import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { calf1 } from "../../../assets/images";

const CalfRaisesGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Calf Raises the Right Way</h2>
          <p>
            Calf raises are a simple yet effective exercise for strengthening
            the calf muscles, particularly the gastrocnemius and soleus. They
            improve lower leg stability, endurance, and can help enhance overall
            athletic performance. Here’s how to do calf raises with proper form.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/UV8gOrHmuKc"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Start in a Standing Position</h4>

          <span>
            1. <strong> Stand Tall with Feet Hip-Width Apart: </strong>
            Stand with your feet about hip-width apart, arms relaxed by your
            sides.
          </span>

          <span>
            2. <strong> Engage Your Core: </strong>
            Keep your core tight and your body upright to prepare for the
            movement.
          </span>

          <img src={calf1} alt="calf1" className="image_box" />
          <br />
          <h4 className="subHeading">
            Step 2: Lower into a Squat and Place Hands on the Ground
          </h4>
          <span>
            1. <strong> Squat Down:</strong>
            Begin by squatting down, keeping your back straight, and lower your
            hands to the floor in front of you.
          </span>
          <span>
            2. <strong> Place Hands Shoulder-Width Apart: </strong> Plant your
            hands on the ground directly in front of your feet, shoulder-width
            apart.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Jump Back into a Plank</h4>
          <span>
            1. <strong> Hop Both Feet Back:</strong>
            Jump your feet back to land in a high plank position, with your body
            in a straight line from head to heels.
          </span>

          <span>
            2. <strong>Maintain a Tight Core: </strong>
            Keep your core engaged, and make sure your hips don’t sag or lift
            too high.
          </span>
          <br />
          <h4 className="subHeading">Step 4: Perform a Push-Up (Optional)</h4>
          <span>
            1. <strong> Lower to the Ground:</strong>
            For added intensity, you can lower your body down into a push-up,
            keeping elbows close to your body.
          </span>

          <span>
            2. <strong>Press Back Up:</strong>
            Push back up to the plank position to continue with the burpee.
          </span>
          <br />
          <h4 className="subHeading">Step 5: Jump Feet Forward and Stand</h4>
          <span>
            1. <strong> Hop Feet Back to Hands:</strong>
            Jump your feet forward so they land just outside your hands, coming
            back into a squat.
          </span>
          <span>
            2. <strong>Stand Up Explosively: </strong>
            Push through your heels to stand up, extending your body fully.
          </span>
          <br />
          <h4 className="subHeading">Step 6: Jump Up</h4>
          <span>
            1. <strong> Explode into a Jump:</strong>
            Extend your arms overhead and jump as high as you can, landing
            softly to finish one repetition.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Not Engaging the Core:</strong> Keep your core tight
            to maintain alignment during the plank and jump back phases.
          </span>
          <span>
            {"b)."} <strong> Landing Hard: </strong> Land softly to reduce
            impact on your knees and avoid injury.
          </span>
          <span>
            {"c)."} <strong>Rushing the Push-Up: </strong>
            If you choose to add the push-up, ensure it’s controlled and doesn’t
            compromise your form.
          </span>
          <br />
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default CalfRaisesGuidance;
