import React, { useEffect, useState } from "react";
import "../styles/profileHelp.scss";
import GptLayout from "../component/ssbGpt/gptLayout";
import ProfileLayout from "../component/reusable/ProfileLayout";
import CustomInput from "../component/reusable/CustomInput";
import { FaUser } from "react-icons/fa";
import CustomTextarea from "../component/reusable/CustomTextarea";
import { MdOutlineMail } from "react-icons/md";
import CustomButton from "../component/reusable/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearErrors,
  clearUpdateState,
  sendHelpMessage,
} from "../redux/actions/userAction";
import ButtonLoader from "../component/reusable/ButtonLoader";
import { useAuth } from "../context/userContext";
import {
  parentSidebarLinks,
  studentSidebarLinks,
  teacherSidebarLinks,
} from "../profileSidebarLinks";

const ProfileHelp = () => {
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const user = auth?.user;

  const [helpMessage, setHelpMessage] = useState({
    name: "",
    email: "",
    message: "",
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setHelpMessage({
      ...helpMessage,
      [name]: value,
    });
  };

  const { loading, data, error } = useSelector(
    (state) => state.profileHelpMessage
  );

  const handleSendMessage = (e) => {
    e.preventDefault();
    dispatch(
      sendHelpMessage(
        helpMessage?.name,
        helpMessage?.email,
        helpMessage?.message
      )
    );
  };

  useEffect(() => {
    if (data?.success) {
      toast.success(data?.message);
      dispatch(clearUpdateState());
      setHelpMessage({
        name: "",
        email: "",
        message: "",
      });
    }
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [data?.message, data?.success, dispatch, error]);

  return (
    <GptLayout>
      <ProfileLayout
        sidebarLinks={
          user?.role === "11"
            ? studentSidebarLinks
            : user?.role === "20"
            ? parentSidebarLinks
            : user?.role === "12" || user?.role === "13"
            ? teacherSidebarLinks
            : []
        }
      >
        <section className="profileHelp_main txt_color">
          <h1 className="page_heading">Need Assistance? We’re Here to Help!</h1>

          <p className="description">
            If you have questions or need help with any part of the SSB-GPT
            platform, please use the form below to submit your query. Whether
            it's about using features, troubleshooting an issue, or
            understanding your results, our support team is ready to assist.
            Simply fill in the details, and we’ll get back to you as soon as
            possible.
          </p>

          <form className="helpForm">
            <h2>Transmit your message to us.</h2>
            <CustomInput
              icon={<FaUser />}
              label="Name"
              impStar="*"
              type="text"
              placeholder="Ex: Pankaj Yadav"
              name="name"
              onChange={handleInputChange}
              value={helpMessage.name}
            />
            <CustomInput
              icon={<MdOutlineMail />}
              label="Email"
              impStar="*"
              type="email"
              placeholder="Ex: yourmail@example.com"
              name="email"
              onChange={handleInputChange}
              value={helpMessage.email}
            />
            <CustomTextarea
              label="How can we help?"
              type="text"
              placeholder="message...."
              name="message"
              onChange={handleInputChange}
              value={helpMessage.message}
            />
            <CustomButton
              className="submit_btn"
              title={loading ? <ButtonLoader /> : `Submit Request`}
              onClick={handleSendMessage}
              disabled={loading}
              width="100%"
              tooltip="Submit Request"
            />
          </form>
        </section>
      </ProfileLayout>
    </GptLayout>
  );
};

export default ProfileHelp;
