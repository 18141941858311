import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { armCircle } from "../../../assets/images";

const ArmCircleGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Arm Circles the Right Way</h2>
          <p>
            Arm circles are a simple but effective exercise for warming up the
            shoulders, improving mobility, and increasing blood flow to the
            upper body. This exercise engages the deltoids, rotator cuffs, and
            traps, making it a great addition to your warm-up routine. Here’s
            how to do arm circles properly.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/UVMEnIaY8aU"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1:Start in an Upright Position</h4>

          <span>
            1. <strong> Stand with Feet Shoulder-Width Apart:</strong>
            Begin by standing tall with your feet shoulder-width apart. Engage
            your core to maintain a stable posture.
          </span>

          <span>
            2. <strong>Extend Your Arms to the Sides: </strong>
            Stretch your arms straight out to the sides, parallel to the ground.
            Keep your palms facing down and fingers extended.
          </span>

          <img src={armCircle} alt="armCircle" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Perform Small Circles</h4>
          <span>
            1. <strong>Make Small Circles: </strong>
            Start making small circles with your arms, moving forward. Keep your
            movements controlled and steady. Start with 10–20 circles in one
            direction.
          </span>
          <span>
            2. <strong>Focus on Smooth, Fluid Movement: </strong> Ensure that
            your arms are not jerking or using momentum; the movement should be
            slow and deliberate.
          </span>

          <br />
          <h4 className="subHeading">
            Step 3:Gradually Increase the Circle Size
          </h4>
          <span>
            1.
            <strong>Increase the Size of the Circles:</strong>
            After completing the small circles, gradually increase the size of
            the circles to engage your shoulder muscles more deeply.
          </span>

          <span>
            2. <strong>Reverse the Direction: </strong>
            After completing a set of forward circles, reverse the direction to
            work the shoulders from different angles. Repeat for the same number
            of circles.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Using Momentum: </strong> Avoid swinging your arms
            or using momentum to make larger circles. Focus on controlled, fluid
            motions for maximum effectiveness.
          </span>
          <span>
            {"b)."} <strong>Shrugging the Shoulders: </strong> Keep your
            shoulders relaxed and avoid shrugging them up toward your ears.
          </span>
          <span>
            {"c)."} <strong>Not Engaging the Core:</strong>
            Make sure your core is engaged to maintain good posture throughout
            the exercise.
          </span>
          <br />
          <h4 className="subHeading">Variations for Added Intensity</h4>
          <span>
            {"a)."} <strong>Weighted Arm Circles:</strong> Hold light weights
            (like dumbbells or water bottles) in each hand to increase the
            intensity of the exercise.
          </span>
          <span>
            {"b)."} <strong>Reverse Arm Circles: </strong> Perform circles in
            the opposite direction for a balanced shoulder workout.
          </span>
          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default ArmCircleGuidance;
