import React, { Fragment, useEffect } from "react";
import "../styles/studentsOfTeacher.scss";
import ProfileLayout from "../component/reusable/ProfileLayout.js";
import Loader from "../component/reusable/Loader.js";
import GptLayout from "../component/ssbGpt/gptLayout.js";
import { teacherSidebarLinks } from "../profileSidebarLinks.js";
import {
  clearSchoolErrors,
  getMyRefStudentsAction,
} from "../redux/actions/schoolByTeacherAction.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import logo_mobile from "../images/logo_mobile.svg";
import moment from "moment";

const StudentsOfTeacher = () => {
  const dispatch = useDispatch();

  // GET STUDENTS START
  const { loading, data, error } = useSelector(
    (state) => state.myReferredStudents
  );

  useEffect(() => {
    dispatch(getMyRefStudentsAction());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearSchoolErrors());
    }
  }, [error, dispatch]);

  // GET STUDENTS ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <GptLayout>
          <ProfileLayout sidebarLinks={teacherSidebarLinks}>
            <section className="studentsOfTeacher_main">
              <h1 className="pageHeading">Students</h1>

              <div className="dataTable custonScroll">
                {!data?.students?.length ? (
                  <div className="noData txt_color">
                    You have not referred students yet
                  </div>
                ) : (
                  <table className="table" cellSpacing={0}>
                    <thead>
                      <tr>
                        <th>Profile Picture</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Joined At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.students?.map((item) => (
                        <tr key={item?._id}>
                          <td>
                            <img
                              className="profilePic"
                              src={
                                item?.proile?.url
                                  ? item?.proile?.url
                                  : logo_mobile
                              }
                              alt="logo_mobile"
                            />
                          </td>
                          <td>{item?.first_name}</td>
                          <td>{item?.email}</td>
                          <td>
                            {moment(item?.createdAt).format("MMM DD, YYYY")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </section>
          </ProfileLayout>
        </GptLayout>
      )}
    </Fragment>
  );
};

export default StudentsOfTeacher;
