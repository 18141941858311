import { userEndPoints } from "../config";
import CommonService from "./CommonService";
class userService {
  async userLogin(email, password) {
    return CommonService.post(userEndPoints.login, {
      email: email,
      password: password,
    });
  }

  async getUserProfile(data) {
    return CommonService.get(userEndPoints.userprofile);
  }
  async userChat(data) {
    return CommonService.post(userEndPoints.chat, {
      input_text: data,
    });
  }
  async userPdfUpload(data) {
    const formData = new FormData();
    formData.append("file", data);
    return CommonService.post(userEndPoints.pdfUpload, {
      formData,
    });
  }
  async userAskQuestion(data) {
    return CommonService.post(userEndPoints.askQuestion, {
      input_text: data,
    });
  }
  async summarizeLink(data) {
    return CommonService.post(userEndPoints.linkSummarize, {
      input_text: data,
    });
  }
  async generateTatImage(data) {
    return CommonService.get(userEndPoints.generateImage + `?category=${data}`);
  }


  async generateSrt() {
    return CommonService.get(userEndPoints.getSrt);
  }
}

export default new userService();
