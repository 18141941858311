export const CREATE_SCHOOL_REQUEST = "CREATE_SCHOOL_REQUEST";
export const CREATE_SCHOOL_SUCCESS = "CREATE_SCHOOL_SUCCESS";
export const CREATE_SCHOOL_FAIL = "CREATE_SCHOOL_FAIL";

export const GET_SCHOOL_REQUEST = "GET_SCHOOL_REQUEST";
export const GET_SCHOOL_SUCCESS = "GET_SCHOOL_SUCCESS";
export const GET_SCHOOL_FAIL = "GET_SCHOOL_FAIL";

export const UPDATE_SCHOOL_REQUEST = "UPDATE_SCHOOL_REQUEST";
export const UPDATE_SCHOOL_SUCCESS = "UPDATE_SCHOOL_SUCCESS";
export const UPDATE_SCHOOL_FAIL = "UPDATE_SCHOOL_FAIL";

export const MYREF_STUDENTS_REQUEST = "MYREF_STUDENTS_REQUEST";
export const MYREF_STUDENTS_SUCCESS = "MYREF_STUDENTS_SUCCESS";
export const MYREF_STUDENTS_FAIL = "MYREF_STUDENTS_FAIL";

export const CLEAR_STATE_SCHOOL = "CLEAR_STATE_SCHOOL";
export const CLEAR_ERROR_SCHOOL = "CLEAR_ERROR_SCHOOL";
