import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import {  stepUps } from "../../../assets/images";

const StepUpsGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Step-Ups the Right Way </h2>
          <p>
            Step-ups are a powerful lower-body exercise that targets the glutes,
            quads, hamstrings, and calves. They also help improve balance,
            coordination, and core stability. Here’s a step-by-step guide to
            performing step-ups with proper form.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/wfhXnLILqdk"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Set Up and Position Yourself</h4>

          <span>
            1. <strong> Choose a Sturdy Platform:</strong>
            Use a bench, step, or box that is high enough so your knee forms
            about a 90-degree angle when your foot is placed on it.
          </span>

          <span>
            2. <strong>Stand with Feet Hip-Width Apart: </strong>
            Stand facing the platform with your feet about hip-width apart. Keep
            your core tight, back straight, and shoulders relaxed.
          </span>

          <img src={stepUps} alt="stepUps" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Step Up with One Leg</h4>
          <span>
            1. <strong>Place Your Right Foot on the Platform:</strong>
            Firmly plant your right foot on the center of the platform.
          </span>
          <span>
            2. <strong>Press Through Your Heel: </strong>Push through the heel
            of your right foot to lift your body upward. Bring your left foot up
            to meet your right foot on the platform, standing tall at the top.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Step Back Down</h4>
          <span>
            1.
            <strong>Step Down Slowly:</strong>
            Reverse the motion by stepping your left foot back down, then your
            right foot, to return to the starting position.
          </span>

          <span>
            2. <strong> Repeat for Desired Reps:</strong>
            Complete the desired number of repetitions on one side, then switch
            to the other leg.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Using Momentum:</strong> Avoid pushing off with your
            back leg. Instead, focus on lifting with the leg on the platform.
          </span>
          <span>
            {"b)."} <strong>Letting Your Knee Cave In: </strong> Keep your knee
            in line with your toes throughout the movement to protect your
            joints.
          </span>
          <span>
            {"c)."} <strong>Not Engaging the Core:</strong>
            Maintain core tightness to help stabilize your body and avoid
            leaning forward.
          </span>
          <br />
          <h4 className="subHeading">Variations for Added Intensity</h4>
          <span>
            {"a)."} <strong> Weighted Step-Up:</strong> Hold dumbbells in each
            hand or place a barbell on your shoulders for extra resistance.
          </span>
          <span>
            {"b)."} <strong>Knee Drive at the Top: </strong> When you step up,
            drive your opposite knee up toward your chest for added core and hip
            flexor engagement.
          </span>

          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default StepUpsGuidance;
