import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GptLayout from "./gptLayout";
import jsPDF from "jspdf";

const InterviewFeedback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleDownload = () => {
    const doc = new jsPDF();
    doc.text(feedbackData, 10, 10);
    // Save the PDF
    doc.save("feedback_report.pdf");

    navigate("/");
  };
  const feedbackData = location?.state;
  return (
    <Fragment>
      <GptLayout>
        <div className="container">
          <section className="interview_wrapper">
            <div className="container">
              <div className="interview_sec">
                <div>
                  <div className="title">
                    <h2>Your SSB mock personal interview feedback</h2>
                  </div>

                  <div className="test_instruction">
                    <ul>
                      <li>
                        <p>{feedbackData}</p>
                      </li>
                    </ul>
                    <div className="downLoad_btn">
                      <button onClick={() => handleDownload()}>
                        DownLoad Feedback Report
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default InterviewFeedback;
