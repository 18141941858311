import React, { useState } from "react";
import airspeedIndicator from "../../images/airspeedIndicator.png";
import artificialHorizon from "../../images/artificialHorizon.png";
import altimeter from "../../images/altimeter.png";
import headingIndicator from "../../images/headingIndicator.png";
import turnCoordinator from "../../images/turnCoordinator.png";
import verticalSpeedIndicator from "../../images/verticalSpeedIndicator.png";
import { useNavigate } from "react-router-dom";

const FlightSimulation = () => {
  const [currentSlider, setCurrentSlider] = useState(1);
  const [isInputCheck, setIsInputCheck] = useState(false);

  const handleNext = () => {
    setCurrentSlider(currentSlider + 1);
  };

  const handlePrev = () => {
    setCurrentSlider(currentSlider - 1);
  };

  const startFlightSimulationTest = () => {
    window.open("https://www.geo-fs.com/geofs.php?v=3.9", "_blank");
  }

  return (
    <div className="testContent">
      {currentSlider === 1 && (
        <div className="airspeedIndicator">
          <div className="title">Airspeed Indicator</div>
          <p>
            An <b>airspeed indicator (ASI)</b> is an essential instrument in an
            airplane that measures how fast the aircraft is moving through the
            air. It’s very similar to a car’s speedometer, but instead of
            showing how fast you're moving on the road, it shows how fast the
            plane is moving through the air.
          </p>
          <img
            className="relImg"
            src={airspeedIndicator}
            alt="Airspeed Indicator"
          />
          <p className="subTitle">How It Works:</p>
          <p>
            The airspeed indicator uses a simple device called a pitot tube to
            measure the speed of air moving against the aircraft. Here’s a
            breakdown of the key components:
          </p>
          <ol>
            <li>
              <b>Pitot Tube:</b>
              <ul>
                <li>
                  The pitot tube is mounted on the outside of the plane,
                  typically on a wing or near the nose, where it’s exposed to
                  the airflow.
                </li>
                <li>
                  It collects the air pressure from the moving air that strikes
                  it as the plane flies. This is called{" "}
                  <b>pitot pressure or total pressure</b>.
                </li>
              </ul>
            </li>
            <li>
              <b>Static Port:</b>
              <ul>
                <li>
                  At the same time, a <b>static port</b> measures the
                  surrounding air pressure, which is unaffected by the plane's
                  movement. This is referred to as <b>static pressure</b>.
                </li>
              </ul>
            </li>
            <li>
              <b>Differential Pressure:</b>
              <ul>
                <li>
                  The airspeed indicator works by calculating the difference
                  between the pitot pressure (from the pitot tube) and the
                  static pressure (from the static port). This difference is
                  what determines the airspeed, which is then shown on the
                  airspeed indicator.
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">Airspeed Display:</p>
          <ul>
            <li>
              The airspeed indicator usually has a <b>circular dial</b> with a
              needle that moves to show the speed. The airspeed is displayed in{" "}
              <b>knots</b>, which is a common measurement in aviation (1 knot =
              1 nautical mile per hour or approximately 1.15 miles per hour).
            </li>
            <li>
              For example, a popular small aircraft like the <b>Cessna 172</b>{" "}
              has an airspeed indicator that ranges from 0 to 200 knots.
            </li>
          </ul>
          <p className="subTitle">Color-Coded Sections:</p>
          <p>
            To make it easier for the pilot to see critical airspeeds, the
            airspeed indicator has different colored sections:
          </p>
          <ul>
            <li>
              <b>White arc:</b> This represents the <b>flap operating range</b>
              —the speeds at which it's safe to use the flaps. The bottom of the
              white arc is the <b>stall speed</b> with flaps extended (the
              slowest speed at which the aircraft can fly without losing lift),
              and the top is the <b>maximum flap extension speed</b>.
            </li>
            <li>
              <b>Green arc:</b> The green section starts at the{" "}
              <b>stall speed</b> without flaps extended, which is typically
              around <b>40 knots</b> for a Cessna 172, and goes up to the{" "}
              <b>maximum safe cruising speed</b>.
            </li>
            <li>
              <b>Yellow arc:</b> This range indicates speeds that are faster
              than the normal cruising speed. It’s still safe to fly in this
              range, but only in calm air. Flying in turbulent air at these
              speeds can damage the aircraft.
            </li>
            <li>
              <b>Red line:</b> This is the <b>never-exceed speed (Vne)</b>. It’s
              the absolute maximum speed the aircraft can safely fly. Going
              beyond this speed risks structural failure.
            </li>
          </ul>
          <p className="subTitle">Summary for Cessna 172:</p>
          <ul>
            <li>
              The <b>airspeed range</b> on the dial for a Cessna 172 is from 0
              to 200 knots.
            </li>
            <li>
              The <b>stall speed</b> (where the aircraft loses lift) is around{" "}
              <b>40 knots</b>, and from there, the needle moves through the{" "}
              <b>green arc</b>, which is the safe cruising speed range for the
              aircraft.
            </li>
          </ul>
          <p>
            The airspeed indicator helps pilots ensure they are flying at safe
            speeds depending on the conditions and maneuvers they are
            performing.
          </p>
        </div>
      )}

      {currentSlider === 2 && (
        <div className="artificialHorizon">
          <div className="title">Artificial Horizon</div>
          <p>
            An <b>artificial horizon</b> (also known as the{" "}
            <b>attitude indicator</b>) is one of the most crucial instruments in
            an airplane, used to give the pilot a clear sense of the aircraft's
            orientation relative to the earth. It provides two critical pieces
            of information:
          </p>
          <ul>
            <li>
              <b>Pitch</b> (whether the nose is tilted up or down relative to
              the horizon)
            </li>
            <li>
              <b>Roll</b> (whether the wings are level or tilted)
            </li>
          </ul>
          <img src={artificialHorizon} alt="Artificial Horizon" />
          <p className="subTitle">Components and How It Works:</p>
          <ol>
            <li>
              <b>Gyro-Controlled Mechanism:</b>
              <ul>
                <li>
                  In traditional, <b>analog artificial horizons</b>, the device
                  is <b>gyro-controlled</b>. This means that it uses a gyroscope
                  to maintain its orientation. A gyroscope is a spinning wheel
                  or disc that remains stable in space despite the movement of
                  the aircraft. The stability of the gyroscope allows the
                  instrument to provide accurate readings of the plane’s
                  attitude, even during maneuvers.
                </li>
                <li>
                  As the aircraft moves, the attitude indicator responds to
                  changes in roll (tilting from side to side) and pitch (tilting
                  nose up or nose down), while the gyroscope stays stable,
                  ensuring that the display reflects the plane’s attitude
                  relative to the real horizon.
                </li>
              </ul>
            </li>
            <li>
              <b>Rotating Disc/Globe:</b>
              <ul>
                <li>
                  The heart of the artificial horizon is a <b>rotating disc</b>{" "}
                  or globe, which is divided into two colored halves. These
                  colors help the pilot quickly understand the aircraft’s
                  orientation:
                  <ul>
                    <li>
                      <b>Blue:</b> Represents the <b>sky</b>.
                    </li>
                    <li>
                      <b>Brown:</b> Represents the <b>ground</b>.
                    </li>
                  </ul>
                </li>
                <li>
                  The dividing line between these two sections is a{" "}
                  <b>white line</b>, which serves as the <b>horizon</b>. This
                  white line visually mimics the real horizon the pilot would
                  see outside the cockpit, giving a reference point for flying.
                </li>
              </ul>
            </li>
            <li>
              <b>Pitch Indication:</b>
              <ul>
                <li>
                  <b>Pitch</b> refers to the up or down tilt of the aircraft's
                  nose relative to the horizon.
                </li>
                <li>
                  On the artificial horizon, when the plane's nose is pointing
                  upward (in a climb), the blue (sky) portion of the globe will
                  appear to move downward, showing more of the blue above the
                  horizon line. Conversely, if the aircraft is in a nose-down
                  attitude (descent), the blue sky area will move upward,
                  showing more brown (ground) below the horizon line.
                </li>
              </ul>
            </li>
            <li>
              <b>Roll Indication:</b>
              <ul>
                <li>
                  <b>Roll</b> is the side-to-side tilt of the aircraft (when the
                  wings are not level).
                </li>
                <li>
                  When the plane banks left or right (for example, during a
                  turn), the artificial horizon will tilt to show how far the
                  aircraft is rolling. The instrument's face typically has small{" "}
                  <b>tick marks</b> or <b>degree markings</b> around the edge of
                  the dial, allowing the pilot to see the exact angle of bank.
                </li>
                <li>
                  For instance, in a 30° bank to the right, the horizon line
                  will tilt downward on the right side, showing more blue (sky)
                  on the left and more brown (ground) on the right.
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">How It Helps the Pilot:</p>
          <p>
            The artificial horizon is extremely important, especially when{" "}
            <b>flying in poor visibility</b> conditions such as clouds, fog, or
            at night when the real horizon might not be visible. In these
            situations, the pilot cannot rely on visual cues from outside the
            aircraft to determine its attitude (orientation). The artificial
            horizon provides an accurate visual representation of the aircraft’s
            orientation relative to the horizon and helps the pilot keep the
            aircraft level or perform controlled maneuvers without external
            visual references.
          </p>
          <p className="subTitle">Summary:</p>
          <ul>
            <li>
              The <b>blue area</b> represents the <b>sky</b>, and the{" "}
              <b>brown area</b> represents the <b>ground</b>.
            </li>
            <li>
              The <b>white horizon line</b> divides the two and serves as the
              visual reference for the real horizon.
            </li>
            <li>
              The instrument shows the <b>pitch</b> (nose up or down) and{" "}
              <b>roll</b> (wings level or banked) of the aircraft by tilting and
              rotating the colored disc accordingly.
            </li>
            <li>
              It uses a <b>gyro-controlled mechanism</b> to ensure accuracy and
              stability, especially during complex maneuvers or low-visibility
              flying conditions.
            </li>
          </ul>
          <p>
            In essence, the artificial horizon acts as the pilot’s reference to
            the earth's horizon, even when the real one is not visible, ensuring
            the pilot can maintain control of the aircraft’s attitude.
          </p>
        </div>
      )}

      {currentSlider === 3 && (
        <div className="altimeter">
          <div className="title">Altimeter</div>
          <p>
            An <b>altimeter</b> is a critical instrument in an aircraft that
            provides information about the aircraft's altitude, or its height
            above a certain reference point, usually above <b>sea level</b> or{" "}
            <b>ground level</b>. Altitude is crucial for pilots to ensure safe
            flight and to avoid obstacles and other aircraft.
          </p>
          <img src={altimeter} alt="Altimeter" />
          <p className="subTitle">How an Altimeter Works:</p>
          <p>
            The description you provided suggests the use of a{" "}
            <b>radio altimeter</b>, which measures the height of the aircraft
            relative to the ground by using <b>radio wave reflection</b>.
            However, it's important to distinguish between two types of
            altimeters:
          </p>
          <ol>
            <li>
              <b>Barometric Altimeter (common in most aircraft):</b> This type
              measures the altitude by sensing changes in air pressure.
            </li>
            <li>
              <b>Radio Altimeter:</b> This measures the exact height above the
              ground using radio waves, typically used during landing to know
              the precise distance from the ground.
            </li>
          </ol>
          <p>
            In this case, we will focus on a{" "}
            <b>traditional barometric altimeter</b> since the description of the
            needles and dials aligns with this type of device.
          </p>
          <p className="subTitle">Barometric Altimeter:</p>
          <ul>
            <li>
              <b>How it works:</b> It measures altitude based on changes in
              atmospheric pressure. The higher the altitude, the lower the air
              pressure. The altimeter is calibrated to translate air pressure
              changes into altitude readings.
            </li>
            <li>
              <b>Pressure-sensitive mechanism:</b> Inside the altimeter, there’s
              a device called an aneroid barometer that expands or contracts
              depending on the air pressure. The changes in air pressure are
              then translated into altitude readings, which are displayed on the
              dial.
            </li>
          </ul>
          <p className="subTitle">Understanding the Altimeter Dial:</p>
          <p>
            An <b>analogue altimeter</b> looks similar to a clock and uses two
            or sometimes three <b>hands</b> (needles) to show the aircraft’s
            altitude.
          </p>
          <ol>
            <li>
              <b>Needles (Hour and Minute Hands)</b>
              <ul>
                <li>
                  Like a clock, the altimeter has an <b>hour hand</b> and a{" "}
                  <b>minute hand</b>.
                </li>
                <li>
                  These hands don’t represent time; instead, they represent
                  altitude
                  <ul>
                    <li>
                      The <b>hour hand</b> indicates altitude in thousands of
                      feet.
                    </li>
                    <li>
                      The <b>minute hand</b> represents altitude in{" "}
                      <b>hundreds of feet</b>.
                    </li>
                    <li>
                      There can also be a third, thinner hand, often used to
                      indicate altitude in tens of thousands of feet, especially
                      in high-altitude aircraft.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <b>Rotations:</b>
              <ul>
                <li>
                  Unlike a clock, the hands of an altimeter rotate in an{" "}
                  <b>anticlockwise</b> direction (counterclockwise).
                </li>
                <li>
                  The dial is numbered from <b>0 to 10</b> (representing
                  thousands of feet).
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">Reading the Altimeter:</p>
          <p>
            Reading an altimeter is similar to reading an analogue clock, but
            with a slight difference in interpretation. Here’s a step-by-step
            guide to interpreting the altitude:
          </p>
          <ol>
            <li>
              <b>Minute Hand:</b>
              <ul>
                <li>
                  The minute hand shows altitude in <b>hundreds of feet</b>.
                </li>
                <li>
                  Multiply the reading of the minute hand by <b>100 feet</b>.
                </li>
                <li>
                  For example, if the minute hand points to "6", it means{" "}
                  <b>600 feet</b>.
                </li>
              </ul>
            </li>
            <li>
              <b>Hour Hand:</b>
              <ul>
                <li>
                  The hour hand shows altitude in <b>thousands of feet</b>.
                </li>
                <li>
                  Multiply the reading of the hour hand by <b>1000 feet</b>.
                </li>
                <li>
                  For example, if the hour hand points to "3", it means{" "}
                  <b>3000 feet</b>.
                </li>
              </ul>
            </li>
            <li>
              <b>Adding the Two Readings:</b>
              <ul>
                <li>
                  The final altitude is obtained by adding the readings from the
                  minute hand and the hour hand.
                </li>
                <li>
                  For instance, if the minute hand is at "6" and the hour hand
                  is at "3", the altitude would be:
                  <ul>
                    <li>
                      <b>
                        Minute hand (600 feet) + Hour hand (3000 feet) = 3600
                        feet.
                      </b>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <b>Third Hand</b> (If Present):
              <ul>
                <li>
                  Some altimeters have a third, very thin hand to represent tens
                  of thousands of feet. If this hand is present, it will show
                  altitude in multiples of 10,000 feet, and its reading should
                  be added to the result from the minute and hour hands.
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">Example:</p>
          <p>Let’s say you’re looking at an altimeter where:</p>
          <ul>
            <li>The minute hand (or the longer needle) is pointing at "5".</li>
            <li>The hour hand (or the shorter needle) is pointing at "2".</li>
          </ul>
          <p>Here’s how you would calculate the altitude:</p>
          <ul>
            <li>
              <b>Minute hand:</b> 5 × 100 feet = <b>500 feet</b>
            </li>
            <li>
              <b>Hour hand:</b> 2 × 1000 feet = <b>2000 feet</b>
            </li>
            <li>
              <b>Total Altitude:</b> 2000 feet + 500 feet = <b>2500 feet</b>
            </li>
          </ul>
          <p className="subTitle">Why It’s Important:</p>
          <ul>
            <li>
              The altimeter helps pilots maintain a safe altitude by providing
              constant feedback on how high the plane is flying.
            </li>
            <li>
              It's especially useful for avoiding obstacles like mountains and
              keeping a safe distance from other aircraft.
            </li>
            <li>
              During <b>takeoff</b> and <b>landing</b>, an altimeter becomes
              critical to ensuring safe ascent and descent.
            </li>
          </ul>
          <p>
            In summary, while reading an altimeter is as simple as reading a
            clock, you need to apply the right multipliers to the hour and
            minute hands to get an accurate reading of the altitude.
          </p>
        </div>
      )}

      {currentSlider === 4 && (
        <div className="headingIndicator">
          <div className="title">Heading Indicator</div>
          <p>
            A <b>heading indicator</b> (also known as a <b>directional gyro</b>{" "}
            or DG) is an essential navigational instrument in an aircraft that
            shows the direction, or <b>heading</b>, in which the aircraft is
            flying. While it functions similarly to a compass, it is more stable
            and reliable in flight than a traditional magnetic compass,
            especially during turns and maneuvers.
          </p>
          <img src={headingIndicator} alt="Heading Indicator" />
          <p className="subTitle">How the Heading Indicator Works:</p>
          <ol>
            <li>
              <b>Gyro-Based Mechanism:</b>
              <ul>
                <li>
                  The heading indicator is <b>gyro-controlled</b>, meaning it
                  uses a <b>gyroscope</b> to maintain its orientation. This
                  helps overcome the limitations of a traditional magnetic
                  compass, which can be inaccurate when the aircraft is
                  accelerating, turning, or experiencing turbulence.
                </li>
                <li>
                  The <b>gyroscope</b> in the instrument is mounted in such a
                  way that it spins and remains stable relative to the Earth's
                  surface, even as the aircraft moves. This stability allows the
                  heading indicator to show the aircraft's heading (direction)
                  more accurately than a magnetic compass.
                </li>
              </ul>
            </li>
            <li>
              <b>Degrees of Direction:</b>
              <ul>
                <li>
                  The heading indicator divides the compass into{" "}
                  <b>360 degrees</b>, representing all possible directions.
                </li>
                <li>
                  Specific cardinal directions are assigned numerical values:
                  <ul>
                    <li>
                      <b>0 degrees (or 360 degrees) = North.</b>
                    </li>
                    <li>
                      <b>90 degrees = East.</b>
                    </li>
                    <li>
                      <b>180 degrees = South.</b>
                    </li>
                    <li>
                      <b>270 degrees = West.</b>
                    </li>
                  </ul>
                </li>
                <li>
                  Any direction between these main points is given a degree
                  value. For example:
                  <ul>
                    <li>
                      <b>45 degrees</b> would be <b>northeast</b> (halfway
                      between north and east).
                    </li>
                    <li>
                      <b>135 degrees</b> would be <b>southeast</b> (halfway
                      between east and south).
                    </li>
                    <li>
                      <b>225 degrees</b> would be <b>southwest</b>.
                    </li>
                    <li>
                      <b>315 degrees</b> would be <b>northwest</b>.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <b>Heading:</b>
              <ul>
                <li>
                  The <b>heading</b> is the direction in which the aircraft is
                  flying, expressed in degrees from 0 to 360.
                </li>
                <li>
                  The aircraft's heading is shown on the indicator by a{" "}
                  <b>needle</b> or marker at the top of the instrument, pointing
                  to the current heading.
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">Reading the Heading Indicator:</p>
          <ol>
            <li>
              <b>Directional Numbers:</b>
              <ul>
                <li>
                  Around the edge of the heading indicator’s dial, numbers
                  representing <b>degrees</b> are printed, typically in
                  increments of <b>10 or 30 degrees</b> (e.g., 30, 60, 90,
                  etc.).
                </li>
                <li>
                  The <b>cardinal directions</b> (N, E, S, W) are often marked
                  for reference.
                </li>
              </ul>
            </li>
            <li>
              <b>Needle or Marker:</b>
              <ul>
                <li>
                  The heading is read by looking at the <b>needle</b> (or white
                  marker) at the top center of the heading indicator.
                </li>
                <li>
                  Whatever degree the needle points to is the current heading of
                  the aircraft.
                </li>
              </ul>
            </li>
            <li>
              <b>For example:</b>
              <ul>
                <li>
                  If the needle is pointing to <b>90 degrees</b>, the aircraft
                  is heading <b>east</b>.
                </li>
                <li>
                  If the needle is pointing to <b>180 degrees</b>, the aircraft
                  is heading <b>south</b>.
                </li>
              </ul>
            </li>
            <li>
              <b>Any Angle Between 0 and 360 Degrees:</b>
              <ul>
                <li>
                  The heading indicator can show any heading between 0 and 360
                  degrees. So, the heading could be something like{" "}
                  <b>270 degrees</b> (west) or <b>350 degrees</b> (just 10
                  degrees west of north).
                </li>
                <li>
                  A heading of <b>360 degrees</b> or <b>0 degrees</b> represents{" "}
                  <b>true north</b>.
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">Limitations and Manual Adjustment:</p>
          <p>
            While a heading indicator is more stable than a magnetic compass, it
            does have a limitation: the{" "}
            <b>gyroscope tends to drift over time</b>. This drift means that the
            instrument may become inaccurate after a period of flight, even
            though it remains more reliable during turns and maneuvers.
          </p>
          <p>
            To maintain accuracy, the heading indicator needs to be{" "}
            <b>periodically reset</b> by referencing the magnetic compass.
            During steady flight, the pilot can adjust the heading indicator to
            match the magnetic compass reading, correcting for any drift in the
            gyroscope.
          </p>
          <p className="subTitle">Example of Using the Heading Indicator:</p>
          <ol>
            <li>
              Suppose a pilot is flying and the heading indicator shows the
              needle pointing at <b>45 degrees</b>. This means the aircraft is
              flying <b>northeast</b>.
            </li>
            <li>
              If the needle points at <b>225 degrees</b>, the aircraft is
              heading <b>southwest</b>.
            </li>
            <li>
              During a turn, the pilot can watch the needle smoothly rotate to
              the new heading, such as from <b>90 degrees (east)</b> to{" "}
              <b>180 degrees (south)</b>.
            </li>
          </ol>
          <p className="subTitle">Summary:</p>
          <ul>
            <li>
              The <b>heading indicator</b> shows the direction (or heading) the
              aircraft is traveling in.
            </li>
            <li>
              The heading is displayed as a <b>degree</b> between{" "}
              <b>0 and 360</b>, with cardinal points marked at:
              <ul>
                <li>
                  <b>0/360 degrees</b> = North
                </li>
                <li>
                  <b>90 degrees</b> = East
                </li>
                <li>
                  <b>180 degrees</b> = South
                </li>
                <li>
                  <b>270 degrees</b> = West
                </li>
              </ul>
            </li>
            <li>
              The aircraft’s heading is determined by reading the{" "}
              <b>degree value</b> at the <b>needle’s tip</b> at the top of the
              dial.
            </li>
            <li>
              Unlike a traditional compass, the heading indicator is{" "}
              <b>gyro-controlled</b>, making it more stable and reliable during
              flight but requiring periodic adjustment to compensate for gyro
              drift.
            </li>
          </ul>
          <p>
            In essence, the heading indicator is a key tool for keeping the
            aircraft on course and for making precise navigational adjustments
            during flight.
          </p>
        </div>
      )}

      {currentSlider === 5 && (
        <div className="turnCoordinator">
          <div className="title">Turn Coordinator</div>
          <p>
            A <b>turn coordinator</b> is an important instrument in an aircraft
            that helps pilots monitor and control the bank angle (the tilt of
            the wings) during a turn. It provides information about the
            aircraft's rate of turn and helps ensure a coordinated turn, where
            the aircraft turns smoothly without skidding or slipping.
          </p>
          <img src={turnCoordinator} alt="Turn Coordinator" />
          <p className="subTitle">How the Turn Coordinator Works:</p>
          <ol>
            <li>
              <b>Purpose:</b>
              <ul>
                <li>
                  The primary function of the turn coordinator is to display the{" "}
                  <b>bank angle</b> of the aircraft’s wings (the tilt of the
                  aircraft to the left or right), which is important for
                  executing smooth and coordinated turns.
                </li>
                <li>
                  Additionally, it shows the <b>rate of turn</b>, which
                  indicates how quickly the aircraft is turning. This helps the
                  pilot maintain a standard rate of turn, crucial for
                  navigation.
                </li>
              </ul>
            </li>
            <li>
              <b>Gyroscopic Mechanism:</b>
              <ul>
                <li>
                  The turn coordinator is <b>gyro-based</b>. Inside the
                  instrument, a gyroscope is mounted at an angle to detect both
                  roll (tilting left or right) and yaw (rotation around the
                  vertical axis of the aircraft).
                </li>
                <li>
                  This allows the turn coordinator to not only measure the bank
                  angle but also give information on how fast the aircraft is
                  turning.
                </li>
              </ul>
            </li>
            <li>
              <b>Display:</b>
              <ul>
                <li>
                  The turn coordinator has a small picture of an <b>airplane</b>{" "}
                  at its center, which represents the aircraft the pilot is
                  flying.
                </li>
                <li>
                  As the aircraft banks left or right, the{" "}
                  <b>miniature airplane</b> in the turn coordinator tilts
                  correspondingly. This makes it easy for the pilot to visualize
                  the aircraft’s bank angle.
                </li>
              </ul>
            </li>
            <li>
              <b>Bank Angle:</b>
              <ul>
                <li>
                  The <b>bank angle</b> refers to how much the aircraft’s wings
                  are tilted relative to the horizon during a turn.
                </li>
                <li>
                  When the aircraft rolls into a left turn, the miniature
                  airplane will tilt to the left. Similarly, in a right turn,
                  the miniature airplane will tilt to the right.
                </li>
                <li>
                  This visual cue lets the pilot know whether they are making a
                  shallow, medium, or steep turn.
                </li>
              </ul>
            </li>
            <li>
              <b>Rate of Turn:</b>
              <ul>
                <li>
                  The turn coordinator also shows the <b>rate of turn</b>,
                  usually indicating a <b>standard rate turn</b>. A standard
                  rate turn means the aircraft is turning at 3 degrees per
                  second, which will result in a full 360-degree turn in 2
                  minutes.
                </li>
                <li>
                  The dial on the turn coordinator typically has two marks on
                  each side, labeled as <b>"Standard Turn"</b>. When the
                  miniature airplane aligns with one of these marks, it
                  indicates the aircraft is making a standard rate turn.
                </li>
              </ul>
            </li>
            <li>
              <b>Coordinated Turn Indicator (Slip/Skid Ball):</b>
              <ul>
                <li>
                  Below the miniature airplane, the turn coordinator also
                  features a <b>ball in a curved tube</b>, called the{" "}
                  <b>inclinometer</b> or <b>slip/skid indicator</b>.
                </li>
                <li>
                  This ball shows whether the turn is <b>coordinated</b>,
                  meaning that the aircraft is turning smoothly without skidding
                  or slipping.
                  <ul>
                    <li>
                      If the ball is centered, the turn is properly coordinated.
                    </li>
                    <li>
                      If the ball moves to the outside of the turn, it indicates
                      the aircraft is <b>skidding</b> (the nose is moving too
                      quickly for the bank angle).
                    </li>
                    <li>
                      If the ball moves to the inside of the turn, it indicates
                      the aircraft is <b>slipping</b> (the nose is moving too
                      slowly for the bank angle).
                    </li>
                  </ul>
                </li>
                <li>
                  To correct a skid or slip, the pilot adjusts the use of the
                  rudder pedals to bring the ball back to the center, ensuring
                  the aircraft turns smoothly.
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">
            Key Information Provided by the Turn Coordinator:
          </p>
          <ol>
            <li>
              <b>Bank Angle (Wings' Tilt):</b>
              <ul>
                <li>
                  The turn coordinator’s miniature airplane tilts in the
                  direction the aircraft is banking, helping the pilot see the
                  wings’ angle relative to the horizontal plane.
                </li>
                <li>
                  This helps the pilot avoid overbanking or underbanking during
                  a turn.
                </li>
              </ul>
            </li>
            <li>
              <b>Rate of Turn:</b>
              <ul>
                <li>
                  The markings on the instrument indicate if the aircraft is
                  turning at a standard rate (3 degrees per second). This is
                  particularly important for instrument flight rules (IFR)
                  flying, where pilots rely on instruments to navigate in poor
                  visibility.
                </li>
              </ul>
            </li>
            <li>
              <b>Coordination of the Turn (Slip or Skid):</b>
              <ul>
                <li>
                  The <b>slip/skid ball</b> shows whether the turn is properly
                  coordinated or if the aircraft is slipping or skidding.
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">How Pilots Use the Turn Coordinator:</p>
          <ol>
            <li>
              <b>During Turns:</b>
              <ul>
                <li>
                  Pilots use the turn coordinator to maintain a standard rate
                  turn by keeping the miniature airplane aligned with the{" "}
                  <b>standard turn</b> marking on the dial.
                </li>
                <li>
                  The <b>bank angle</b> is also monitored visually, ensuring the
                  wings are tilted at the correct angle for the intended turn.
                </li>
              </ul>
            </li>
            <li>
              <b>Ensuring a Coordinated Turn:</b>
              <ul>
                <li>
                  While turning, the pilot monitors the <b>ball</b> in the
                  inclinometer. If the ball is not centered, the pilot uses
                  rudder control to adjust the coordination of the turn,
                  ensuring a smooth and balanced maneuver.
                </li>
              </ul>
            </li>
            <li>
              <b>Recovery from a Turn:</b>
              <ul>
                <li>
                  After completing the turn, the pilot uses the turn coordinator
                  to level the wings and return the aircraft to straight and
                  level flight.
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">Summary:</p>
          <ul>
            <li>
              The <b>turn coordinator</b> shows both the <b>bank angle</b> of
              the aircraft (the tilt of the wings during a turn) and the{" "}
              <b>rate of turn</b>.
            </li>
            <li>
              The <b>miniature airplane</b> in the instrument mimics the actual
              bank angle of the aircraft, allowing the pilot to visualize the
              turn.
            </li>
            <li>
              The <b>slip/skid ball</b> shows whether the turn is coordinated,
              ensuring the aircraft is turning smoothly without slipping or
              skidding.
            </li>
            <li>
              This instrument is crucial for maintaining smooth, controlled
              turns, especially in conditions where external visual references
              (like the horizon) are not available.
            </li>
          </ul>
          <p>
            In essence, the turn coordinator is a key tool for ensuring that
            turns are performed accurately and safely, helping the pilot
            maintain control of the aircraft's orientation during flight.
          </p>
        </div>
      )}

      {currentSlider === 6 && (
        <div className="verticalSpeedIndicator">
          <div className="title">Vertical Speed Indicator</div>
          <p>
            The <b>Vertical Speed Indicator</b> (VSI), also known as the{" "}
            <b>variometer</b> or
            <b>rate of climb/descent indicator</b>, is an essential flight
            instrument that tells the pilot how quickly the aircraft is either
            climbing (ascending) or descending. It provides information in terms
            of the
            <b>rate of ascent or descent</b>, typically measured in{" "}
            <b>feet per minute (fpm)</b>, not feet per second as mentioned.
          </p>
          <img src={verticalSpeedIndicator} alt="vertical Speed Indicator" />
          <p className="subTitle">How the VSI Works:</p>
          <ol>
            <li>
              <b>Principle of Operation:</b>
              <ul>
                <li>
                  The VSI measures the{" "}
                  <b>rate of change in static air pressure</b> outside the
                  aircraft. When the aircraft climbs, the outside air pressure
                  decreases; when the aircraft descends, the pressure increases.
                </li>
                <li>
                  Inside the VSI is a small, airtight <b>capsule</b> connected
                  to the aircraft's <b>static pressure system</b> (a system that
                  senses the air pressure outside the aircraft). Changes in
                  altitude cause the pressure inside the capsule to either
                  expand or contract.
                </li>
                <li>
                  A mechanical system connected to this capsule converts the
                  pressure changes into needle movements on the instrument’s
                  display, providing a reading in <b>feet per minute</b> (fpm)
                  that shows how fast the aircraft is climbing or descending.
                </li>
              </ul>
            </li>
          </ol>
          <p className="subtitle">Reading the VSI Dial:</p>
          <ol>
            <li>
              <b>Zero Position:</b>
              <ul>
                <li>
                  The <b>9 o’clock position</b> on the dial (similar to a
                  conventional clock) represents <b>zero</b> rate of climb or
                  descent. This means the aircraft is flying in level
                  flight—neither ascending nor descending.
                </li>
                <li>
                  If the needle points to this position, the aircraft is
                  maintaining altitude.
                </li>
              </ul>
            </li>
            <li>
              <b>Climbing:</b>
              <ul>
                <li>
                  When the aircraft <b>ascends</b>, the needle moves{" "}
                  <b>clockwise</b> from the zero position.
                </li>
                <li>
                  The higher the needle goes, the faster the rate of climb.
                </li>
                <li>
                  Typical values on the dial might range from <b>0 to 2000</b>{" "}
                  fpm, or in some cases higher, indicating the rate at which the
                  aircraft is climbing. For example:
                  <ul>
                    <li>
                      If the needle is pointing to <b>500</b>, the aircraft is
                      climbing at a rate of <b>500 feet per minute</b>.
                    </li>
                    <li>
                      If the needle points to <b>1000</b>, the aircraft is
                      ascending at <b>1000 feet per minute</b>.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <b>Descending:</b>
              <ul>
                <li>
                  When the aircraft <b>descends</b>, the needle moves{" "}
                  <b>counterclockwise</b> from the zero position.
                </li>
                <li>
                  The farther the needle moves counterclockwise, the faster the
                  rate of descent.
                </li>
                <li>
                  Just like for climbing, typical values might range from{" "}
                  <b>0 to -2000 fpm</b> or higher. For example:
                  <ul>
                    <li>
                      If the needle is pointing to <b>-500</b>{" "}
                      (counterclockwise), the aircraft is descending at{" "}
                      <b>500 feet per minute</b>.
                    </li>
                    <li>
                      If the needle points to <b>-1000</b>, the aircraft is
                      descending at <b>1000 feet per minute</b>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <p className="subTitle">Needle Movements:</p>
          <ul>
            <li>
              <b>Clockwise</b> movement of the needle from the zero position
              indicates the aircraft is climbing (positive vertical speed).
            </li>
            <li>
              <b>Counterclockwise</b> movement of the needle from the zero
              position indicates the aircraft is descending (negative vertical
              speed).
            </li>
          </ul>
          <p className="subTitle">Example Readings:</p>
          <ol>
            <li>
              If the VSI needle is pointing at <b>+500</b> (clockwise), the
              aircraft is climbing at a rate of <b>500 feet per minute</b>.
            </li>
            <li>
              If the needle is pointing at <b>-1000</b> (counterclockwise), the
              aircraft is descending at a rate of <b>1000 feet per minute</b>.
            </li>
            <li>
              If the needle is on the <b>zero</b> mark at the 9 o’clock
              position, the aircraft is in <b>level flight</b> (no change in
              altitude).
            </li>
          </ol>
          <p className="subTitle">Response Time of the VSI:</p>
          <ul>
            <li>
              The VSI doesn’t provide instantaneous feedback. There’s typically
              a <b>lag</b> of a few seconds before the VSI displays the correct
              rate of climb or descent. This lag happens because the instrument
              takes time to detect and display the change in air pressure as the
              aircraft changes altitude.
            </li>
            <li>
              Pilots need to be aware of this delay, especially during precise
              maneuvers, and may need to use other instruments to confirm
              altitude changes quickly.
            </li>
          </ul>
          <p className="subTitle">Summary of Key Points:</p>
          <ul>
            <li>
              The VSI shows the <b>rate of ascent or descent</b> in{" "}
              <b>feet per minute</b> (fpm), which helps the pilot know how
              quickly the aircraft is climbing or descending.
            </li>
            <li>
              The <b>9 o’clock position</b> on the dial represents{" "}
              <b>level flight</b> (zero rate of climb or descent).
            </li>
            <li>
              The <b>needle moves clockwise</b> from the zero position to show
              climbing and <b>counterclockwise</b> to show descending.
            </li>
            <li>
              It helps pilots maintain control during ascent and descent, giving
              them the information needed for smooth and safe altitude changes.
            </li>
          </ul>
          <p>
            In essence, the VSI is a critical tool for ensuring that the
            aircraft climbs and descends at safe and controlled rates,
            especially during takeoffs, landings, and altitude adjustments.
          </p>
        </div>
      )}

      <div className="skipCheckbox">
        <input
          onChange={(e) => setIsInputCheck(e.target.checked)}
          type="checkbox"
          name="skipCheckBox"
          id="skipCheckBox"
        />{" "}
        <label htmlFor="skipCheckBox">skip and never show me again</label>
      </div>
      <div className="sliderButtonGrp">
        {!isInputCheck && (
          <>
            {currentSlider !== 1 && <button onClick={handlePrev}>Prev</button>}
            {currentSlider !== 6 && <button onClick={handleNext}>Next</button>}
          </>
        )}
        {(currentSlider === 6 || isInputCheck)  && <button onClick={()=> startFlightSimulationTest()}>Start Flight Simulation</button>}
      </div>
    </div>
  );
};

export default FlightSimulation;
