import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { burpee } from "../../../assets/images";

const BurpeeGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Burpees the Right Way</h2>
          <p>
            Burpees are a full-body, high-intensity exercise that combines
            strength and cardio. They target multiple muscle groups, including
            the chest, arms, legs, and core, and are known for their ability to
            quickly elevate your heart rate. Here’s how to perform burpees with
            proper form for maximum benefits.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/G2hv_NYhM-A"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Start in a Standing Position</h4>

          <span>
            1. <strong> Stand Tall with Feet Hip-Width Apart: </strong>
            Stand with your feet about hip-width apart, arms relaxed by your
            sides.
          </span>

          <span>
            2. <strong> Engage Your Core: </strong>
            Keep your core tight and your body upright to prepare for the
            movement.
          </span>

          <img src={burpee} alt="burpee" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Rise Up onto Your Toes</h4>
          <span>
            1. <strong> Lift Your Heels: </strong>
            Slowly raise your heels off the ground, shifting your weight onto
            the balls of your feet. Try to reach the highest point you can
            without losing balance.
          </span>
          <span>
            2. <strong> Squeeze Your Calves: </strong> Focus on squeezing your
            calf muscles at the top of the movement.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Lower Back Down</h4>
          <span>
            1. <strong> Control the Descent: </strong>
            Slowly lower your heels back to the ground. Avoid dropping them too
            quickly; control is key to maximizing the benefit.
          </span>

          <span>
            2. <strong>Repeat:</strong>
            Perform the movement for the desired number of reps, maintaining
            steady breathing and control.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Leaning Forward or Backward:</strong> Keep your body
            upright and avoid using momentum by leaning.
          </span>
          <span>
            {"b)."} <strong> Dropping Heels Too Fast: </strong> Lower your heels
            slowly to keep the calf muscles engaged throughout the movement.
          </span>
          <span>
            {"c)."} <strong>Not Using Full Range of Motion: </strong>
            Not Using Full Range of Motion: Rise up as high as possible on your
            toes and lower your heels fully for maximum benefit.
          </span>
          <br />
          <h4 className="subHeading">Variations for Added Intensity</h4>
          <span>
            {"a)."} <strong>Single-Leg Calf Raise:</strong> Perform the movement
            on one leg to increase the difficulty and further engage your core.
          </span>
          <span>
            {"b)."} <strong>Weighted Calf Raise: </strong> Hold a dumbbell in
            each hand or use a weighted barbell to add resistance.
          </span>

          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default BurpeeGuidance;
