import React, { useEffect, useState } from "react";
import "../../styles/gptSidebar.scss";
// import PushupTracker from "./PushupTracker";
import { cycling, training, yoga } from "../../assets/images";
import { FaPause, FaPlay, FaQuestionCircle } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import PopupPrompt from "../reusable/PopupPrompt";
import { IoCloseSharp, IoInformationCircleOutline } from "react-icons/io5";
import study_music from "../../audio/study_music.mp3";
import workout_music from "../../audio/workout_music.mp3";

const GptSidebar = ({ showExerciseBar }) => {
  const navigate = useNavigate();
  const [activity, setActivity] = useState("exercise");
  const [exerciseCategory, setExerciseCategory] = useState("");
  // const [pushupCount, setPushupCount] = useState(0);
  // const [exerciseStart, setExerciseStart] = useState(false);
  const [otherExercise, setOtherExercise] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");

  useEffect(() => {
    if (query) {
      setOtherExercise(true);
    }
  }, [query]);

  const handleActivity = (type) => {
    if (activity === type) {
      setActivity("");
      setExerciseCategory("");
    } else {
      setActivity(type);
    }
  };
  const handleExerciseCategory = (category) => {
    if (exerciseCategory === category) {
      setExerciseCategory("");
    } else {
      setExerciseCategory(category);
      navigate(`/exercise?type=${category}`);
    }
  };

  const handleNavigate = () => {
    navigate("/binaural-music");
  };

  //   const appLinks = `
  // Unlock this feature by downloading our app!
  // Get started today by downloading the
  // <a href="YOUR_PLAYSTORE_LINK" target="_blank">Android App</a> or
  // <a href="YOUR_APPSTORE_LINK" target="_blank">iOS App</a>
  // and take your preparation to the next level!
  // `;

  const exerciseList = [
    { name: "Pushups", url: "/about-push-ups" },
    { name: "Squats", url: "/about-body-squats" },
    {
      name: "Reverse Lunges",
      url: "/about-reverse-lunges",
    },
    { name: "Lunge", url: "/about-lunge" },
    { name: "Tricep dips", url: "/about-tricep-dips" },
    {
      name: "Mountain Climbers",

      url: "/about-mountain-climber",
    },
    { name: "Burpee", url: "/about-burpee" },
    { name: "Calf raises", url: "/about-calf-raises" },
    { name: "Knee push-ups", url: "/about-knee-push-ups" },
    { name: "Crunch", url: "/about-crunch" },
    { name: "Glute bridge", url: "/about-glute-bridge" },
    { name: "Jumping jack", url: "/about-jumping-jack" },
    { name: "Leg raises", url: "/about-leg-raises" },
    { name: "Step-up", url: "/about-step-up" },
    { name: "Bodyweight Squat", url: "/about-body-squat" },
    {
      name: "Bicycle crunches",

      url: "/about-bicycle-crunches",
    },
    { name: "Wall sit", url: "/about-wall-sit" },
    { name: "Arm Circles", url: "/about-arm-circles" },
    { name: "Bear Crawl", url: "/about-bear-crawl" },
    { name: "Pull-ups", url: "/about-pull-ups" },
  ];

  const [studyMusicPlaying, setStudyMusicPlaying] = useState(false);
  const [studyMusic] = useState(new Audio(study_music));

  const [workoutMusicPlaying, setWorkoutMusicPlaying] = useState(false);
  const [workoutMusic] = useState(new Audio(workout_music));

  // START AND STOP STUDY MUSIC
  const handleStudyMusic = () => {
    if (studyMusicPlaying) {
      studyMusic.pause();
    } else if (workoutMusicPlaying) {
      workoutMusic.pause();
      setWorkoutMusicPlaying(false);
      studyMusic.play();
    } else {
      studyMusic.play();
    }
    setStudyMusicPlaying(!studyMusicPlaying);
  };

  useEffect(() => {
    return () => {
      studyMusic.pause();
      studyMusic.src = "";
    };
  }, [studyMusic]);

  // START AND STOP WORKOUT MUSIC
  const handleWorkoutMusic = () => {
    if (workoutMusicPlaying) {
      workoutMusic.pause();
    } else if (studyMusicPlaying) {
      studyMusic.pause();
      setStudyMusicPlaying(false);
      workoutMusic.play();
    } else {
      workoutMusic.play();
    }
    setWorkoutMusicPlaying(!workoutMusicPlaying);
  };

  useEffect(() => {
    return () => {
      workoutMusic.pause();
      workoutMusic.src = "";
    };
  }, [workoutMusic]);

  const handleOtherExercise = () => {
    setOtherExercise(false);
    setActivity("exercise");
    setExerciseCategory("");
  };

  const [exerciseData, setExerciseData] = useState([]);

  // Fetch stored exercise data from localStorage on mount
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("exerciseData")) || [];
    setExerciseData(data);
  }, []);

  return (
    <section className={`gpt_sidebar ${showExerciseBar ? "active" : ""}`}>
      <div className="top_bar">
        <ul>
          <li>
            <button
              onClick={() => handleActivity("training")}
              className={activity === "training" ? "active" : ""}
            >
              <img src={training} alt="training" />
            </button>
          </li>
          <li>
            <button
              onClick={() => handleActivity("cycling")}
              className={activity === "cycling" ? "active" : ""}
            >
              <img src={cycling} alt="cycling" />
            </button>
          </li>
          <li>
            <button
              title="Push-Ups"
              className={activity === "exercise" ? "active" : ""}
              onClick={() => handleActivity("exercise")}
              // disabled={activity === "exercise"}
            >
              <img src={yoga} alt="yoga" />
            </button>
          </li>
        </ul>

        {activity === "exercise" &&
          exerciseCategory === "" &&
          !otherExercise && (
            <div className="all_exercise_cat custonScroll">
              <div className="choose_title">Choose Any One:</div>
              {exerciseList.map((item, itemIndex) => (
                <div className="catgry" key={itemIndex}>
                  <button
                    title={item?.name}
                    className="exercise_category"
                    onClick={() => handleExerciseCategory(item?.name)}
                  >
                    {item?.name}
                  </button>
                  <button
                    title={`How to do ${item?.name}`}
                    className="know_more"
                    onClick={() => navigate(item?.url)}
                  >
                    <FaQuestionCircle />
                  </button>
                </div>
              ))}
            </div>
          )}

        {exerciseCategory !== "" && activity === "exercise" && (
          <div className="activity_tracker_camera_sec custonScroll">
            <ul className="activity_tracker">
              {exerciseData.length > 0 ? (
                exerciseData?.map((exercise, index) => {
                  const [exerciseName, count] = Object.entries(exercise)[0];
                  return (
                    <li key={index} className="exercise-item">
                      <p>
                        <strong>{exerciseName}</strong>: {count}{" "}
                      </p>

                      <p>
                        <span>Status</span>
                        {count > 10 ? (
                          <span className="status complete">Complete</span>
                        ) : (
                          <span className="status pending">Pending</span>
                        )}
                      </p>
                    </li>
                  );
                })
              ) : (
                <p>No exercises tracked yet.</p>
              )}
            </ul>

            {/* <PushupTracker
              exerciseStart={exerciseStart}
              setExerciseStart={setExerciseStart}
              setPushupCount={setPushupCount}
              setActivity={setActivity}
              setExerciseCategory={setExerciseCategory}
              setOtherExercise={setOtherExercise}
            /> */}
          </div>
        )}

        {otherExercise && (
          <div className="start_again">
            <button
              title="Continue With Other Exercise"
              onClick={() => handleOtherExercise()}
            >
              Continue With Other Exercise
            </button>
            <button title="Close" onClick={() => setOtherExercise(false)}>
              <IoCloseSharp /> Close
            </button>
          </div>
        )}

        {(activity === "training" || activity === "cycling") && (
          <div className="donwload_app_content">
            Unlock this feature by downloading our app! Get started today by
            downloading the{" "}
            <Link to="" target="_blank">
              Android App
            </Link>{" "}
            or{" "}
            <Link to="" target="_blank">
              iOS App
            </Link>{" "}
            and take your preparation to the next level!
          </div>
        )}
      </div>
      <div className="bottom_bar">
        <div className="music_list">
          <div>
            <h2>
              Binaural Music{" "}
              <span>
                <IoInformationCircleOutline />
              </span>
            </h2>
            <p className="binaural" onClick={() => handleNavigate()}>
              Know, what is Binural Music?
            </p>
          </div>

          <ul>
            <li onClick={handleStudyMusic}>
              <p className="txt">For Study</p>
              <p onClick={handleStudyMusic}>
                {studyMusicPlaying ? <FaPause /> : <FaPlay />}
              </p>
            </li>
            <li onClick={handleWorkoutMusic}>
              <p className="txt">For Workout</p>
              <p onClick={handleWorkoutMusic}>
                {workoutMusicPlaying ? <FaPause /> : <FaPlay />}
              </p>
            </li>
          </ul>
        </div>
      </div>

      {/* {(activity === "training" || activity === "cycling") && (
        <PopupPrompt
          title={appLinks}
          cancelBtnTxt="Okay"
          cancelOnclick={() => setActivity("")}
        />
      )} */}
    </section>
  );
};

export default GptSidebar;
