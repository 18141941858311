import React, { Fragment } from "react";
import "../../styles/refundAndCancellationPolicy.scss";
import { Link, useNavigate } from "react-router-dom";
import { logo_mobile } from "../../assets/images";
import { AiOutlineArrowLeft } from "react-icons/ai";
import GptLayout from "./gptLayout";
import CustomHelmet from "../reusable/Helmet";

const RefundAndCancellationPolicy = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="Refund & Cancellation Policy : SSB-GPT"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
          <section className="privacy_policy_main txt_color refundPolicy_main">
            <div className="w_bg">
              <div className="top_bar">
                <div className="go_back">
                  <Link onClick={() => navigate(-1)}>
                    <AiOutlineArrowLeft />
                  </Link>
                  <p>Refund & Cancellation Policy</p>
                </div>
              </div>

              <section className="policy_layout_main">
                <div className="profile_content">
                  <div className="privacy_content">
                    <div className="title">
                      <img src={logo_mobile} alt="logo" />
                      <p>Last updated: November 01, 2024</p>
                    </div>

                    <div className="main_heading">
                      {/* <h1>Refund & Cancellation Policy</h1> */}
                      <p>
                        Thank you for choosing our services. Please carefully
                        review our refund and cancellation policy below:
                      </p>
                    </div>

                    <div className="privacy_text">
                      <h2 className="heading">1. Non-Refundable Purchases</h2>
                      <p>
                        All purchases of our products and services, including
                        online courses, batches, and other offerings, are
                        strictly non-refundable. This policy ensures consistency
                        and availability for all participants and helps us
                        maintain a high standard of service.
                      </p>
                      <h2 className="heading">2. Batch Transfer Policy</h2>
                      <p>
                        In the event that you accidentally purchase the wrong
                        batch, we offer a one-time transfer option:
                      </p>
                      <ul className="info_list">
                        <li>
                          <b>Request Window:</b> You may submit a request to
                          change to an alternative batch of equal value within{" "}
                          <b>10 days</b> of your initial purchase.
                        </li>
                        <li>
                          <b>Conditions:</b> The transfer is only permitted if
                          the new batch has available seats and is of equal
                          value to the original purchase.
                        </li>
                        <li>
                          <b>Process:</b> To request a transfer, please contact
                          our support team with your purchase details and the
                          preferred batch you would like to switch to. Our team
                          will respond promptly to confirm availability and
                          assist with the transfer.
                        </li>
                      </ul>

                      <h2 className="heading">3. Exceptions</h2>
                      <p>
                        Refunds or transfers beyond the initial 10-day window
                        are not permitted, as seats are reserved and resources
                        are allocated based on enrollment.
                      </p>

                      <p>
                        We appreciate your understanding and are committed to
                        providing a positive learning experience. For any
                        further questions or support, please feel free to
                        contact us.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <Link to="/" className="goHome_btn">
                Home
              </Link>
            </div>
          </section>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default RefundAndCancellationPolicy;
