import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import {  legRaises } from "../../../assets/images";

const JumpingJackGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Leg Raises the Right Way</h2>
          <p>
            Leg raises are an excellent exercise for targeting the lower abs and
            strengthening the hip flexors. They’re commonly used in core
            routines to enhance stability and build lower abdominal strength.
            Here’s a step-by-step guide to performing leg raises with proper
            form.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/gz-VmPNR2VQ"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Get into Starting Position</h4>

          <span>
            1. <strong> Lie Flat on Your Back: </strong>
            Start by lying on your back on a mat, with your legs extended and
            arms by your sides, palms facing down.
          </span>

          <span>
            2. <strong>Engage Your Core: </strong>
            Tighten your core to stabilize your lower back. If needed, you can
            place your hands under your hips for added support.
          </span>

          <img src={legRaises} alt="legRaises" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Lift Your Legs</h4>
          <span>
            1. <strong>Raise Both Legs Up Together:</strong>
            Keeping your legs straight, lift them toward the ceiling until they
            form a 90-degree angle with your torso. Try to keep your lower back
            pressed into the ground as much as possible.
          </span>
          <span>
            2. <strong>Exhale as You Lift: </strong>Breathe out as you raise
            your legs to fully engage your core and avoid straining your lower
            back.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Lower Back Down</h4>
          <span>
            1.
            <strong>Control the Descent:</strong>
            Slowly lower your legs back down to the ground without letting them
            touch the floor. Keep your core tight to control the movement.
          </span>

          <span>
            2. <strong> Inhale as You Lower: </strong>
            Breathe in as you bring your legs down, maintaining control and
            avoiding any sudden drops.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Arching the Lower Back:</strong> Keep your lower
            back pressed into the ground to avoid strain. If you find this
            difficult, try placing your hands under your hips.
          </span>
          <span>
            {"b)."} <strong>Rushing the Movement: </strong> Lower your legs
            slowly to maximize core engagement and prevent using momentum.
          </span>
          <span>
            {"c)."} <strong>Bending the Knees:</strong>
            Keep your legs as straight as possible to fully engage the lower
            abs.
          </span>
          <br />
          <h4 className="subHeading">Variations for Added Intensity</h4>
          <span>
            {"a)."} <strong> Weighted Leg Raise: </strong>Hold a small weight or
            dumbbell between your feet for extra resistance.
          </span>
          <span>
            {"b)."} <strong>Leg Raise with Hip Lift: </strong> At the top of the
            movement, lift your hips slightly off the ground for added lower ab
            activation.
          </span>

          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default JumpingJackGuidance;
