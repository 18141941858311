import React, { Fragment, useEffect, useState } from "react";
import "../styles/teacherProfile.scss";
import {
  FaExpeditedssl,
  FaUserEdit,
  FaUpload,
  FaFileUpload,
  FaCopy,
} from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { useAuth } from "../context/userContext";
import { defaultUser } from "../assets/images";
import StudentLayout from "../component/reusable/ProfileLayout.js";
import CustomInput from "../component/reusable/CustomInput";
import UploadProfilePicForm from "../component/reusable/UploadProfilePicForm.js";
import Loader from "../component/reusable/Loader.js";
import GptLayout from "../component/ssbGpt/gptLayout.js";
import { FaMapLocationDot } from "react-icons/fa6";
import CustomTextarea from "../component/reusable/CustomTextarea.js";
import schoolLogo from "../images/schoolLogo.png";
import CustomButton from "../component/reusable/CustomButton.js";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearUpdateState,
  updateUserProfile,
  userProfile,
} from "../redux/actions/userAction.js";
import { toast } from "react-toastify";
import ButtonLoader from "../component/reusable/ButtonLoader.js";
import {
  clearSchoolErrors,
  clearSchoolState,
  createSchoolAction,
  getSchoolAction,
  updateSchoolAction,
} from "../redux/actions/schoolByTeacherAction.js";
import { Link } from "react-router-dom";
import { teacherSidebarLinks } from "../profileSidebarLinks.js";

const TeacherProfile = () => {
  const dispatch = useDispatch();
  const [uploadProfileModal, setUploadProfileModal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editSchool, setEditSchool] = useState(false);
  const [auth] = useAuth();

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const handleCurrentLocation = () => {
    setProfileData({
      ...profileData,
      address: "Mandola, Rewari, Haryana, 123103",
    });
  };

  const [profileData, setProfileData] = useState({
    first_name: auth?.user?.first_name,
    last_name: auth?.user?.last_name,
    phone: auth?.user?.phone,
    email: auth?.user?.email,
    address: auth?.user?.address,
    dob: auth?.user?.dob,
  });

  // UPDATE USER PROFILE START
  const {
    loading,
    data: updateProfileData,
    error: updateProfileErr,
  } = useSelector((state) => state.updateProfile);

  const handleUpdateProfile = () => {
    dispatch(
      updateUserProfile(
        profileData?.first_name,
        profileData?.last_name,
        profileData?.email,
        profileData?.phone,
        profileData?.dob,
        profileData?.address
      )
    );
  };

  useEffect(() => {
    if (updateProfileData?.success) {
      toast.success(updateProfileData?.message);
      dispatch(clearUpdateState());
      dispatch(userProfile());
      setEditable(false);
    }
    if (!updateProfileErr) {
      toast.success(updateProfileErr);
      dispatch(clearErrors());
    }
  }, [
    updateProfileData?.message,
    updateProfileData?.success,
    dispatch,
    updateProfileErr,
  ]);
  // UPDATE USER PROFILE ENDS

  // GET SCHOOL START
  const {
    loading: getSchoolLoading,
    data: getSchoolData,
    error: getSchoolErr,
  } = useSelector((state) => state.getSchool);

  const createdSchool = getSchoolData?.school;

  useEffect(() => {
    dispatch(getSchoolAction());
  }, [dispatch]);

  useEffect(() => {
    if (getSchoolErr) {
      toast.error(getSchoolErr);
      dispatch(clearSchoolErrors);
    }
  }, [getSchoolData?.success, getSchoolData?.message, getSchoolErr, dispatch]);
  // GET SCHOOL ENDS

  const [schoolData, setSchoolData] = useState({
    schoolName: getSchoolData?.school?.name,
    schoolAddress: getSchoolData?.school?.address,
    schoolBio: getSchoolData?.school?.bio,
    schoolInfo: getSchoolData?.school?.infoFile,
    schoolLogo: getSchoolData?.school?.logo,
  });

  const handleSchoolInputChange = (e) => {
    const { value, name, files } = e.target;
    setSchoolData({
      ...schoolData,
      [name]: files ? files[0] : value,
    });
  };

  // CREATE SCHOOL START
  const {
    loading: createSchoolLoading,
    data: createSchoolData,
    error: createSchoolErr,
  } = useSelector((state) => state.createSchool);

  const handleCreateSchool = () => {
    if (
      schoolData.schoolName &&
      schoolData.schoolAddress &&
      schoolData.schoolBio &&
      schoolData.schoolInfo &&
      schoolData.schoolLogo
    ) {
      dispatch(
        createSchoolAction(
          schoolData.schoolName,
          schoolData.schoolAddress,
          schoolData.schoolBio,
          schoolData.schoolInfo,
          schoolData.schoolLogo
        )
      );
    } else {
      toast.warning("Please fill all the required fields");
      setEditSchool(true);
    }
  };

  useEffect(() => {
    if (createSchoolData?.success) {
      toast.success(createSchoolData?.message);
      dispatch(clearSchoolState());
      setEditSchool(false);
      dispatch(getSchoolAction());
    }
    if (createSchoolErr) {
      toast.error(createSchoolErr);
      dispatch(clearSchoolErrors);
    }
  }, [
    createSchoolData?.success,
    createSchoolData?.message,
    createSchoolErr,
    dispatch,
  ]);
  // CREATE SCHOOL ENDS

  // UPDATE SCHOOL START
  const {
    loading: updateSchoolLoading,
    data: updateSchoolData,
    error: updateSchoolErr,
  } = useSelector((state) => state.updateSchool);

  const handleUpdateSchool = () => {
    dispatch(
      updateSchoolAction(
        schoolData.schoolName,
        schoolData.schoolAddress,
        schoolData.schoolBio,
        schoolData.schoolInfo,
        schoolData.schoolLogo
      )
    );
  };

  useEffect(() => {
    if (updateSchoolData?.success) {
      toast.success(updateSchoolData?.message);
      dispatch(clearSchoolState());
      setEditSchool(false);
      dispatch(getSchoolAction());
    }
    if (updateSchoolErr) {
      toast.error(updateSchoolErr);
      dispatch(clearSchoolErrors);
    }
  }, [
    updateSchoolData?.success,
    updateSchoolData?.message,
    updateSchoolErr,
    dispatch,
  ]);
  // UPDATE SCHOOL START

  const handleLinkCopy = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Could not copy link: ", error);
      });
  };

  const hostUrl = window.location.origin;

  return (
    <Fragment>
      {loading || getSchoolLoading ? (
        <Loader />
      ) : (
        <GptLayout>
          <StudentLayout sidebarLinks={teacherSidebarLinks}>
            <section className="teacher_profile_section txt_color">
              <h2 className="profilePage_heading">Account Info</h2>

              <div className="user_details_main">
                <div className="user_pro_data">
                  <h2>
                    Profile{" "}
                    <button
                      className={editable ? "activeBtn" : ""}
                      onClick={() => setEditable(!editable)}
                    >
                      {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </h2>
                  <div>
                    <div className="details_sec">
                      <div className="data_card">
                        <CustomInput
                          label="First Name"
                          type="text"
                          placeholder="First Name"
                          impStar={editable ? "*" : ""}
                          name="first_name"
                          value={
                            editable
                              ? profileData?.first_name
                              : auth.user?.first_name
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Last Name"
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          value={
                            editable
                              ? profileData?.last_name
                              : auth.user?.last_name
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Whatsapp Number"
                          type="number"
                          placeholder="0987654321"
                          impStar={editable ? "*" : ""}
                          name="phone"
                          value={
                            editable ? profileData?.phone : auth.user?.phone
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Email"
                          type="email"
                          placeholder="example@email.com"
                          impStar={editable ? "*" : ""}
                          name="email"
                          value={
                            editable ? profileData?.email : auth.user?.email
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Address"
                          type="text"
                          placeholder="Ex: MG Road, Gurugram"
                          impStar={editable ? "*" : ""}
                          name="address"
                          value={
                            editable ? profileData?.address : auth.user?.address
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                          locationIcon={editable ? <FaMapLocationDot /> : ""}
                          handleLocation={editable ? handleCurrentLocation : ""}
                        />
                        <CustomInput
                          label="Date Of Birth"
                          type="date"
                          placeholder="26-11-1998"
                          impStar={editable ? "*" : ""}
                          name="dob"
                          value={editable ? profileData?.dob : auth.user?.dob}
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />

                        {editable && (
                          <CustomButton
                            className="pro_update_btn"
                            title={loading ? <ButtonLoader /> : `Update`}
                            width="100px"
                            onClick={handleUpdateProfile}
                          />
                        )}
                      </div>
                      <div className="data_card" style={{ marginTop: "20px" }}>
                        <CustomInput
                          label="Refferal Link"
                          type="text"
                          placeholder="Refferal Link"
                          name="refferalLink"
                          value={`${hostUrl}/student-register?ref=${auth?.user?.couponCode}`}
                          readOnly={true}
                          fullWidth={true}
                          copyBtn={<FaCopy />}
                          handleCopy={() =>
                            handleLinkCopy(
                              `${hostUrl}/student-register?ref=${auth?.user?.couponCode}`
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="profile_picSec">
                      <div className="user_pro_pic">
                        {editable && (
                          <button
                            className="absolute_btn deleteImage_btn"
                            title="Remove Profile Picture"
                          >
                            <RiDeleteBinLine />
                          </button>
                        )}

                        <img
                          src={
                            auth?.user?.profile?.url
                              ? auth?.user?.profile?.url
                              : defaultUser
                          }
                          alt="alpha regiment user"
                        />
                        {editable && (
                          <button
                            title="Upload Profile Picture"
                            className="absolute_btn uploadImage_btn"
                            onClick={() => setUploadProfileModal(true)}
                          >
                            <FaUpload />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user_pro_data" style={{ marginTop: "40px" }}>
                  <h2>
                    School Profile{" "}
                    <button
                      className={editSchool ? "activeBtn" : ""}
                      onClick={() => setEditSchool(!editSchool)}
                    >
                      {editSchool ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </h2>
                  <div>
                    <div className="details_sec">
                      <div className="data_card">
                        <CustomInput
                          label="Name"
                          type="text"
                          impStar={editSchool ? "*" : ""}
                          placeholder="Ex: PPS Brilliant School"
                          name="schoolName"
                          value={
                            editSchool
                              ? schoolData?.schoolName
                              : createdSchool?.name
                          }
                          readOnly={editSchool ? false : true}
                          onChange={handleSchoolInputChange}
                          borderColor={editSchool ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Address"
                          type="text"
                          placeholder="Ex: MG Road, Gurugram"
                          impStar={editSchool ? "*" : ""}
                          name="schoolAddress"
                          value={
                            editSchool
                              ? schoolData?.schoolAddress
                              : createdSchool?.address
                          }
                          readOnly={editSchool ? false : true}
                          onChange={handleSchoolInputChange}
                          borderColor={editSchool ? "#f2f2f2" : ""}
                          locationIcon={editSchool ? <FaMapLocationDot /> : ""}
                          handleLocation={
                            editSchool ? handleCurrentLocation : ""
                          }
                        />
                        <CustomTextarea
                          label="School’s Bio"
                          type="text"
                          impStar={editSchool ? "*" : ""}
                          placeholder="Bio"
                          name="schoolBio"
                          value={
                            editSchool
                              ? schoolData?.schoolBio
                              : createdSchool?.bio
                          }
                          readOnly={editSchool ? false : true}
                          onChange={handleSchoolInputChange}
                          borderColor={editSchool ? "#f2f2f2" : ""}
                          fullWidth={true}
                        />
                        <CustomInput
                          label="School’s Information"
                          type="file"
                          accept="application/pdf"
                          impStar={editSchool ? "*" : ""}
                          name="schoolInfo"
                          readOnly={editSchool ? false : true}
                          onChange={handleSchoolInputChange}
                          borderColor={editSchool ? "#f2f2f2" : ""}
                          locationIcon={editSchool ? <FaFileUpload /> : ""}
                          handleLocation={
                            editSchool ? handleCurrentLocation : ""
                          }
                          fullWidth={true}
                        />
                        {!editSchool && createdSchool?.infoFile && (
                          <Link
                            to={
                              process.env.REACT_APP_API_URL +
                              `/uploads/` +
                              createdSchool?.infoFile
                            }
                            target="_blank"
                          >
                            See Information File
                          </Link>
                        )}

                        {editSchool &&
                          (createdSchool?._id ? (
                            <CustomButton
                              className="pro_update_btn"
                              title={
                                updateSchoolLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  `Update`
                                )
                              }
                              width="100px"
                              onClick={handleUpdateSchool}
                            />
                          ) : (
                            <CustomButton
                              className="pro_update_btn"
                              title={
                                createSchoolLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  `Create`
                                )
                              }
                              width="100px"
                              onClick={handleCreateSchool}
                            />
                          ))}
                      </div>
                    </div>
                    <div className="profile_picSec">
                      <div className="user_pro_pic">
                        {editSchool && (
                          <button
                            className="absolute_btn deleteImage_btn"
                            title="Remove Profile Picture"
                          >
                            <RiDeleteBinLine />
                          </button>
                        )}

                        <img
                          src={
                            createdSchool?.logo
                              ? `${process.env.REACT_APP_API_URL}/uploads/${createdSchool?.logo}`
                              : schoolLogo
                          }
                          alt="ssb gpt school logo"
                        />
                        {editSchool && (
                          <>
                            <input
                              type="file"
                              accept="image/*"
                              id="file-upload"
                              name="schoolLogo"
                              onChange={handleSchoolInputChange}
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor="file-upload"
                              className="absolute_btn uploadImage_btn"
                            >
                              <FaUpload />
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* UPLOAD PROFILE PICTURE FORM START */}
            {uploadProfileModal && (
              <section className="upload_profile_pic_main">
                <UploadProfilePicForm
                  setUploadProfileModal={setUploadProfileModal}
                />
              </section>
            )}

            {/* UPLOAD PROFILE PICTURE FORM ENDS */}
          </StudentLayout>
        </GptLayout>
      )}
    </Fragment>
  );
};

export default TeacherProfile;
