import React, { Fragment } from "react";
import "../../styles/contactUs.scss";
import CustomHelmet from "../reusable/Helmet";
import GptLayout from "./gptLayout";
import { Link } from "react-router-dom";
import { GrMapLocation } from "react-icons/gr";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { FaRegEnvelope, FaUser } from "react-icons/fa";
import { RiFacebookBoxLine } from "react-icons/ri";
import { CiTwitter } from "react-icons/ci";
import { IoLogoInstagram } from "react-icons/io5";
import OtherGPT from "../reusable/OtherGPT";

const ContactUs = () => {
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
          <div className="contact_us_main txt_color">
            <div className="heading_section">
              <h1 className="main_heading">Contact Us</h1>
              <h2 className="subHeading">
                Our team is standing by, prepared to engage.
              </h2>
              <p className="heading_desc">
                Conquer the SSB Interview: Harness AI Firepower with SSB-GPT.
              </p>
            </div>

            <div className="contact_form">
              <div className="left_sec">
                <h2 className="heading">Drop A Line</h2>
                <div className="description">
                  We’re always ready to hear from you—or better yet, welcome you
                  to base. Looking forward to your arrival!
                </div>

                <div className="addresses">
                  <p>
                    <span>
                      <LiaPhoneVolumeSolid />
                    </span>
                    <span>
                      <Link to="tel:+9190348-64394">(+91) 90348-64394</Link>
                      <Link to="tel:+9189014-84719">(+91) 89014-84719</Link>
                    </span>
                  </p>
                  <p>
                    <span>
                      <GrMapLocation />
                    </span>
                    <span>
                      Alpha Regiment Campus, Farrukhnager, Gurugram, HR 122506
                    </span>
                  </p>
                  <p>
                    <span>
                      <FaRegEnvelope />
                    </span>
                    <span>
                      <Link to="mailto:support@ssbgpt.in">
                        support@ssbgpt.in
                      </Link>
                    </span>
                  </p>
                </div>

                <div className="social_links">
                  <Link title="Facebook">
                    <RiFacebookBoxLine />
                  </Link>
                  <Link title="Twitter">
                    <CiTwitter />
                  </Link>
                  <Link title="Instagram">
                    <IoLogoInstagram />
                  </Link>
                </div>
              </div>
              <div className="right_sec">
                <form className="form">
                  <div className="form_heading">
                    Transmit your message to us.
                  </div>
                  <div className="inputBox">
                    <label>Name*</label>
                    <input type="text" placeholder="Ram Kumar" />
                    <span>
                      <FaUser />
                    </span>
                  </div>
                  <div className="inputBox">
                    <label>Email*</label>
                    <input type="email" placeholder="ramkumar@gmail.com" />
                    <span>
                      {" "}
                      <FaRegEnvelope />
                    </span>
                  </div>
                  <div className="inputBox">
                    <label>How can we help?</label>
                    <textarea type="text" placeholder="" rows={5} />
                  </div>
                  <button type="submit">Submit Request</button>
                </form>
              </div>
            </div>

            <div className="map_container">
              <div
                id="embed-ded-map-canvas"
                style={{ height: "100%", width: "100%", maxWidth: "100%" }}
              >
                <iframe
                  style={{ height: "100%", width: "100%", border: 0 }}
                  frameBorder="0"
                  title="Alpha Regiment Map"
                  src="https://www.google.com/maps/embed/v1/place?q=Alpha+Regiment+Campus,+Farrukhnager,+Gurugram,+HR+122506&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                ></iframe>
              </div>
            </div>
            <OtherGPT />
          </div>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default ContactUs;
