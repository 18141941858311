import React, { Fragment, useEffect, useState } from "react";
import "../styles/parentProfile.scss";
import { FaExpeditedssl, FaUserEdit, FaUpload } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { useAuth } from "../context/userContext";
import { defaultUser } from "../assets/images";
import StudentLayout from "../component/reusable/ProfileLayout.js";
import CustomInput from "../component/reusable/CustomInput";
import UploadProfilePicForm from "../component/reusable/UploadProfilePicForm.js";
import Loader from "../component/reusable/Loader.js";
import GptLayout from "../component/ssbGpt/gptLayout.js";
import { FaMapLocationDot } from "react-icons/fa6";
import { ImLink } from "react-icons/im";
import CustomButton from "../component/reusable/CustomButton.js";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearUpdateState,
  updateUserProfile,
  userProfile,
} from "../redux/actions/userAction.js";
import { toast } from "react-toastify";
import ButtonLoader from "../component/reusable/ButtonLoader.js";
import {
  attachChildAction,
  clearChildErrors,
  clearChildState,
  getChildsAction,
  updateChildAction,
} from "../redux/actions/attachChildAction.js";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { parentSidebarLinks } from "../profileSidebarLinks.js";

const ParentProfile = () => {
  const dispatch = useDispatch();
  const [uploadProfileModal, setUploadProfileModal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editChild, setEditChild] = useState({ show: false, id: "" });
  const [addChild, setAddChild] = useState(false);
  const [auth] = useAuth();

  const [childName, setChildName] = useState("");
  const [childEmail, setChildEmail] = useState("");

  const [updateChildName, setUpdateChildName] = useState("");
  const [updateChildEmail, setUpdateChildEmail] = useState("");

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const [profileData, setProfileData] = useState({
    first_name: auth?.user?.first_name,
    last_name: auth?.user?.last_name,
    phone: auth?.user?.phone,
    email: auth?.user?.email,
    address: auth?.user?.address,
    dob: auth?.user?.dob,
  });

  // UPDATE USER PROFILE START
  const {
    loading,
    data: updateProfileData,
    error: updateProfileErr,
  } = useSelector((state) => state.updateProfile);

  const handleUpdateProfile = () => {
    dispatch(
      updateUserProfile(
        profileData?.first_name,
        profileData?.last_name,
        profileData?.email,
        profileData?.phone,
        profileData?.dob,
        profileData?.address
      )
    );
  };

  useEffect(() => {
    if (updateProfileData?.success) {
      toast.success(updateProfileData?.message);
      dispatch(clearUpdateState());
      dispatch(userProfile());
      setEditable(false);
    }
    if (!updateProfileErr) {
      toast.success(updateProfileErr);
      dispatch(clearErrors());
    }
  }, [
    updateProfileData?.message,
    updateProfileData?.success,
    dispatch,
    updateProfileErr,
  ]);
  // UPDATE USER PROFILE ENDS

  const handleCurrentLocation = () => {
    setProfileData({
      ...profileData,
      address: "Mandola, Rewari, Haryana, 123103",
    });
  };

  // ATTACH CHILD START
  const {
    loading: attachChildLoading,
    data: attachChildData,
    error: attachChildErr,
  } = useSelector((state) => state.attachChild);

  const handleAttachChild = () => {
    dispatch(attachChildAction(childName, childEmail));
  };

  useEffect(() => {
    if (attachChildData?.success) {
      toast.success(attachChildData?.message);
      dispatch(clearChildState);
      setChildName("");
      setChildEmail("");
      setAddChild(false);
      dispatch(getChildsAction());
    }
    if (attachChildErr) {
      toast.error(attachChildErr);
      dispatch(clearChildErrors);
    }
  }, [
    attachChildData?.success,
    attachChildData?.message,
    attachChildErr,
    dispatch,
  ]);
  // ATTACH CHILD ENDS

  // GET ALL CHILDS START
  const {
    loading: getChildLoading,
    data: getChildData,
    error: getChildError,
  } = useSelector((state) => state.getChilds);

  useEffect(() => {
    dispatch(getChildsAction());
  }, [dispatch]);

  useEffect(() => {
    if (getChildError) {
      toast.error(getChildError);
      dispatch(clearChildErrors);
    }
  }, [getChildData?.success, getChildData?.message, getChildError, dispatch]);
  // GET ALL CHILDS ENDS

  // UPDATE CHILD START
  const handleChildEdit = (item) => {
    setEditChild({ show: true, id: item?._id });
    setUpdateChildName(item?.name);
    setUpdateChildEmail(item?.email);
  };

  const {
    loading: updateChildLoading,
    data: updateChildData,
    error: updateChildErr,
  } = useSelector((state) => state.updateChild);

  const handleUpdateChild = (item) => {
    if (item?._id) {
      dispatch(updateChildAction(item?._id, updateChildName, updateChildEmail));
    }
  };

  useEffect(() => {
    if (updateChildData?.success) {
      toast.success(updateChildData?.message);
      dispatch(clearChildState());
      setEditChild({ show: false, id: "" });
      setUpdateChildName("");
      setUpdateChildEmail("");
      dispatch(getChildsAction());
    }
    if (updateChildErr) {
      toast.error(updateChildErr);
      dispatch(clearChildErrors());
      setEditChild({ show: false, id: "" });
      setUpdateChildName("");
      setUpdateChildEmail("");
    }
  }, [
    updateChildData?.success,
    updateChildData?.message,
    dispatch,
    updateChildErr,
  ]);
  // UPDATE CHILD ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <GptLayout>
          <StudentLayout sidebarLinks={parentSidebarLinks}>
            <section className="parent_profile_section txt_color">
              <h2 className="profilePage_heading">Account Info</h2>

              <div className="user_details_main">
                <div className="user_pro_data">
                  <h2>
                    Profile{" "}
                    <button
                      className={editable ? "activeBtn" : ""}
                      onClick={() => setEditable(!editable)}
                    >
                      {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </h2>
                  <div>
                    <div className="details_sec">
                      <div className="data_card">
                        <CustomInput
                          label="First Name"
                          type="text"
                          placeholder="First Name"
                          impStar={editable ? "*" : ""}
                          name="first_name"
                          value={
                            editable
                              ? profileData?.first_name
                              : auth.user?.first_name
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Last Name"
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          value={
                            editable
                              ? profileData?.last_name
                              : auth.user?.last_name
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Whatsapp Number"
                          type="number"
                          placeholder="0987654321"
                          impStar={editable ? "*" : ""}
                          name="phone"
                          value={
                            editable ? profileData?.phone : auth.user?.phone
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Email"
                          type="email"
                          placeholder="example@email.com"
                          impStar={editable ? "*" : ""}
                          name="email"
                          value={
                            editable ? profileData?.email : auth.user?.email
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Address"
                          type="text"
                          placeholder="Ex: MG Road, Gurugram"
                          impStar={editable ? "*" : ""}
                          name="address"
                          value={
                            editable ? profileData?.address : auth.user?.address
                          }
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                          locationIcon={<FaMapLocationDot />}
                          handleLocation={handleCurrentLocation}
                        />
                        <CustomInput
                          label="Date Of Birth"
                          type="date"
                          placeholder="26-11-1998"
                          impStar={editable ? "*" : ""}
                          name="dob"
                          value={editable ? profileData?.dob : auth.user?.dob}
                          readOnly={editable ? false : true}
                          onChange={handleInputChange}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />

                        {editable && (
                          <CustomButton
                            className="pro_update_btn"
                            title={loading ? <ButtonLoader /> : `Update`}
                            width="100px"
                            disabled={loading}
                            onClick={handleUpdateProfile}
                          />
                        )}
                      </div>
                      <div className="card_heading">
                        <h2 className="">Your Child</h2>
                        <CustomButton
                          icon={<IoMdAdd />}
                          onClick={() => setAddChild(!addChild)}
                          width="40px"
                        />
                      </div>
                      <div className="data_card">
                        {addChild && (
                          <>
                            <CustomInput
                              label="Children's Name"
                              type="text"
                              placeholder="Ex: Pankaj Yadav"
                              name="childName"
                              value={childName}
                              onChange={(e) => setChildName(e.target.value)}
                              borderColor="#f2f2f2"
                            />
                            <div className="inputWithButton">
                              <CustomInput
                                label="Children's Email"
                                type="text"
                                placeholder="Ex: childmail@example.com"
                                name="childEmail"
                                value={childEmail}
                                onChange={(e) => setChildEmail(e.target.value)}
                                borderColor="#f2f2f2"
                              />
                              <button onClick={handleAttachChild}>
                                {attachChildLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  <>
                                    <ImLink /> Connect
                                  </>
                                )}
                              </button>
                            </div>
                            <hr
                              style={{
                                width: "100%",
                                borderStyle: "dashed",
                                borderColor: "#f2f2f240",
                                marginBottom: "20px",
                              }}
                            />
                          </>
                        )}

                        {getChildLoading ? (
                          <h1>Loading</h1>
                        ) : (
                          <div className="childList">
                            <div className="heading">
                              <label>Child Name</label>
                              <label>Child Email</label>
                            </div>
                            {getChildData?.childs?.map((item) => {
                              return (
                                <div className="child">
                                  <CustomInput
                                    type="text"
                                    placeholder="Ex: Pankaj Yadav"
                                    name="updateChildName"
                                    value={
                                      editChild.show &&
                                      editChild.id === item?._id
                                        ? updateChildName
                                        : item?.name
                                    }
                                    readOnly={
                                      editChild.show &&
                                      editChild.id === item?._id
                                        ? false
                                        : true
                                    }
                                    onChange={(e) =>
                                      setUpdateChildName(e.target.value)
                                    }
                                    borderColor={
                                      editChild.show &&
                                      editChild.id === item?._id
                                        ? "#f2f2f2"
                                        : ""
                                    }
                                  />

                                  <CustomInput
                                    type="email"
                                    placeholder="Ex: childmail@example.com"
                                    name="updateChildEmail"
                                    value={
                                      editChild.show &&
                                      editChild.id === item?._id
                                        ? updateChildEmail
                                        : item?.email
                                    }
                                    readOnly={
                                      editChild.show &&
                                      editChild.id === item?._id
                                        ? false
                                        : true
                                    }
                                    onChange={(e) =>
                                      setUpdateChildEmail(e.target.value)
                                    }
                                    borderColor={
                                      editChild.show &&
                                      editChild.id === item?._id
                                        ? "#f2f2f2"
                                        : ""
                                    }
                                  />
                                  <button
                                    title={
                                      editChild.show &&
                                      editChild.id === item?._id
                                        ? "Update"
                                        : "Edit"
                                    }
                                    onClick={
                                      editChild.show &&
                                      editChild.id === item?._id
                                        ? () => handleUpdateChild(item)
                                        : () => handleChildEdit(item)
                                    }
                                  >
                                    {editChild.show &&
                                    editChild.id === item?._id ? (
                                      updateChildLoading ? (
                                        <ButtonLoader />
                                      ) : (
                                        <TiTick />
                                      )
                                    ) : (
                                      <CiEdit />
                                    )}
                                  </button>
                                  <button title="Delete">
                                    <MdDelete />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="profile_picSec">
                      <div className="user_pro_pic">
                        {editable && (
                          <button
                            className="absolute_btn deleteImage_btn"
                            title="Remove Profile Picture"
                          >
                            <RiDeleteBinLine />
                          </button>
                        )}

                        <img
                          src={
                            auth?.user?.profile?.url
                              ? auth?.user?.profile?.url
                              : defaultUser
                          }
                          alt="alpha regiment user"
                        />
                        {editable && (
                          <button
                            title="Upload Profile Picture"
                            className="absolute_btn uploadImage_btn"
                            onClick={() => setUploadProfileModal(true)}
                          >
                            <FaUpload />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* UPLOAD PROFILE PICTURE FORM START */}
            {uploadProfileModal && (
              <section className="upload_profile_pic_main">
                <UploadProfilePicForm
                  setUploadProfileModal={setUploadProfileModal}
                />
              </section>
            )}

            {/* UPLOAD PROFILE PICTURE FORM ENDS */}
          </StudentLayout>
        </GptLayout>
      )}
    </Fragment>
  );
};

export default ParentProfile;
