import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { wallSits } from "../../../assets/images";

const WallSitsGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Wall Sits the Right Way</h2>
          <p>
            Wall sits are an isometric exercise that strengthens the legs,
            particularly the quads, glutes, and calves, while also improving
            muscular endurance. It’s a simple exercise that requires only a wall
            and no equipment, making it ideal for at-home workouts. Here’s how
            to perform wall sits with proper form.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/cWTZ8Am1Ee0"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Set Up Against a Wall</h4>

          <span>
            1. <strong> Stand with Your Back Against a Wall:</strong>
            Start by standing about two feet away from a wall, with your back
            against it.
          </span>

          <span>
            2. <strong>Place Feet Shoulder-Width Apart: </strong>
            Position your feet shoulder-width apart, ensuring they’re about 1–2
            feet away from the wall, depending on your height.
          </span>

          <img src={wallSits} alt="wallSits" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Lower into the Sit Position</h4>
          <span>
            1. <strong>Slide Down the Wall: </strong>
            Slowly slide your back down the wall until your thighs are parallel
            to the ground, creating a 90-degree angle at your knees.
          </span>
          <span>
            2. <strong>Engage Your Core: </strong> Keep your core tight and your
            back pressed against the wall. Your knees should be directly above
            your ankles, not extending over your toes.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Hold the Position</h4>
          <span>
            1.
            <strong>Maintain the Position:</strong>
            Hold the squat position for the desired amount of time (start with
            15–30 seconds and increase as you build strength).
          </span>

          <span>
            2. <strong>Focus on Steady Breathing: </strong>
            Breathe steadily throughout the hold, keeping your core engaged and
            legs strong.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Letting Knees Drift Over Toes: </strong> Keep your
            knees directly above your ankles to avoid excess strain on your
            knees.
          </span>
          <span>
            {"b)."} <strong>Arching Your Lower Back: </strong> Keep your back
            fully pressed against the wall to ensure proper posture and protect
            your lower back.
          </span>
          <span>
            {"c)."} <strong>Allowing Feet to Be Too Close to the Wall:</strong>
            Position your feet far enough away from the wall so that your knees
            form a 90-degree angle in the sit position.
          </span>
          <br />
          <h4 className="subHeading">Variations for Added Intensity</h4>
          <span>
            {"a)."} <strong>Weighted Wall Sit:</strong> Hold a dumbbell or
            medicine ball in front of you to add resistance.
          </span>
          <span>
            {"b)."} <strong>Single-Leg Wall Sit: </strong> Lift one foot off the
            ground to increase the difficulty and engage your core even more.
          </span>
          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default WallSitsGuidance;
