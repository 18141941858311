import {
  ATTACH_PARENT_FAIL,
  ATTACH_PARENT_REQUEST,
  ATTACH_PARENT_SUCCESS,
  CLEAR_ERROR_ATTACH_PARENT,
  CLEAR_STATE_ATTACH_PARENT,
} from "../constants/attachParentConstant";

// ATTACH PARENT BY STUDENT REDUCER
export const attachParentReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTACH_PARENT_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case ATTACH_PARENT_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case ATTACH_PARENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_STATE_ATTACH_PARENT:
      return {
        loading: false,
        data: {},
      };
    case CLEAR_ERROR_ATTACH_PARENT:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
