import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { BiCycleCrunch } from "../../../assets/images";

const BiCycleGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Bicycle Crunches the Right Way</h2>
          <p>
            Bicycle crunches are a dynamic core exercise that targets the abs,
            particularly the obliques, while also engaging the hip flexors. This
            exercise combines an ab crunch with a twisting motion for a
            full-core workout. Here’s a step-by-step guide to performing bicycle
            crunches with proper form.
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/1we3bh9uhqY"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Get Into Starting Position</h4>

          <span>
            1. <strong> Lie Flat on Your Back:</strong>
            Start by lying on the floor or a mat with your lower back pressed
            into the ground.
          </span>

          <span>
            2. <strong>Place Your Hands Behind Your Head: </strong>
            Lightly support your head with your hands, keeping elbows wide.
            Avoid pulling on your neck.
          </span>
          <span>
            3. <strong>Lift Your Legs:</strong>
            Lift your legs and bend your knees to a 90-degree angle, so your
            shins are parallel to the ground.
          </span>

          <img src={BiCycleCrunch} alt="BiCycleCrunch" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Crunch and Twist</h4>
          <span>
            1. <strong>Engage Your Core and Twist:</strong>
            Engage your core, and as you lift your shoulder blades off the
            ground, twist your torso to bring your right elbow toward your left
            knee.
          </span>
          <span>
            2. <strong>Extend the Opposite Leg: </strong> At the same time,
            extend your right leg out straight, keeping it slightly above the
            ground.
          </span>

          <br />
          <h4 className="subHeading">Step 3: Switch Sides</h4>
          <span>
            1.
            <strong>Alternate the Twist:</strong>
            Return to the center briefly, then twist in the opposite direction,
            bringing your left elbow toward your right knee while extending your
            left leg.
          </span>

          <span>
            2. <strong>Keep a Controlled Motion: </strong>
            Continue alternating sides in a controlled and fluid motion, as if
            pedaling a bicycle, for the desired number of repetitions.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Pulling on the Neck: </strong> Avoid using your
            hands to pull your head up. Focus on engaging your core and twisting
            with your torso.
          </span>
          <span>
            {"b)."} <strong>Using Momentum: </strong> Keep the movement
            controlled rather than rushing. Slow down to maintain full
            engagement of the abs and obliques.
          </span>
          <span>
            {"c)."} <strong>Letting Legs Drop Too Low:</strong>
            Keep your extended leg slightly above the ground, not resting it
            completely, to keep tension on the core.
          </span>
          <br />
          <h4 className="subHeading">Variations for Added Intensity</h4>
          <span>
            {"a)."} <strong>Slow Bicycle Crunch:</strong> Perform the exercise
            in slow motion to increase time under tension.
          </span>
          <span>
            {"b)."} <strong>Weighted Bicycle Crunch: </strong> Hold a light
            weight or medicine ball in your hands to increase resistance as you
            twist.
          </span>
          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default BiCycleGuidance;
