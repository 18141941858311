import React, { Fragment } from "react";
import "../../styles/fitnessCoachProfile.scss";
import CustomHelmet from "../reusable/Helmet";
import GptLayout from "../ssbGpt/gptLayout";
import { FiMessageCircle } from "react-icons/fi";
import fitnessCoachProfilPic from "../../images/fitness-coach-profile-pic.png";
import fitness_coach_home from "../../images/fitness_coach_home.png";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import fitness from "../../images/icons/fitness.png";
import analysis from "../../images/icons/analysis.png";
import personalizedFitness from "../../images/personalizedFitness.jpg";
import plan from "../../images/icons/plan.png";
import dailyNutrition from "../../images/dailyNutrition.jpg";
import AIAnalytics from "../../images/AIAnalytics.jpg";
import vikramProgram from "../../images/vikramProgram.png";
import { GoPlus } from "react-icons/go";
import { useAuth } from "../../context/userContext";

const FitnessCoachProfile = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();

  const handleRedirectToChat = () => {
    if (auth?.user?.id) {
      navigate(`/chat?type=physical`);
    } else {
      navigate("/login", { state: `/chat?type=physical` });
    }
  };
  return (
    <Fragment>
      <CustomHelmet
        title="Meet Vikram Kumar - Your AI Fitness Trainer - SSB-GPT"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
        <div className="fitnessCoachProfile_main txt_color">
          <div className="hero_section">
            <div className="left_sec">
              <div className="heading_top">Your AI Fitness Trainer</div>
              <h1 className="main_heading">
                Meet Vikram: Specialized Fitness{" "}
                <span>Training for SSB Aspirants</span>
              </h1>
              <p className="description">
                Vikram Kumar is a cutting-edge AI fitness trainer developed to
                guide you through the physical challenges of the SSB selection
                process. With an in-depth understanding of the physical
                standards required in the Indian Armed Forces, Vikram delivers
                personalized workout programs, diet plans, and mental toughness
                exercises to help you build the stamina, strength, and endurance
                needed to succeed.
              </p>
              <button className="talk_captain" onClick={handleRedirectToChat}>
                <FiMessageCircle /> Talk to Vikram
              </button>
            </div>
            <div className="right_sec">
              <div className="intro_modal_sec">
                <div className="profile_pic">
                  <img src={fitnessCoachProfilPic} alt="captain Alpha" />
                </div>
                <div className="name_sec">
                  <div className="name">Vikram Kumar</div>
                  <div className="desc">AI Fitness Trainer</div>
                </div>
              </div>
              <div className="social_links">
                <Link to="https://www.facebook.com/profile.php?id=61568212808425" title="Facebook" target="_blank">
                  <FaFacebookF />
                </Link>
                <Link to="https://x.com/AITrainerVikram" title="Twitter" target="_blank">
                  <FaXTwitter />
                </Link>
                <Link to="https://www.youtube.com/@AIVikramKumar" title="Youtube" target="_blank">
                  <FaYoutube />
                </Link>
                <Link to="#!" title="Instagram" target="_blank">
                  <FaInstagram />
                </Link>
              </div>
            </div>
          </div>

          <div className="effective_member">
            <h2 className="heading">
              Vikram Kumar: YOUR AI FITNESS TRAINER, AS EFFECTIVE AS A REAL{" "}
              <span>PHYSICAL INSTRUCTOR!</span>
            </h2>
            <p className="desc">
              Using advanced AI algorithms, Vikram simulates real-time SSB
              physical conditions, helping candidates adapt their fitness
              routines dynamically for optimal performance.
            </p>
            <div className="ai_img">
              <img src={fitness_coach_home} alt="fitness_coach_home" />
            </div>
          </div>

          <div className="next_level_prep">
            <h2 className="heading">
              EXPERIENCE ELITE-LEVEL{" "}
              <span>SSB FITNESS TRAINING AND GUIDANCE TODAY.</span>
            </h2>
            <div className="group_task_strategy">
              <div className="left_sec">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  Personalized Fitness <img src={fitness} alt="fitness" />{" "}
                  Plans.
                </h2>
                <p className="desc">
                  Based on your fitness level, Vikram designs custom plans to
                  target the physical requirements for SSB, including stamina,
                  strength, and agility.
                </p>
                <p className="desc">
                  Vikram has been designed with inputs from ex-military fitness
                  trainers and doctors, ensuring that every workout is tailored
                  to meet the rigorous standards of the SSB.
                </p>
                <button className="talk_btn" onClick={handleRedirectToChat}>
                  <GoPlus />
                  Talk You To Vikram Kumar
                </button>
              </div>
              <div className="right_sec">
                <div className="img_sec">
                  <img src={personalizedFitness} alt="personalizedFitness" />
                </div>
              </div>
            </div>

            <div className="core_expretise_sec">
              <div className="psychTestPrep">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  Daily Nutrition <img src={plan} alt="plan" /> Guidelines
                </h2>
                <p className="desc">
                  Get tailored diet plans that fuel your body for optimal
                  performance, recovery, and energy, aligned with your fitness
                  goals.
                </p>
                <img src={dailyNutrition} alt="dailyNutrition" />
              </div>
              <div className="interviewSkillsPersonality">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  AI Analytics <img src={analysis} alt="analysis" /> for Smarter
                  Training
                </h2>
                <p className="desc">
                  Vikram uses daily AI-driven analytics to assess your progress
                  and adjust your exercise plans for more effective training,
                  helping you stay on track for peak performance.
                </p>
                <img src={AIAnalytics} alt="AIAnalytics" />
              </div>
            </div>

            <div className="militaryAwareness">
              <div className="right_sec">
                <div className="img_sec">
                  <div className="circle_sec">
                    <div className="red"></div>
                    <div className="blue"></div>
                    <div className="yellow"></div>
                  </div>
                </div>
                <img src={vikramProgram} alt="vikramProgram" />
              </div>
              <div className="left_sec">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  What You’ll Get with Vikram’s Program
                </h2>
                <ol className="desc_list">
                  <li>
                    Daily Workout Plans: Structured routines that adapt as you
                    progress.
                  </li>
                  <li>
                    Diet & Nutrition Guidance: Tailored meal plans to fuel your
                    body for optimal performance.
                  </li>
                  <li>
                    Recovery & Flexibility: Guided cooldown routines,
                    stretching, and recovery exercises to prevent injuries.
                  </li>
                  <li>
                    Performance Tracking: Track your progress and receive
                    real-time feedback on improvements and areas to focus on.
                  </li>
                </ol>
                <button className="talk_btn" onClick={handleRedirectToChat}>
                  <GoPlus />
                  Talk you to Vikram Kumar
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default FitnessCoachProfile;
