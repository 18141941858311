import axios from "axios";
import {
  ATTACH_CHILD_FAIL,
  ATTACH_CHILD_REQUEST,
  ATTACH_CHILD_SUCCESS,
  CLEAR_ERROR_ATTACH_CHILD,
  CLEAR_STATE_ATTACH_CHILD,
  DELETE_CHILD_FAIL,
  DELETE_CHILD_REQUEST,
  DELETE_CHILD_SUCCESS,
  GET_CHILDS_FAIL,
  GET_CHILDS_REQUEST,
  GET_CHILDS_SUCCESS,
  UPDATE_CHILD_FAIL,
  UPDATE_CHILD_REQUEST,
  UPDATE_CHILD_SUCCESS,
} from "../constants/attachChildConstant";

// ATTACH CHILD ACTION
export const attachChildAction =
  (childName, childEmail) => async (dispatch) => {
    try {
      dispatch({
        type: ATTACH_CHILD_REQUEST,
      });

      const localStorageToken = localStorage.getItem("ssb-token")
        ? JSON.parse(localStorage.getItem("ssb-token"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/ssb-gpt/attach-child`,
        {
          childName,
          childEmail,
        },
        config
      );
      dispatch({
        type: ATTACH_CHILD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ATTACH_CHILD_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// GET CHILD ACTION
export const getChildsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CHILDS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/ssb-gpt/my-childs`,
      config
    );
    dispatch({
      type: GET_CHILDS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CHILDS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// UPDATE CHILD ACTION
export const updateChildAction =
  (childId, childName, childEmail) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_CHILD_REQUEST,
      });

      const localStorageToken = localStorage.getItem("ssb-token")
        ? JSON.parse(localStorage.getItem("ssb-token"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/ssb-gpt/update-child/${childId}`,
        {
          childName,
          childEmail,
        },
        config
      );
      dispatch({
        type: UPDATE_CHILD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CHILD_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// UPDATE CHILD ACTION
export const deleteChildAction = (childId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_CHILD_REQUEST,
    });

    const localStorageToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API}/ssb-gpt/delete-child/${childId}`,
      config
    );
    dispatch({
      type: DELETE_CHILD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CHILD_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearChildState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE_ATTACH_CHILD });
};

// CLEAR STATE ACTION FUNCTION
export const clearChildErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR_ATTACH_CHILD });
};
