import axios from "axios";
import {
  CLEAR_ERROR_SCHOOL,
  CLEAR_STATE_SCHOOL,
  CREATE_SCHOOL_FAIL,
  CREATE_SCHOOL_REQUEST,
  CREATE_SCHOOL_SUCCESS,
  GET_SCHOOL_FAIL,
  GET_SCHOOL_REQUEST,
  GET_SCHOOL_SUCCESS,
  MYREF_STUDENTS_FAIL,
  MYREF_STUDENTS_REQUEST,
  MYREF_STUDENTS_SUCCESS,
  UPDATE_SCHOOL_FAIL,
  UPDATE_SCHOOL_REQUEST,
  UPDATE_SCHOOL_SUCCESS,
} from "../constants/schoolByTeacherConstant";

// CREATE SCHOOL BY TEACHER ACTION
export const createSchoolAction = (name, address, bio, infoFile, logo) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SCHOOL_REQUEST,
    });

    const localStorageToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : "";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    // Create a new FormData object and append the file
    const formData = new FormData();
    formData.append("name", name);
    formData.append("address", address);
    formData.append("bio", bio);
    formData.append("infoFile", infoFile);
    formData.append("logo", logo);

    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/ssb-gpt/create-school`,
      formData,
      config
    );
    dispatch({
      type: CREATE_SCHOOL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SCHOOL_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// GET SCHOOL BY TEACHER ACTION
export const getSchoolAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SCHOOL_REQUEST,
    });

    const localStorageToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/ssb-gpt/get-school`,
      config
    );
    dispatch({
      type: GET_SCHOOL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SCHOOL_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// UPDATE SCHOOL BY TEACHER ACTION
export const updateSchoolAction = (name, address, bio, infoFile, logo) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_SCHOOL_REQUEST,
      });
  
      const localStorageToken = localStorage.getItem("ssb-token")
        ? JSON.parse(localStorage.getItem("ssb-token"))
        : "";
  
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };
  
      // Create a new FormData object and append the file
      const formData = new FormData();
      formData.append("name", name);
      formData.append("address", address);
      formData.append("bio", bio);
      formData.append("infoFile", infoFile);
      formData.append("logo", logo);
  
      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/ssb-gpt/update-school`,
        formData,
        config
      );
      dispatch({
        type: UPDATE_SCHOOL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SCHOOL_FAIL,
        payload: error?.response?.data?.message,
      });
    }
};


// GET SCHOOL BY TEACHER ACTION
export const getMyRefStudentsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: MYREF_STUDENTS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/ssb-gpt/my-students`,
      config
    );
    dispatch({
      type: MYREF_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MYREF_STUDENTS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearSchoolState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE_SCHOOL });
};

// CLEAR STATE ACTION FUNCTION
export const clearSchoolErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR_SCHOOL });
};
