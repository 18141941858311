import React, { Fragment, useEffect, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import GptSidebar from "./gptSidebar";
import { Link } from "react-router-dom";
import { TiArrowUp } from "react-icons/ti";
import bookService from "../../services/bookService";
import { useAuth } from "../../context/userContext";
import Loader from "../reusable/Loader";
import audioBell from "../../audio/notification-bell.wav";
import GptBooks from "./gptBooks";
import WhatCanDo from "./WhatCanDo";
import CustomHelmet from "../reusable/Helmet";
// import userService from "../../services/userService";
import "../../styles/psychology.scss";
import {
  practicetatPpdt,
  practiceWatSrt,
  unlimitedMockInterviews,
} from "../../assets/images";
import moment from "moment";

const tatImages = [
  {
    id: 1,
    image: practicetatPpdt,
  },
  {
    id: 2,
    image: practiceWatSrt,
  },
  {
    id: 3,
    image: unlimitedMockInterviews,
  },
];

const wordArray = [
  {
    id: 1,
    word: "Efficiency",
  },
  {
    id: 2,
    word: "Practice",
  },
  {
    id: 3,
    word: "Dcipline",
  },
  // {
  //   id: 4,
  //   word: "Dcipline",
  // },
  // {
  //   id: 5,
  //   word: "Dcipline",
  // },
  // {
  //   id: 6,
  //   word: "Dcipline",
  // },
  // {
  //   id: 7,
  //   word: "Dcipline",
  // },
];

const srtArray = [
  {
    id: 1,
    srt: "What your goal",
  },
  {
    id: 2,
    srt: "What do you do as a indian team captain",
  },
  {
    id: 3,
    srt: "You stuck in mud whats your reaction",
  },
  {
    id: 4,
    srt: "You see accident",
  },
  {
    id: 5,
    srt: "Some people torcher a boy",
  },
  {
    id: 6,
    srt: "Ragging in front of you",
  },
  {
    id: 7,
    srt: "Dcipline",
  },
];

const sdtArray = [
  {
    id: 1,
    sdt: "What do your parents think about you?",
  },
  {
    id: 2,
    sdt: "What do your Teachers/Seniors think about you?",
  },
  {
    id: 3,
    sdt: "What do your Friends/Colleagues think about you?",
  },
  {
    id: 4,
    sdt: "What do you think about yourself? ",
  },
  {
    id: 5,
    sdt: "What sort of a person you want to become in life?",
  },
];
const notification = new Audio(audioBell);
const PsychologyTest = () => {
  const [auth] = useAuth();

  const [activeStep, setActiveStep] = useState(11);
  const [outputData, setOutputData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [authLoading, setAuthLoading] = useState(false);
  const [bookList, setBookList] = useState([]);
  // const [saveValue, setSaveValue] = useState();

  const [isLoading] = useState(false);

  const savedList = localStorage.getItem("userHistory");

  // HANDLE TAT IMAGE TEST STATE  STARTS

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [tatTimer, setTatTimer] = useState(5);
  const [typingTimer, setTypingTimer] = useState(10);
  const [showImage, setShowImage] = useState(true);
  const [showTypingText, setShowTypingText] = useState(false);

  const handleTatStart = async () => {
    // setIsLoading(true);
    setActiveStep(activeStep + 1);
    // const query = "TAT";
    // try {
    //   const apiResponseData = await userService.generateTatImage(query);
    //   if (apiResponseData) {
    //     setTatImageList(apiResponseData?.data?.data);
    //     setIsLoading(false);
    //   }
    // } catch (error) {
    //   setIsLoading(false);
    // }
  };

  useEffect(() => {
    let timer;
    if (
      activeStep === 3 &&
      tatImages?.length > 0 &&
      currentImageIndex < tatImages.length
    ) {
      if (showImage) {
        if (tatTimer > 0) {
          timer = setTimeout(() => setTatTimer(tatTimer - 1), 1000);
        } else {
          notification.play();
          setShowImage(false);
          setShowTypingText(true);
          setTypingTimer(10);
        }
      } else if (showTypingText) {
        if (typingTimer > 0) {
          timer = setTimeout(() => setTypingTimer(typingTimer - 1), 1000);
        } else {
          if (currentImageIndex < tatImages.length - 1) {
            notification.play();
            setShowImage(true);
            setShowTypingText(false);
            setTatTimer(5);
            setCurrentImageIndex(currentImageIndex + 1);
          } else {
            setActiveStep(4);
            notification.play();
          }
        }
      }
    }

    return () => clearTimeout(timer);
  }, [
    activeStep,
    currentImageIndex,
    tatTimer,
    typingTimer,
    showImage,
    showTypingText,
  ]);
  // HANDLE TAT IMAGE TEST STATE  ENDS

  // HANDLE WAT  TEST STARTS
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [watTimer, setWatTimer] = useState(5);

  const handleWatStart = async () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    let timer;

    if (
      activeStep === 5 &&
      wordArray?.length > 0 &&
      currentWordIndex < wordArray.length
    ) {
      if (watTimer > 0) {
        timer = setTimeout(() => setWatTimer(watTimer - 1), 1000);
      } else {
        if (currentWordIndex < wordArray.length - 1) {
          setWatTimer(5);
          notification.play();
          setCurrentWordIndex(currentWordIndex + 1);
        } else {
          setActiveStep(activeStep + 1);
          notification.play();
        }
      }
    }

    return () => clearTimeout(timer);
  }, [activeStep, currentWordIndex, watTimer]);
  // HANDLE WAT TEST ENDS

  // HANDLE SRT TEST STARTS
  const [srtTimer, setSrtTimer] = useState(10);

  const handleSrtStart = async () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    let timer;
    if (activeStep === 7 && srtArray?.length > 0) {
      if (srtTimer > 0) {
        timer = setTimeout(() => setSrtTimer(srtTimer - 1), 1000);
      } else {
        setActiveStep(activeStep + 1);
        notification.play();
      }
    }

    return () => clearTimeout(timer);
  }, [activeStep, srtTimer]);
  // HANDLE SRT TEST ENDS

  // HANDLE SDT TEST STARTS
  const [sdtTimer, setSdtTimer] = useState(10);

  const handleSdtStart = async () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    let timer;
    if (activeStep === 9 && sdtArray?.length > 0) {
      if (sdtTimer > 0) {
        timer = setTimeout(() => setSdtTimer(sdtTimer - 1), 1000);
      } else {
        setActiveStep(activeStep + 1);
        notification.play();
      }
    }

    return () => clearTimeout(timer);
  }, [activeStep, sdtTimer]);
  // HANDLE SDT TEST ENDS

  useEffect(() => {
    if (savedList) {
      const data = JSON.parse(savedList);

      const today = new Date().toISOString().split("T")[0];
      const searchHistory = data.find(
        (item) => item.dayData === today
      )?.searchData;
      if (searchHistory) {
        setOutputData(searchHistory);
      }

      setQuestionList(data);
    }
  }, [savedList]);

  const fetchBookList = async () => {
    try {
      setAuthLoading(true);
      const res = await bookService.myAllBookList();
      if (res) {
        setAuthLoading(false);
        setBookList(res?.data?.books);
      }
    } catch (error) {
      setAuthLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.user?._id) {
      fetchBookList();
    }
  }, [auth?.user?._id]);

  // const handleSave = (idx) => {
  //   setSaveValue(idx);
  // };

  const handleShowHistory = (date) => {
    const extractedData = questionList
      ?.filter((ele) => ele?.dayData === date)
      .map((item) => item.searchData)
      .flat();
    setOutputData(extractedData);
  };

  // SCROLL HER PAGE AFTER EVERY SEARCH STARTS
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current && outputData?.length > 0) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [outputData]);

  const initialState = Array.from({ length: 60 }, (_, i) => ({
    question: `TAT ${i + 1}`,
    answer: "",
  }));

  const [answers, setAnswers] = useState(initialState);

  const handleInputChange = (index, value) => {
    setAnswers((prevAnswers) => {
      prevAnswers[index].answer = value;
      return [...prevAnswers];
    });
  };

  const formatTime = (timeInSeconds) => {
    const minutes = moment.duration(timeInSeconds, "seconds").minutes();
    const seconds = moment.duration(timeInSeconds, "seconds").seconds();
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSubmitSrt = () => {};

  return authLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
        <section className="gpt_layout_wrapper">
          <GptSidebar setOutputData={setOutputData} />
          <section className="gpt_Search_box">
            <div className="input_box">
              <div className="srch_output custonScroll" ref={scrollRef}>
                {activeStep === 1 && (
                  <div className="instructions">
                    <h2>Instructions :</h2>
                    <p>
                      Welcome to the psychology test battery. This assessment is
                      designed to evaluate your personality, cognitive
                      abilities, and behavioral tendencies
                    </p>
                    <h4>The test battery consists of several components:</h4>
                    <p>
                      <span>1.</span>
                      <span>
                        <strong>Thematic Apperception Test (TAT)</strong> : You
                        will be shown 12 images, one at a time, and asked to
                        write stories about each image.
                      </span>
                    </p>
                    <p>
                      <span>2.</span>

                      <span>
                        <strong>Word Association Test (WAT)</strong>: You will
                        be presented with a series of words and asked to write a
                        sentence in response to each word.
                      </span>
                    </p>
                    <p>
                      <span>3.</span>
                      <span>
                        <strong>Situation Reaction Test (SRT)</strong> : You
                        will be given a series of hypothetical situations and
                        asked to write how you would react.
                      </span>
                    </p>
                    <p>
                      <span>4.</span>
                      <span>
                        <strong>Self-Description Test (SDT)</strong> : You'll be
                        asked to share your parents' and teachers' opinions, as
                        well as your own.
                      </span>
                    </p>
                    <h4>Please follow these general instructions:</h4>
                    <p>
                      <span>-</span> Listen carefully to the instructions for
                      each test.
                    </p>
                    <p>
                      <span>-</span> Read each question or prompt carefully.
                    </p>
                    <p>
                      <span>-</span> Answer honestly and spontaneously.
                    </p>
                    <p>
                      <span>-</span> Don't worry about right or wrong answers.
                    </p>
                    <p>
                      <span>-</span> We are interested in your thoughts,
                      feelings, and behaviors.
                    </p>
                    <h4>Additional guidelines:</h4>
                    <p>
                      <span>-</span> Work at your own pace, but manage your time
                      effectively.
                    </p>
                    <p>
                      <span>-</span> Write clearly and legibly.
                    </p>
                    <p>
                      <span>-</span> Please note that you will not be allowed to
                      take breaks in between tests. Once you start, you must
                      complete the entire test battery in one sitting.
                    </p>
                    <p>
                      Remember, this assessment is designed to help us
                      understand your personality, strengths, and weaknesses. Be
                      yourself, and respond honestly.
                    </p>
                    <div className="btn_wrap">
                      <button onClick={() => setActiveStep(activeStep + 1)}>
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {/* TAT TEST SECTION STARTS  */}
                {activeStep === 2 && (
                  <div className="instructions">
                    <h2>Instructions:</h2>
                    <p>
                      In the Thematic Apperception Test (TAT), you'll be shown
                      12 images, one at a time. Each image will appear for 30
                      seconds, after which you'll have 4 minutes to write a
                      story based on the image.
                    </p>
                    <h4>Please follow these instructions:</h4>
                    <p>
                      <span>1. </span>
                      <span>
                        Carefully observe the image during the 30-second
                        display.
                      </span>
                    </p>
                    <p>
                      <span>2.</span>{" "}
                      <span>
                        Once the image disappears, write a story covering these
                        points: What is happening in the image? What led to this
                        situation? What is happening now? What will happen next?
                        How do the characters feel?
                      </span>
                    </p>
                    <p>
                      <span>3. </span>
                      <span>
                        You will repeat this process for 11 images. After the
                        11th image, you will be shown a blank screen. For the
                        12th story, please write about any image that comes to
                        your mind at that moment. This can be any scene,
                        situation, or picture that you imagine.
                      </span>
                    </p>
                    <h4>Remember:</h4>
                    <p>
                      <span>-</span> There are no right or wrong answers.
                    </p>
                    <p>
                      <span>-</span> We are interested in your thoughts and
                      feelings.
                    </p>
                    <p>
                      <span>-</span> Please write your stories clearly and
                      legibly.
                    </p>
                    <p>
                      <span>-</span> You have 4 minutes to write each story.
                    </p>
                    <p>
                      <span>-</span> Take your time, and don't worry if you need
                      a moment to think.
                    </p>
                    <p className="imp">Let's begin.</p>
                    <div className="btn_wrap">
                      <button onClick={() => handleTatStart()}>
                        Start TAT
                      </button>
                    </div>
                  </div>
                )}
                {activeStep === 3 && (
                  <>
                    {isLoading ? (
                      <div className="tat_test_msg">
                        <p>
                          Your Test is preparing. It will take upto 10-20
                          seconds. <br />
                          Do not refresh the page in case you may loss data
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="time_box">
                          <h1>TAT</h1>
                          <p>
                            {showImage
                              ? formatTime(tatTimer)
                              : formatTime(typingTimer)}
                          </p>
                        </div>
                        <div className="test_box">
                          {showImage && tatImages[currentImageIndex] && (
                            <>
                              <img
                                src={tatImages[currentImageIndex].image}
                                alt="tatimage"
                              />
                            </>
                          )}
                          {showTypingText && (
                            <div>
                              <strong>Start Writing</strong>
                            </div>
                          )}

                          {/* */}
                        </div>
                      </>
                    )}
                  </>
                )}
                {/* TAT TEST SECTION ENDS */}

                {/* WAT SECTION STARTS  */}
                {activeStep === 4 && (
                  <div className="instructions">
                    <h2>Instructions :</h2>
                    <p>
                      Welcome to the Word Association Test (WAT). This test is
                      designed to assess your thoughts, feelings, and
                      associations with different words.
                    </p>
                    <h4>Here's how the test works:</h4>
                    <p>
                      <span>-</span>
                      <span>
                        You will be shown a series of 60 words, one at a time.
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>Each word will be displayed for 15 seconds.</span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>
                        As soon as you see the word, write down a complete
                        sentence that comes to mind in response to the word.
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>
                        The sentence should be your immediate reaction to the
                        word.
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>
                        Please note that while this is not a language
                        proficiency test, clear and correct expression of your
                        thoughts is important. Avoid grammatical and spelling
                        mistakes as they may reflect on your power of expression
                        in written format.
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>Write only one sentence per word.</span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>Be honest and spontaneous in your responses.</span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>
                        Don't think too much or try to analyze the word.
                      </span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>Just react with your first thought.</span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>
                        Keep writing until all 60 words have been displayed.
                      </span>
                    </p>

                    <p>
                      Remember, this test is designed to understand your
                      thoughts and associations, so be yourself and respond
                      naturally.
                    </p>

                    <div className="btn_wrap">
                      <button onClick={() => handleWatStart()}>
                        Start WAT
                      </button>
                    </div>
                  </div>
                )}
                {activeStep === 5 && (
                  <>
                    {isLoading ? (
                      <div className="tat_test_msg">
                        <p>
                          Your Test is preparing. It will take upto 10-20
                          seconds. <br />
                          Do not refresh the page in case you may loss data
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="time_box">
                          <h1>WAT</h1>
                          <p>{formatTime(watTimer)}</p>
                        </div>
                        <div className="test_box">
                          {wordArray[currentWordIndex] && (
                            <div>
                              <h4>{wordArray[currentWordIndex].word}</h4>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
                {/* WAT SECTIONS ENDS */}

                {/* SRT SECTION STARTS */}
                {activeStep === 6 && (
                  <div className="instructions">
                    <h2>Instructions :</h2>
                    <p>
                      Welcome to the Situation Reaction Test (SRT). This test is
                      designed to assess how you would react in different social
                      and personal situations.
                    </p>
                    <h4>Here's how the test works:</h4>
                    <p>
                      <span>-</span>
                      <span>
                        You will be given a series of 60 situations, one at a
                        time.
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>
                        Each situation will be described in a few sentences
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>
                        You will have to write how you would react in that
                        situation.
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>
                        Please respond as if you were actually in that
                        situation.
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>Be honest and spontaneous in your responses.</span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>
                        Don't think too much or try to analyze the situation.
                      </span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>Just react with your first thought.</span>
                    </p>
                    <h4>Important Instructions :</h4>
                    <p>
                      <span>-</span>
                      <span>Write only one reaction per situation.</span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>Be specific and clear in your responses.</span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>Avoid vague or general answers.</span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>
                        Show how you would actually behave in the situation.
                      </span>
                    </p>

                    <h4>Time Limit :</h4>
                    <p>
                      <span>-</span>
                      <span>
                        You have 30 minutes to complete all 60 situations.
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>
                        Manage your time effectively to ensure you respond to
                        all situations.
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>Avoid vague or general answers.</span>
                    </p>

                    <p>
                      Remember, this test is designed to understand how you
                      would react in different situations, so be yourself and
                      respond naturally.
                    </p>

                    <div className="btn_wrap">
                      <button onClick={() => handleSrtStart()}>
                        Start SRT
                      </button>
                    </div>
                  </div>
                )}
                {activeStep === 7 && (
                  <>
                    {isLoading ? (
                      <div className="tat_test_msg">
                        <p>
                          Your Test is preparing. It will take upto 10-20
                          seconds. <br />
                          Do not refresh the page in case you may loss data
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="time_box">
                          <h1>SRT</h1>
                          <p>{formatTime(srtTimer)}</p>
                        </div>
                        <div className="test_box">
                          {srtArray?.map((ele, idx) => (
                            <p className="srt" key={idx}>
                              {idx + 1}. {ele?.srt}
                            </p>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
                {/* SRT SECTION STARTS */}

                {/* SDT TEST STARTS  */}
                {activeStep === 8 && (
                  <div className="instructions">
                    <h2>Instructions :</h2>
                    <p>
                      Welcome to the Self-Description Test (SDT). This test is
                      designed to help us understand your self-perception,
                      strengths, weaknesses, and relationships.
                    </p>
                    <h4>Here's how the test works:</h4>
                    <p>
                      <span>-</span>
                      <span>You will be asked to write five descriptions:</span>
                    </p>
                    <p>
                      <span>1)</span>
                      <span>What do your parents think about you?</span>
                    </p>
                    <p>
                      <span>2)</span>
                      <span>
                        What do your Teachers/Seniors think about you?
                      </span>
                    </p>
                    <p>
                      <span>3)</span>
                      <span>
                        What do your Friends/Colleagues think about you?
                      </span>
                    </p>
                    <p>
                      <span>4)</span>
                      <span>What do you think about yourself?</span>
                    </p>
                    <p>
                      <span>5)</span>
                      <span>
                        What sort of a person you want to become in life?
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>
                        Please be honest and candid in your descriptions
                      </span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>Write each description in the space provided</span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>Be specific and clear in your responses</span>
                    </p>
                    <p>
                      <span>-</span>
                      <span>Avoid vague or general answers</span>
                    </p>

                    <h4>Important Instructions :</h4>
                    <p>
                      <span>-</span>
                      <span>
                        Write each description from the perspective of the
                        person/group mentioned
                      </span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>
                        Focus on your strengths, weaknesses, and personality
                        traits
                      </span>
                    </p>

                    <p>
                      <span>-</span>
                      <span>Be concise and to the point</span>
                    </p>

                    <h4>Time Limit :</h4>
                    <p>
                      <span>-</span>
                      <span>
                        You have 15 minutes to complete all five descriptions
                      </span>
                    </p>

                    <p>
                      <strong>Note: </strong>
                      If you're not working, write about the opinions of your
                      parents, teachers, friends, your own thoughts, and the
                      person you aspire to be. If you're working, write about
                      the opinions of your parents, seniors, colleagues, your
                      own thoughts, and the person you want to become
                    </p>

                    <p>
                      Remember, this test is designed to help us understand your
                      self-awareness and relationships, so be yourself and
                      respond naturally.
                    </p>

                    <div className="btn_wrap">
                      <button onClick={() => handleSdtStart()}>
                        Start SDT
                      </button>
                    </div>
                  </div>
                )}

                {activeStep === 9 && (
                  <>
                    {isLoading ? (
                      <div className="tat_test_msg">
                        <p>
                          Your Test is preparing. It will take upto 10-20
                          seconds. <br />
                          Do not refresh the page in case you may loss data
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="time_box">
                          <h1>SDT</h1>
                          <p>{formatTime(sdtTimer)}</p>
                        </div>
                        <div className="test_box">
                          {sdtArray?.map((ele, idx) => (
                            <p key={idx}>
                              {idx + 1}. {ele?.sdt}
                            </p>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
                {/* SDT TEST ENDS */}

                {activeStep === 10 && (
                  <div className="test_box">
                    <p>
                      Congratulations on completing your Psychology test! You
                      can now submit your test for analysis and receive your
                      results. Once you click the upload button, you'll be
                      guided to upload all your tests one by one. Please note
                      that you won't be able to access this area again once this
                      tab is closed, so be sure to load your test now.
                    </p>
                    <div className="upload_btn">
                      <button onClick={() => setActiveStep(activeStep + 1)}>
                        Upload
                      </button>
                    </div>
                  </div>
                )}

                {/* {activeStep === 11 && (
                  <>
                    {outputData?.length > 0 &&
                      outputData?.map((ele, idx) => (
                        <>
                          {ele?.srtList?.length > 0 && (
                            <h5>
                              {ele?.srtList?.map((itm, id) => (
                                <span key={id}>
                                  {id + 1}. {itm}
                                </span>
                              ))}
                              <div className="action_btn">
                                <span onClick={() => handleSave(idx)}>
                                  <LuBookmark />
                                  Save
                                </span>
                                <span>
                                  <AiFillDislike />
                                </span>
                                <span>
                                  <AiFillLike />
                                </span>
                              </div>
                            </h5>
                          )}
                        </>
                      ))}
                  </>
                )} */}
              </div>

              <div className="answer_input_gpt feedback_input">
                {activeStep === 11 && (
                  <div>
                    <div className="input_index custonScroll">
                      {/* {Array.from({ length: 60 }, (_, i) => (
                        <div key={i}>
                          <span>Ans {i + 1} :</span>
                          <textarea
                            value={answers[i].answer}
                            onChange={(e) =>
                              handleInputChange(i, e.target.value)
                            }
                            placeholder="Write your answer"
                          />
                        </div>
                      ))} */}

                      <div>
                        <span>Ans 1:</span>
                        <textarea
                          value={answers[1].answer}
                          onChange={(e) => handleInputChange(1,e.target.value)}
                          placeholder="Write your answer"
                        />
                      </div>
                    </div>
                    <button
                      className="search"
                      onClick={() => handleSubmitSrt()}
                    >
                      <TiArrowUp />
                    </button>
                  </div>
                )}
                <p className="advice_txt">
                  {/* SSB-GPT can make mistakes. We suggest seeking advice from
                  experts.  */}
                  Read our <Link to="/term-of-use">Terms</Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </div>
            </div>
          </section>

          <GptBooks
            bookList={bookList}
            fetchBookList={fetchBookList}
            // handleSave={saveValue}
            outputData={outputData}
            // activeTab={activeTab}
            questionList={questionList}
            handleShowHistory={handleShowHistory}
          />
        </section>

        <WhatCanDo />
        </div>
      </GptLayout>
      {/* {mediaFile?.length > 0 && (
                  <div className="test_start">
                    {(showTypingText || showImage) &&
                      currentImageIndex < mediaFile?.length && (
                        <div className="test_start">
                          {showTypingText &&
                            currentImageIndex <= mediaFile?.length && (
                              <p>Start writing...</p>
                            )}
                          {showImage &&
                            currentImageIndex < mediaFile?.length && (
                              <div className="question_list">
                                <img
                                  src={
                                    mediaFile[currentImageIndex]?.mediaImage
                                  }
                                  alt={
                                    mediaFile[currentImageIndex]?.mediaImage
                                  }
                                />
                              </div>
                            )}
                        </div>
                      )}
                  </div>
                )} */}
    </Fragment>
  );
};

export default PsychologyTest;
