import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { bearCrwal } from "../../../assets/images";

const BearCrawlGuidance = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do a Bear Crawl</h2>
          <p>
            The Bear Crawl is an effective full-body exercise that targets your
            core, shoulders, arms, and legs. It’s great for building strength,
            stability, and endurance. Here's a step-by-step guide to doing the
            Bear Crawl properly:
          </p>
          <br />
          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/780CAAkwjMo"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1:Start in the Right Position</h4>

          <span>
            1.Begin on all fours, with your hands placed directly under your
            shoulders and your knees under your hips.
          </span>

          <span>
            2.Keep your spine neutral—avoid arching your back or letting your
            belly drop.
          </span>
          <span>
            3. Your toes should be tucked, and your knees should be hovering
            just above the ground (about an inch or two).
          </span>

          <img src={bearCrwal} alt="bearCrwal" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Lift Your Knees</h4>
          <span>
            1. Engage your core and raise your knees a couple of inches off the
            ground.
          </span>
          <span>
            2. At this point, your body should form a straight line from your
            head to your knees.
          </span>
          <span>
            3. Maintain a stable core, keeping your hips level with your
            shoulders.
          </span>

          <br />
          <h4 className="subHeading">
            Step 3: Move Your Right Hand and Left Foot
          </h4>
          <span>
            1. Begin crawling by moving your right hand forward while
            simultaneously moving your left foot forward. This is similar to the
            cross-body motion when walking or running.
          </span>

          <span>
            2. Keep your movements slow and controlled—avoid jerking or rushing
            through the movement.
          </span>

          <br />
          <h4 className="subHeading">
            Step 4: Move Your Left Hand and Right Foot
          </h4>
          <span>
            1. Follow the same pattern as the first step by moving your left
            hand forward and right foot forward at the same time.
          </span>

          <span>
            2. As you crawl, focus on maintaining stability in your torso and
            keeping your body as flat and horizontal as possible.
          </span>
          <br />
          <h4 className="subHeading">Step 5: Maintain a Steady Pace</h4>
          <span>
            1. Keep a steady rhythm as you continue to crawl forward. The Bear
            Crawl should be done in a controlled, deliberate manner, with focus
            on your form.
          </span>

          <br />
          <h4 className="subHeading">Step 6: Turn Around (If Needed)</h4>
          <span>
            1. If you reach the end of your space, turn around by carefully
            shifting your weight and repeating the crawl in the opposite
            direction.
          </span>

          <br />
          <h4 className="subHeading">Step 7: Finish</h4>
          <span>
            1. When you’re done, slowly lower your knees to the ground and rest.
          </span>

          <br />

          <h4 className="subHeading">Key Tips for Bear Crawl:</h4>
          <span>
            {"a)."} <strong>Keep Your Core Tight: </strong> Engage your core
            throughout the movement to maintain a stable and neutral spine. This
            will prevent your lower back from sagging and help you move more
            efficiently.
          </span>
          <span>
            {"b)."} <strong>Use Full Range of Motion: </strong> Don’t rush
            through the movement. Make sure your limbs extend fully as you
            crawl.
          </span>
          <span>
            {"c)."} <strong>Breath Control:</strong>
            Breathe steadily throughout the exercise. Avoid holding your breath,
            which can lead to tension and fatigue.
          </span>
          <span>
            {"d)."} <strong> Head Position:</strong>
            Keep your head aligned with your spine—don’t tilt it upward or drop
            it down.
          </span>
          <span>
            {"e)."} <strong>Keep Your Hips Level:</strong>
            Avoid letting your hips rise too high, as this can reduce the
            effectiveness of the movement.
          </span>

          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong>Sagging Hips: </strong>If your hips drop too low,
            your lower back may become strained. Keep your hips level with your
            shoulders.
          </span>
          <span>
            {"b)."} <strong>Fast Movement:</strong> Rushing through the Bear
            Crawl can compromise your form. Focus on quality movement over
            speed.
          </span>
          <span>
            {"c)."} <strong> Poor Hand Placement: </strong>
            Your hands should be directly beneath your shoulders, and not too
            wide or narrow, to ensure proper support.
          </span>
          <br />
          <h4 className="subHeading">Benefits of Bear Crawl:</h4>
          <span>
            {"a)."} Full-body engagement, building strength and endurance.
          </span>
          <span>{"b)."} Core stability improvement.</span>
          <span>{"c)."} Enhances coordination and balance.</span>
          <span>
            {"d)."} Works multiple muscle groups simultaneously, including the
            shoulders, chest, arms, back, core, and legs
          </span>
          <br />
          <p>
            The Bear Crawl is a simple but powerful exercise that can be added
            to any workout routine to build functional strength and stability.
            Give it a try!
          </p>
          <br />
        </div>
      </section>
    </Fragment>
  );
};

export default BearCrawlGuidance;
