import React, { Fragment } from "react";
import "../../styles/medicalExpertProfile.scss";
import CustomHelmet from "../reusable/Helmet";
import GptLayout from "../ssbGpt/gptLayout";
import { FiMessageCircle } from "react-icons/fi";
import chatPersonalAIDoctor from "../../images/chatPersonalAIDoctor.png";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import betterHealth from "../../images/icons/betterHealth.png";
import heartProblem from "../../images/icons/heartProblem.png";
import stethoscope from "../../images/icons/stethoscope.png";
import medicalScreening from "../../images/medicalScreening.jpg";
import betterHealthBanner from "../../images/betterHealthBanner.jpg";
import heartProblemBanner from "../../images/heartProblemBanner.jpg";
import ravinderProgramList from "../../images/ravinderProgramList.png";
import { GoPlus } from "react-icons/go";
import { medicalModal } from "../../assets/images";
import { useAuth } from "../../context/userContext";

const MedicalExpertProfile = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();

  const handleRedirectToChat = () => {
    if (auth?.user?.id) {
      navigate(`/chat?type=medical`);
    } else {
      navigate("/login", { state: `/chat?type=medical` });
    }
  };
  return (
    <Fragment>
      <CustomHelmet
        title="Meet Ravinder Yadav: Your AI Military Doctor - SSB-GPT"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
        <div className="medicalExpertProfile_main txt_color">
          <div className="hero_section">
            <div className="left_sec">
              <div className="heading_top">Your AI Military Doctor</div>
              <h1 className="main_heading">
                Meet Dr. Ravinder Yadav <span>Your AI Medical Guide</span>
              </h1>
              <p className="description">
                Dr. Ravinder Yadav is an advanced AI-powered medical expert
                developed to help candidates prepare thoroughly for the medical
                standards required to join the Indian Armed Forces. With
                insights from seasoned military doctors, Dr. Yadav provides
                guidance on everything from fitness standards to specific
                medical checks, ensuring you’re fully prepared for the medical
                assessment phase of the selection process.
              </p>
              <button className="talk_captain" onClick={handleRedirectToChat}>
                <FiMessageCircle /> Talk to Ravinder Yadav
              </button>
            </div>
            <div className="right_sec">
              <div className="intro_modal_sec">
                <div className="profile_pic">
                  <img src={medicalModal} alt="medicalModal" />
                </div>
                <div className="name_sec">
                  <div className="name">Ravinder Yadav</div>
                  <div className="desc">AI Doctor</div>
                </div>
              </div>
              <div className="social_links">
                <Link to="https://www.facebook.com/profile.php?id=61568163640272" title="Facebook" target="_blank">
                  <FaFacebookF />
                </Link>
                <Link to="https://x.com/AIRavinder" title="Twitter" target="_blank">
                  <FaXTwitter />
                </Link>
                <Link to="https://www.youtube.com/@AIRavinderYadav" title="Youtube" target="_blank">
                  <FaYoutube />
                </Link>
                <Link to="https://www.instagram.com/aidoctorravinder/" title="Instagram" target="_blank">
                  <FaInstagram />
                </Link>
              </div>
            </div>
          </div>

          <div className="effective_member">
            <h2 className="heading">
              Dr. Ravinder Yadav: YOUR AI MEDICAL GUIDE, AS RELIABLE AS A{" "}
              <span>PERSONAL MILITARY DOCTOR!</span>
            </h2>
            <p className="desc">
              Advanced AI offers comprehensive health assessments, helping
              candidates prepare dynamically to meet armed forces medical
              standards.
            </p>
            <div className="ai_img">
              <img src={chatPersonalAIDoctor} alt="chatPersonalAIDoctor" />
            </div>
          </div>

          <div className="next_level_prep">
            <h2 className="heading">
              EXPERIENCE ELITE-LEVEL{" "}
              <span>MEDICAL PREPARATION AND GUIDANCE TODAY.</span>
            </h2>
            <div className="group_task_strategy">
              <div className="left_sec">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  Comprehensive Medical{" "}
                  <img src={stethoscope} alt="stethoscope" /> Screening
                </h2>
                <p className="desc">
                  Dr. Yadav provides virtual assessments covering key areas such
                  as vision, hearing, posture, and cardiovascular health,
                  ensuring that you meet the rigorous physical standards.
                </p>
                <button className="talk_btn" onClick={handleRedirectToChat}>
                  <GoPlus />
                  Talk You To Ravinder Yadav
                </button>
              </div>
              <div className="right_sec">
                <div className="img_sec">
                  <img src={medicalScreening} alt="medicalScreening" />
                </div>
              </div>
            </div>

            <div className="core_expretise_sec">
              <div className="psychTestPrep">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  Tailored Fitness and Health{" "}
                  <img src={betterHealth} alt="betterHealth" /> Recommendations
                </h2>
                <p className="desc">
                  Get personalized advice to maintain or improve your health
                  based on your current fitness levels and medical requirements.
                </p>
                <img src={betterHealthBanner} alt="betterHealthBanner" />
              </div>
              <div className="interviewSkillsPersonality">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  Guidance on Common Medical Issues{" "}
                  <img src={heartProblem} alt="heartProblem" />
                </h2>
                <p className="desc">
                  Dr. Yadav offers advice on managing common issues like BMI,
                  blood pressure, and minor vision or posture corrections that
                  could impact your selection.
                </p>
                <img src={heartProblemBanner} alt="heartProblemBanner" />
              </div>
            </div>

            <div className="militaryAwareness">
              <div className="right_sec">
                <div className="img_sec">
                  <div className="circle_sec">
                    <div className="red"></div>
                    <div className="blue"></div>
                    <div className="yellow"></div>
                  </div>
                </div>
                <img src={ravinderProgramList} alt="ravinderProgramList" />
              </div>
              <div className="left_sec">
                <div className="top_heading">Core Expertise</div>
                <h2 className="sec_heading">
                  What You’ll Get with Dr. Ravinder’s Program
                </h2>
                <ul className="desc_list">
                  <li>
                    Personalized Health Plan: Tailored recommendations to meet
                    armed forces standards, covering nutrition, sleep, and
                    fitness.
                  </li>
                  <li>
                    Vision and Hearing Practice Checks: Tools to help you assess
                    and track your vision and hearing, with tips on improving
                    eye and ear health.
                  </li>
                  <li>
                    Posture and Flexibility Training: Exercises and tips to help
                    maintain a posture that meets military requirements.
                  </li>
                  <li>
                    Nutrition and Immunity Boosting: Advice on diet and
                    supplements to strengthen immunity and overall health.
                  </li>
                </ul>
                <button className="talk_btn" onClick={handleRedirectToChat}>
                  <GoPlus />
                  Talk you to Ravinder Yadav
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default MedicalExpertProfile;
