import React, { Fragment } from "react";
import "../../../styles/binaural.scss";
import {  useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomHelmet from "../../reusable/Helmet";
import { bodySquat1, bodySquat2 } from "../../../assets/images";

const BodySquatsGuidance = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="binaural_wrapper">
        <div className="container">
          <button className="backBtn" onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
            Go Back
          </button>
        </div>
        <div className="binaural_content container">
          <h2 className="heading">How to Do Bodyweight Squats the Right Way</h2>
          <p>
            Bodyweight squats are an excellent exercise for building lower body
            strength, improving mobility, and enhancing overall stability. They
            primarily target your quads, glutes, and hamstrings, with core
            engagement as well. Here’s a step-by-step guide to doing bodyweight
            squats correctly.
          </p>
          <br />

          <div className="vdo_link">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/otzWCWpuW-A"
              title="How to do a triceps dips"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <br />
          <h4 className="subHeading">Step 1: Get Into Position</h4>

          <span>
            1. <strong> Stand with Feet Shoulder-Width Apart:</strong>
            Position your feet shoulder-width apart or slightly wider. Point
            your toes slightly outward to align with your knees.
          </span>
          <span>
            2. <strong>Engage Your Core:</strong>
            Tighten your core muscles to keep your spine straight. Roll your
            shoulders back and keep your chest up.
          </span>
          <img src={bodySquat1} alt="pushup1" className="image_box" />
          <br />
          <h4 className="subHeading">Step 2: Lower Into the Squat</h4>
          <span>
            1. <strong>Push Hips Back First:</strong> Begin the squat by pushing
            your hips back as if you're about to sit on a chair. This helps you
            engage your glutes and protects your knees from strain.
          </span>
          <span>
            2. <strong>Bend Your Knees:</strong> Continue lowering your body by
            bending your knees. Aim to get your thighs parallel to the ground,
            or as low as your mobility allows.
          </span>
          <img src={bodySquat2} alt="pushup2" className="image_box" />

          <br />
          <h4 className="subHeading">Step 3: Rise Back Up</h4>
          <span>
            1. <strong>Push Through Your Heels:</strong> Press into your heels
            and straighten your legs to stand back up. Exhale as you rise.
          </span>
          <span>
            2. <strong>Return to Starting Position:</strong> Make sure to keep
            your back straight and chest up as you return to the starting
            position.
          </span>

          {/* <img src={pushup3} alt="pushup3" className="image_box" /> */}
          <br />
          <h4 className="subHeading">Common Mistakes to Avoid</h4>
          <span>
            {"a)."} <strong> Knees Caving Inward:</strong> Try to keep your
            knees aligned with your toes throughout the movement. Focus on
            pushing them slightly outward as you squat.
          </span>
          <span>
            {"b)."} <strong>Lifting Heels Off the Ground:</strong> Keep your
            heels grounded to maintain stability and protect your knees.
          </span>
          <span>
            {"c)."} <strong>Rounding the back:</strong> Engage your core to
            avoid rounding your back. Your chest should stay lifted, and your
            spine should stay neutral.
          </span>
          <br />
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default BodySquatsGuidance;
